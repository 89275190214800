.sectionTitle {
  margin-bottom: 20px;
}


.cardTile {
  // deprecated
  color: #4F4F4F;
}


.card {
  &.overload {
    border-radius: .25rem;
  }

  .title {
    font-family: Inter;
    font-size: 20px;
    line-height: 16px;
    color: #4f4f4f;
    padding: 15px 0;
  }

  .status {
    padding-top: 1.5rem;
  }
}

.downloadBtn {
  margin-left: 1.5rem;
}