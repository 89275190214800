.container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 40px;
  width: 100%;
  &.mobile {
    margin-top: 40px;
  }

  .header {
    display: flex;
    align-items: center;
    gap: 4px;
    width: 100%;

    .line {
      border-bottom: 2px solid #DEE2E9;
      width: 100%;
    }

    .sortBy {
      white-space: nowrap;
      color: var(--Black, #2A2A2B);
      font-feature-settings: 'clig' off, 'liga' off;
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }

    .sortByControl {
      margin-right: 40px;
      color: var(--Black, #2A2A2B);
      font-feature-settings: 'clig' off, 'liga' off;
      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 20px;
      letter-spacing: -0.3px;

      .select {
        color: var(--Black, #2A2A2B);
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
        letter-spacing: -0.3px;
        :global(.ant-select-selector) {
          padding-left: 2px;
        }
        :global(.ant-select-arrow) {
          color: black;
          font-size: 14px;
        }

        :global(.ant-select-selection-item) {
          color: var(--Black, #2A2A2B);
          font-feature-settings: 'clig' off, 'liga' off;
          font-family: Inter;
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          letter-spacing: -0.3px;
        }
      }
    }
  }

  .campaigns {
    display: flex;
    padding-bottom: 34px;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
  }
}