.subTitles{
  font-size: 14px;
  line-height: 21px;
  letter-spacing: -0.3px;
  color: #81858B;
}
.captchaContainer{
  .captchaTitle{
    font-size: 12px;
    line-height: 21px;
    letter-spacing: -0.3px;
    color: #81858B;
  }
  .captchaImage{
    margin-top: 6px;
    text-align: center;
  }
  .captchaInput{
    margin-top: 6px;
  }
  .captchaError{
    margin-top: 6px;
    margin-bottom: 0;
  }
}
.captchaError{
  color: #ff4d4f;
  margin-bottom: 10px;
}
:global(.ant-btn).signupBtn{
  margin-top: 30px;
}
:global(.ant-btn).signupBtn2{
  margin-top: 20px;
  margin-bottom: 30px;
}
.otherStepsContainer{
  display: flex;
  justify-content: center;
  .headerBackground{
    height: 291px;
    max-height: 20vh;
    background: #E8ECF3;
    width: 100%;
    position: absolute;
  }
  .innerContainer{
    z-index: 1;
    margin-top: 40px;
    max-width: 376px;
    border: 1px solid #E8ECF3;
    background-color: white;
    box-sizing: border-box;
    border-radius: 8px;
  }
}