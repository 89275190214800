@import '../../../variables.scss';

.divider {
    height: 4px;
    background: $gray002;
    margin-top: 40px;
    margin-bottom: 16px;
}

.title {
    color: $gray004;
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 21.88px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px;
    letter-spacing: 0.2px;
}

.container {
    margin-top: 16px;
    display: flex;
    padding: 24px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 24px;
    align-self: stretch;
    border-radius: 16px;
    border: 1px solid $gray002;
    width: 100%;
    margin-bottom: 60px;
}