.title {
  color: #686f84;
  display: flex;
  font-size: 17.5px;
  font-weight: 600;
  margin-bottom: 40px;
}

:global(.ant-select:not(.ant-select-customize-input)).selector {
  > :global(.ant-select-selector) {
    border: 0;
    background-color: #e8ecf3;
    color: #686f84;
    font-size: 12px;
    height: 26px;
    padding: 0 11px 0 7px;
  }
  :global(.ant-select-selection-placeholder) {
    color: #686f84;
  }
}

:global(.ant-select:not(.ant-select-customize-input)).activeSelector {
  > :global(.ant-select-selector) {
    border: 0;
    background-color: #686F84;
    color: white;
    font-size: 12px;
    height: 26px;
    padding: 0 11px 0 7px;
  }
  :global(.ant-select-clear) {
    background-color: #686F84;
    color: white;
  }
  :global(.ant-select-arrow) {
    color: white;
  }
}