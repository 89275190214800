@import '../../../variables.scss';

.topicHeader {
    display: flex;
    align-items: center;
    height: 20px;

    .title {
        height: 44px;
        display: flex;
        align-items: center;
        color: black;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
        /* 125% */
        letter-spacing: -0.3px;

    }
}

:global(.ant-collapse).accordion {
    &> :global(.ant-collapse-item-active)> :global(.ant-collapse-header) {
        .topicHeader {
            .title {
                color: white;

                &.selected {
                    color: white;
                }
            }
        }
    }
}

:global(.ant-collapse).accordion {
    border-radius: 8px;
    // overflow: hidden;
    margin-bottom: 8px;
    background-color: $gray001;

    &> :global(.ant-collapse-item) {
        border-bottom: 0;

        :global(.ant-collapse-header) {
            padding: 11px 16px;
            background-color: white;
            border-radius: 8px !important;
            border: 1px solid $gray002;

            :global(.ant-collapse-expand-icon) {
                color: black;
            }
        }
    }

    &> :global(.ant-collapse-item-active)> :global(.ant-collapse-header) {
        background-color: $blue002;
        border-radius: 8px 8px 0 0 !important;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
        /* 125% */
        letter-spacing: -0.3px;

        :global(.ant-collapse-expand-icon) {
            color: white;
        }
    }

    :global(.ant-collapse-content):global(.ant-collapse-content-active) {
        border-left: 4px solid $gray002 !important;
        border-radius: 0 0 8px 8px;
    }
}

.divider {
    border-bottom: 1px solid #E8ECF3;
    margin: 0 14px;
}