@import '../../../variables.scss';

.container {
    display: flex;
    align-items: center;
    height: 61px;

    .title {
        letter-spacing: -0.3px;
        color: black;

        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;

        cursor: pointer;

        &.selected {
            color: var(--main-color);
        }
    }
}

.cannotGoThere {
    cursor: default;

    .title {
        cursor: default;
    }
}

.divider {
    border-bottom: 1px solid #E8ECF3;
    margin: 0 -14px;
}