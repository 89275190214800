@import "../../../variables.scss";

.container {
  display: flex;
  padding: 24px 16px;
  align-items: center;
  gap: 16px;
  flex: 1 0 0;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid white;
  background: white;
  /* Shadow */
  box-shadow: 0px 4px 16px 0px rgba(51, 51, 51, 0.08), 0px 4px 4px 0px rgba(51, 51, 51, 0.04);

  .icon {
    width: 32px;
    height: 32px;

    img {
      width: 32px;
      height: 32px;
    }
  }

  .content {
    .points {
      font-feature-settings: 'clig' off, 'liga' off;
      -webkit-text-stroke-width: 54.57499694824219;
      -webkit-text-stroke-color: var(--Black, #2A2A2B);
      font-family: Inter;
      font-size: 21px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      letter-spacing: -0.3px;
      display: flex;
      gap: 10px;
      align-items: center;
    }

    .description {
      color: var(--grey, #81858B);
      font-feature-settings: 'clig' off, 'liga' off;

      /* caption */
      font-family: Inter;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
}

.mobile.container {
  .content {
    .description {
      max-width: 90px;
    }
  }
}