$panelHeight: 12vw;
$panelMaxHeight: 160px;
$panelHeightMobile: 30vw;

.height90 {
    height: calc(0.9*#{$panelHeight});
    @media (max-width: 450px) {
        height: calc(0.9*#{40vw});
    }

    @media (max-width: 768px) {
        height: calc(0.7*#{$panelHeightMobile});
    }
}

.image {
    width: $panelHeight;
    height: $panelHeight;
    border-radius: calc(#{$panelHeight}/2);
    @media (max-width: 450px) {
        width: 50vw;
        height: 50vw;
        border-radius: 25vw;
        margin-bottom: 20px;
    }

    @media (max-width: 768px) {
        width: $panelHeightMobile;
        height: $panelHeightMobile;
        border-radius: 25vw;
        margin-bottom: 20px;
    }
}

.bkgndPanel {
    position: absolute;
    background-color: rgb(232, 235, 243);
    padding: 0;
    width: 100%;
}

.content {
    padding-top: calc(3*#{$panelHeight}/11);
    max-width: 1140px;
    margin-left: auto;
    margin-right: auto;
}

.heading {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
}
