@import '../../variables.scss';

.templates {
  display: flex;
  gap: 10px;
  align-items: center;
  width: 100%;

  .templateWrapper {
    border-radius: 8px;
    margin-bottom: 40px;
    overflow: hidden;
    width: 120px;
    border: 4px $gray002 solid;
    height: fit-content;
    cursor: pointer;

    &.selected {
      border-color: var(--main-color);
    }
  }
}

.option {
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0px;
  text-align: left;
  color: black;

  .questionCount {
    color: $gray003;
  }
}

.hidableContainer {
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;

  .label {
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: -0.30000001192092896px;
    text-align: left;
  }
}

.container {
  width: 100%;
  padding-bottom: $extraBottomPadding;

  .titleContainer {
    align-items: center;
    display: flex;
    height: 80px;
    padding-top: 19px;
    justify-content: space-between;

    .title {
      font-family: Inter;
      font-style: normal;
      font-weight: 600;
      font-size: 21.88px;
      line-height: 32px;
      max-width: 300px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }

    .actions {
      display: flex;
      align-items: center;

      .buttonNextPrev {
        width: 40px;
        height: 40px;
        padding: 2px 0px !important;
        margin-right: 10px;

        .icon {
          font-size: 26px;
        }
      }

      .button {
        height: 40px;
        width: 190px;
      }
    }
  }

  .card {
    margin-top: 16px;
    border-radius: 8px;

    .title {
      font-size: 22px;
      font-weight: bold;
      color: #4f4f4f;
    }

    .sectionTitle {
      margin: 16px 0px;
      color: #4f4f4f;
      font-size: 17.5px;
      font-weight: 600;
      letter-spacing: 0.146px;
      text-transform: capitalize;
    }

    .subTitle {
      font-size: 14px;
      color: #81858b;
    }

    .sectionRow {
      align-items: flex-start;
      display: flex;

      .sectionInfo {
        align-items: center;
        background-color: #f7f8fa;
        display: flex;
        min-width: 160px;
        padding: 6px 8px;

        .removeButton {
          padding: 0 !important;
        }

        span {
          margin: 0 12px;
        }
      }
    }
  }

  .addCertificateButton {
    font-size: 16px;
    width: 100%;
    min-height: 40px;

    .square {
      background-color: $blue001;
      width: 16px;
      height: 16px;
      border-radius: 4px;
      margin-right: 8px;
    }
  }
}

.bluePrintWrapper {
  border: 2px solid #f7f8fa;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  max-width: 400px;
  width: 100%;

  .blueprintFooter {
    align-items: center;
    background-color: #f7f8fa;
    display: flex;
    justify-content: space-between;
    margin-top: 2px;
    padding: 5px 10px;

    p {
      margin: 0;
      text-transform: capitalize;
    }
  }
}

.blueprintMenu {
  max-height: 350px;
  overflow-y: scroll;
  padding: 0;
  width: 100%;

  :global(.ant-select-item) {
    min-height: unset;
    margin: 0 0 10px;
    padding: 0;
  }

  :global(.ant-select-item-option-grouped) {
    padding: 0;
  }
}

.topicMenu {
  max-height: 350px;
  overflow-y: scroll;
  padding: 0;
  width: 100%;
  max-width: 576px;

  :global(.ant-select-item) {
    min-height: unset;
    margin: 0 0 10px;
    padding: 0;
  }

  :global(.ant-select-item-option-grouped) {
    padding: 0;
  }
}
  
.addTopicButton {
  width: 100%;
  height: 40px;
  margin-bottom: 1em;
}

.blueprintItemWrapper {
  position: relative;

  &:hover {
    p {
      display: initial;
    }
  }

  p {
    display: none;
    font-size: 40px;
    position: absolute;
    text-align: center;
    text-transform: capitalize;
    top: 45%;
    width: 100%;
  }

  .blueprintItem {
    margin: 0 auto;
    max-width: 200px;
  }
}
.options {
  margin-top: 20px;
  .displayField {
    display: flex;
    justify-content: space-between;
  }
}