@import '../../../variables.scss';

:global(.ant-card).card {
    margin-top: 17px;
    border: 1px solid #e8ecf3;
    box-sizing: border-box;
    border-radius: 8px;

    .mainTitle {
        font-size: 12px;
        line-height: 16px;
        color: #828282;
        margin-bottom: 14px;
    }

    .container {
        display: flex;
        border-bottom: 1px solid $gray002;
        margin-top: 16px;
        padding-bottom: 16px;

        &:last-child {
            border-bottom: 0;
        }


        .firstColumn {
            .icon {
                height: 22px;
                width: 22px;
                margin-right: 7px;
            }
        }

        .secondColumn {

            .title {
                color: $gray004;
                font-feature-settings: 'clig' off, 'liga' off;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
            }

            .content {
                color: $blue003;
                font-feature-settings: 'clig' off, 'liga' off;
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                padding-top: 4px;

                .list {
                    list-style-type: disc;
                    padding-left: 16px;

                    .item {
                        text-indent: -5px;
                        white-space: normal;

                        &:before {
                            content: "-";
                            text-indent: -10px;
                            position: absolute;
                        }
                    }
                }
            }
        }
    }
}