@import "../../../variables.scss";

.container {
  display: flex;
  padding: 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 25px;
  flex: 1 0 0;
  border-radius: 8px;
  border: 1px solid var(--white, #FFF);
  background: var(--white, #FFF);

  /* Shadow */
  box-shadow: 0px 4px 16px 0px rgba(51, 51, 51, 0.08), 0px 4px 4px 0px rgba(51, 51, 51, 0.04);


  .title {
    color: var(--grey, #81858B);
    font-feature-settings: 'clig' off, 'liga' off;

    /* Subtitle */
    font-family: Inter;
    font-size: 17.5px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    /* 114.286% */
    letter-spacing: 0.147px;
  }

  .secondSection {
    display: flex;
    align-items: center;
    gap: 32px;
    align-self: stretch;

    .chart {
      width: 153px;
      height: 153px;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: 109.525px;
        height: 109.525px;
        flex-shrink: 0;
        position: absolute;
      }

      .progressBar {
        width: 153px;
        height: 153px;
      }
    }

    .content {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 3px;

      .percentage {
        color: var(--Black, #2A2A2B);
        font-feature-settings: 'clig' off, 'liga' off;

        /* bignumber */
        font-family: Inter;
        font-size: 40px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;

      }

      .description {
        color: var(--grey, #81858B);
        font-feature-settings: 'clig' off, 'liga' off;

        /* paragraph */
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        /* 125% */
        letter-spacing: -0.3px;
      }
    }
  }

  .bottomSection {
    display: flex;
    align-items: flex-start;
    gap: 9px;
    align-self: stretch;

    .column {
      display: flex;
      height: 81px;
      padding: 16px;
      align-items: flex-start;
      flex-direction: column;
      flex: 1 0 0;
      border-radius: 8px;
      border: 1px solid var(--grey, #DEE2E9);
      background: var(--white, #FFF);
      gap: 3px;
      flex: 1 0 0;

      .value {
        color: var(--Black, #2A2A2B);
        text-align: center;
        font-feature-settings: 'clig' off, 'liga' off;

        /* digit */
        font-family: Inter;
        font-size: 21px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: -0.3px;
      }

      .description {
        color: var(--grey, #81858B);
        font-feature-settings: 'clig' off, 'liga' off;

        /* caption */
        font-family: Inter;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }
  }
}