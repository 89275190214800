.container {
  display: flex;
  flex-direction: column;
  padding-top: 0;
  max-width: 476px;
  margin-left: 24px;
  .preview {
    width: calc(100% - 376px) !important;
  }
  .notification {
    display: flex;
    padding: 16px 10px;
    background-color: #f7f8fa;
    width: 100%;
    border: 1px solid #e8ecf3;
    border-radius: 8px;
    &.selected {
      background: #f7f8fa;
      border-radius: 8px;
    }
    .iconContainer {
      width: 60px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      .background {
        width: 32px;
        height: 32px;
        display: flex;
        background-color: #f7f8fa;
        align-items: center;
        justify-content: center;
        border-radius: 8px;
        .icon {
          height: 40px;
        }
      }
    }
    .content {
      width: 100%;
      .title {
        font-weight: normal;
        font-size: 13px;
        line-height: 21px;
        letter-spacing: -0.3px;
        color: #4f4f4f;
      }
      .title {
        font-weight: normal;
        font-size: 13px;
        line-height: 21px;
        letter-spacing: -0.3px;
        color: #4f4f4f;
      }
      .date {
        font-size: 11px;
        line-height: 16px;
        color: #81858b;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
  }
  .info {
    display: flex;
    flex-direction: column;
    border: 1px solid #f7f8fa;
    box-sizing: border-box;
    border-radius: 8px;
    padding: 15px 17px;
    margin-top: 16px;
    .row {
      display: flex;
      justify-content: space-between;
      margin-top: 16px;
      &:first-child{
        margin-top: 0;
      }
      .label {
        font-weight: normal;
        font-size: 14px;
        line-height: 21px;
        display: flex;
        align-items: center;
        letter-spacing: -0.3px;
        color: #4f4f4f;
        .icon {
          width: 16px;
          height: 16px;
          margin-right: 11px;
        }
      }
      .value {
        font-weight: normal;
        font-size: 12px;
        line-height: 16px;
        display: flex;
        align-items: center;
        text-align: right;
        color: #81858b;
        max-width: 60%;
        overflow: hidden;
      }
    }
  }
}
