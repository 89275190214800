@import '../../variables.scss';

.filterButton {
    border-radius: 4px;
    height: 40px;
    width: 119px;
    display: flex;
    align-items: center;
    margin-left: 20px;
    margin-bottom: 17px;
    cursor: pointer;
    color: var(--tertiary-text-button-color);
    padding: 10px 20px;
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: -0.30000001192092896px;
    text-align: center;


    .filterIcon {
        margin-right: 10px;
        width: 15px;
        height: 9px;

        .filterIconTopLine {
            border-top: 2px solid var(--tertiary-text-button-color);
        }

        .filterIconBottomLine {
            border-top: 2px solid var(--tertiary-text-button-color);
            margin-top: 5px;
            width: 8px;
            margin-left: 7px;
        }
    }

    &.report {
        width: 139px;
        margin-bottom: 0;
    }

    &.company {
        background: #e8ecf3;
        color: #81858b;
        font-weight: 500;
        margin-left: 18px;
        margin-bottom: 0;
        min-width: 112px;
        width: auto;

        .filterIcon {
            display: none;
        }
    }
}

.filterMenu {
    border: 1px solid #e8ecf3;
    box-sizing: border-box;
    border-radius: 8px;
    background-color: white;
    min-width: 360px;
    max-height: 320px;

    &.noHeightLimit {
        max-height: initial;
    }

    .wrapper {
        display: flex;
    }

    .innerContainer {
        padding: 12px 16px;
        width: 100%;

        .inpuntContainer {
            .searchInput {
                background-color: #F7F8FA;
                border: 0;

                input {
                    background-color: #F7F8FA;
                }

                :global(.ant-input-search-icon::before) {
                    border: 0;
                }
            }
        }

        .radioGroup {
            &.vertical {
                width: 100%;
                max-height: 200px;
                overflow-y: auto;
                padding-top: 10px;

                :global(.ant-radio-wrapper) {
                    display: block;
                    max-width: 225px;
                    overflow: hidden;
                    padding: 4px 0;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }
            }
        }

        .title {
            font-weight: 600;
            font-size: 17px;
            line-height: 20px;
            color: #4f4f4f;
        }
    }

    .actionButton {
        border-top: 1px solid #e8ecf3;
        height: 52px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }
}