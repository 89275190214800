@import '../../variables.scss';

.selectedCourses {
  .selectedCourse {
    .title {
      font-size: 18px;
      font-weight: 600;
      line-height: 20px;
      letter-spacing: 0.1466670036315918px;
      text-align: left;
      color: $gray005;
      margin-top: 20px;
    }

    .description {
      font-size: 12px;
      font-weight: 400;
      line-height: 15px;
      letter-spacing: 0px;
      text-align: left;
      color: $gray003;
      margin-top: 18px;
    }
  }
}

.courseMenu {
  max-height: 350px;
  overflow-y: scroll;
  padding: 0;
  width: 100%;
  max-width: 576px;

  :global(.ant-select-item) {
    min-height: unset;
    margin: 0 0 10px;
    padding: 0;
  }

  :global(.ant-select-item-option-grouped) {
    padding: 0;
  }
}

.addCourseButton {
  width: 100%;
}