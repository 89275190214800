@import '../../variables.scss';

.addLessonButton {
  width: 100%;
  height: 40px;

  .square {
    background-color: $blue001;
    width: 16px;
    height: 16px;
    border-radius: 4px;
    margin-right: 8px;
  }
}

.cardsContainer {}

@mixin extended-style {
  margin-bottom: 16px;
  border-radius: 4px;
}

@mixin card-body-style {
  padding: 30px;
}

@mixin btn-container {
  .btnContainer {
    .removeButton {
      height: 32px;
      padding: 0 8px;

      .square {
        width: 16px;
        height: 16px;
        border-radius: 4px;
      }
    }

    .space {
      margin-left: 8px;
    }
  }
}

.cardContainer {
  &.extendedStyle {
    @include extended-style;
  }

  &.cardBody {
    @include card-body-style;
  }

  .title {
    margin-top: 20px;
    font-size: 14px;
    font-weight: 400;
  }

  .type {
    height: 24px;
    padding: 4px 8px 4px 8px;
    border-radius: 4px;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0px;
    text-align: center;
    color: $blue003;
    background-color: $gray001;
    width: fit-content;
    margin-top: 18px;
  }

  .evaluation {
    margin-top: 10px;
    background-color: $gray001;
    height: 32px;
    padding: 4px 8px 4px 8px;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: -0.30000001192092896px;
    text-align: left;
    color: $gray005;
    margin-right: 8px;
    width: fit-content;

    .icon {
      margin-right: 8px;
    }
  }

  .meta {
    display: flex;
    justify-content: flex-end;
    font-size: 12px;
    color: #81858b;
  }

  .description {
    font-size: 12px;
    margin-top: 16px;
    color: #81858b;
  }

  .topIconContainer {
    width: 100%;
    display: flex;
    justify-content: center;
    align-self: center;
    cursor: move;
  }

  @include btn-container;
}

.sectionTitle {
  margin: 16px 0px;
  color: #4f4f4f;
  font-size: 17px;
  font-weight: 600;
  text-transform: capitalize;
}

// TODO: code duplication
.backButton {
  display: inline-flex;
  align-items: center;
  color: #686F84;
  font-size: 16px;
  margin: 0;
  padding: 0;
  background: transparent;
  border: none;
  font-weight: 500;
  margin-bottom: 9px;

  span {
    margin-left: 10px;
  }
}

.sortablelist {
  list-style: none;
  margin: 0;
  padding: 0;
}