.wrapper {
  composes: wrapper from "./common.module.scss";

  .sectionTitle {
    background-color: #f7f8fa;
    @media print {
      margin-top: 40px;
    }

    .title {
      font-family: Inter;
      font-style: normal;
      font-weight: normal;
      font-size: 20px;
      line-height: 16px;
      color: #4f4f4f;
      padding: 25px 40px;
    }
  }
}
