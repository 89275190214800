.container {
  display: inline-flex;
  padding: 16px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 4px;
  background: rgba(0, 0, 0, 0.75);
  backdrop-filter: blur(5px);
  width: 206px;

  .innerContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 24px;
    align-self: stretch;
  }
}

.pointer {
  width: 10px;
  height: 10px;
  border-radius: 5px;
  border: 1px #FAFAFA solid;
  box-shadow: 0px 0px 5px #000;

  &.forSaturation {
    margin-left: -50%;
    margin-top: -50%;
  }

  &.forHue {
    margin-left: -50%;
  }
}

.popover {
  padding: 0;

  :global(.ant-popover-arrow) {
    width: 0;
    height: 0;
  }

  :global(.ant-popover-inner) {
    :global(.ant-popover-inner-content) {
      padding: 0;
    }
  }
}

.label {
  display: flex;
  width: 174px;
  align-items: center;
  gap: 8px;
  color: #FFF;
  font-feature-settings: 'clig' off, 'liga' off;
  -webkit-font-smoothing: unset;
}

.color {
  display: flex;
  width: 20px;
  height: 20px;
  padding: 9.655px;
  align-items: flex-start;
  gap: 9.655px;
  border-radius: 3.862px;
}

.colors {
  display: flex;
  width: 174px;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 8px;
  flex-wrap: wrap;

  .color {
    width: 22px;
    height: 22px;
  }
}

.section {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  flex: 1 0 0;

  .message {
    color: #909090;
    line-height: normal;
    margin: 0;
    -webkit-font-smoothing: unset;

  }
}

.innerColorPicker {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;

  .section {
    gap: 4px;
  }

  .hex {
    display: flex;
    height: 30px;
    padding: 4px 8px;
    align-items: center;
    gap: 8px;
    flex-shrink: 0;
    align-self: stretch;
    border-radius: 4px;
    border: 0.8px solid #909090;

    .input {
      border: 0;
      background-color: transparent;
    }
  }

  .sat {
    height: 100px;
    position: relative;
    margin-bottom: 10px;
    width: 174px;
    height: 174px;
  }

  .hue {
    height: 10px;
    position: relative;
    margin-bottom: 10px;
    display: flex;
    width: 138px;
    justify-content: space-between;
    align-items: center;
    margin: 0 18px;
    border-radius: 20px;
    overflow: hidden;
  }
}