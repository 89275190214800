.mainContainer {
  padding-top: 50px;
  .title {
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 32px;
    color: #4f4f4f;
  }
  .items {
    padding-top: 15px;
  }
}
