@import '../../variables.scss';

.topics {
    .topic {
        margin-bottom: 16px;

        .title {
            color: $gray004;
            overflow: hidden;
            text-overflow: ellipsis;
            font-size: 18px;
            font-weight: 600;
            line-height: 20px;
            letter-spacing: 0.1466670036315918px;
            text-align: left;
            margin-top: 20px;
        }

        .description {
            margin-top: 10px;
            color: #81858B;
            font-size: 12px;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
        }

        .separator {
            border: 1px solid #E8ECF3;
            margin-top: 24px;
            margin-bottom: 24px;
        }

        .removeButton {
            height: 32px;
            width: 32px;
            padding: 0 !important;
        }

    }
}

.category {
    height: 24px;
    padding: 4px 8px 4px 8px;
    border-radius: 4px;
    background-color: $gray001;
    width: fit-content;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0px;
    text-align: center;
    white-space: nowrap;
    margin-top: 20px;
}

.lessonContainer {
    overflow: hidden;
    border-bottom: 1px solid $gray002;
    padding-bottom: 20px;

    &.closed {
        border-bottom: 0;
        padding-bottom: 0px;
        height: 0;
    }

    .lessons {
        .lesson {
            display: flex;
            height: 90px;
            background-color: white;
            border-radius: 8px;
            padding: 16px 18px;
            align-items: center;
            margin-bottom: 16px;

            &:nth-child(odd) {
                background-color: $gray001;

                .innerContainer {
                    .title {
                        color: $gray005;
                    }
                }
            }

            &:nth-child(even) {
                .innerContainer {
                    .title {
                        color: $gray003;
                    }
                }
            }

            .handler {
                width: 15px;
                margin-right: 6px;

                svg {
                    width: 20px;
                    height: 20px;
                }
            }

            .innerContainer {
                margin-left: 12px;
                display: flex;
                flex-direction: column;
                width: 100%;
                justify-content: space-between;
                height: 100%;

                .title {
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 17px;
                    letter-spacing: 0px;
                    text-align: left;
                    overflow: hidden;
                    margin-top: 0;
                }

                .bottomPart {
                    display: flex;
                    justify-content: space-between;
                }

                .disableSwitch {
                    width: 100%;
                    text-align: right;
                    font-size: 12px;
                    color: #81858B;
                }

                .category {
                    height: 24px;
                    padding: 4px 8px 4px 8px;
                    border-radius: 4px;
                    background-color: $gray001;
                    width: fit-content;
                    font-size: 12px;
                    font-weight: 500;
                    line-height: 16px;
                    letter-spacing: 0px;
                    text-align: center;
                    margin-top: 0;
                }
            }
        }
    }
}

.lessonControl {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    height: 36px;
    width: 100%;

    .lessonControlLabel {
        font-size: 16px;
        font-weight: 600;
        line-height: 20px;
        letter-spacing: -0.30000001192092896px;
        text-align: right;
        color: $blue003;
        margin-right: 10px;
    }
}