@import '../../variables.scss';

:global(#teurona) {
  .container {
    width: 100%;
    margin-bottom: 24px;

    .switch {
      width: 100%;

      :global(.ant-radio-button-wrapper) {
        width: 50%;
        text-align: center;
        height: 40px;
        line-height: 40px;
      }
    }
  }
}