@import '../../../variables.scss';

.container {
    width: 100%;

    &.clickable {
        cursor: pointer;
    }

    .titleButtonContainer {
        margin-top: 10px;
        display: flex;
        justify-content: space-between;

        .title {
            min-height: 48px;
            overflow: hidden;
            color: $gray005;
            font-feature-settings: 'clig' off, 'liga' off;
            text-overflow: ellipsis;
            white-space: nowrap;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 20px;
            /* 125% */
            letter-spacing: -0.3px;
        }

        .gotToTopicContainer {
            .button {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 36px;
                width: 36px;
                cursor: pointer;
            }
        }
    }

    .categoryContainer {
        margin-top: 4px;
    }

    .description {
        margin-top: 8px;
        font-size: 14px;
        line-height: 21px;
        letter-spacing: -0.3px;
        color: #828282;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .divider {
        border-bottom: 1px solid #e8ecf3;
        border-radius: 8px;
        margin-top: 16px;
    }

    .progress {
        .percentage {
            font-size: 12px;
            line-height: 16px;
            color: #828282;
        }

        .bar {
            :global(.ant-progress-bg) {
                height: 10px !important;
            }
        }
    }

    .durationPriorityContainer {
        display: flex;
        margin-top: 20px;
        width: 400px;
        justify-content: flex-start;
        gap: 22px;

        .duration {
            display: flex;
            align-items: center;
            color: $gray003;
            font-feature-settings: 'clig' off, 'liga' off;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 16px;
        }

        .priority {
            display: flex;
            align-items: center;
            color: $gray003;
            font-feature-settings: 'clig' off, 'liga' off;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 16px;
        }

        .date {
            color: $gray003;
            font-feature-settings: 'clig' off, 'liga' off;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 16px;

            span {
                margin-left: 4px;
                margin-right: 4px;
            }
        }

        .icon {
            margin-right: 6px;
        }
    }
}