.container{
  display: flex;
  gap: 10px;
  padding: 10px 0;
  &:nth-child(2n) {
    background-color: #F9F9F9;
  }
  .key {
    width: 400px;
    min-width: 400px;
    font-weight: bold;
  }
  .es {
    width: 200px;
    min-width: 200px;
  }
  .other {
    width: 100%;
    min-width: 200px;
  }
}