.container {
    width: 100%;

    .image {
        width: 100%;
        height: 388px;
        object-fit: cover;
        border-radius: 8px;

        &.mobile {
            border-radius: initial;
            width: 100vw;
            height: unset;
        }
    }
}