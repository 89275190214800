.fullName {
	display: flex;
	flex-direction: row;
	$width: 24px;
	.image {
		width: $width;
		height: $width;
		border-radius: calc(#{$width} / 2);
	}
	.label {
		margin-left: calc(#{$width} / 3);
	}
}
