@import '../../variables.scss';

.container {
  position: relative;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 10px;

  .icon {
    height: 26px;
    width: 26px;
  }

  .notificationsTitle {
    font-size: 10px;
    font-weight: 500;
    line-height: 12px;
    letter-spacing: -0.30000001192092896px;
    color: $gray003;
  }

  .counter {
    width: 14px;
    height: 14px;
    background-color: $blue001;
    font-size: 8px;
    line-height: 12px;
    text-align: center;
    flex-direction: row;
    color: #ffffff;
    font-weight: 600;
    border-radius: 17px;
    padding: 0;
    padding-top: 1px;
    padding-right: 1px;
    position: absolute;
    left: 16px;
    top: 16px;
  }
}

.menuContainer {
  background-color: white;
  border: 1px solid #e8ecf3;
  border-radius: 8px;
  width: 324px;

  @media (max-width: 375px) {
    width: 100vw;
  }

  .menuTitle {
    font-weight: 600;
    font-size: 17.5px;
    line-height: 20px;
    color: #4f4f4f;
    height: 76px;
    padding: 26px 16px;
  }

  .subTitle {
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    color: #81858b;
    padding-left: 16px;
  }

  .notifications {
    width: 322px;
    max-height: calc(100vh - 300px);
    overflow-y: auto;
    overflow-x: hidden;

    .none {
      padding: 20px 10px;
    }

    .notification {
      display: flex;
      height: 50px;
      margin-top: 6px;

      .iconContainer {
        width: 60px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;

        .background {
          width: 32px;
          height: 32px;
          display: flex;
          background-color: #f7f8fa;
          align-items: center;
          justify-content: center;
          border-radius: 8px;

          .icon {
            height: 40px;
          }
        }
      }

      .content {
        width: 240px;
        overflow: hidden;

        @media (max-width: 375px) {
          width: 100%;
        }

        .title {
          font-weight: normal;
          font-size: 13px;
          line-height: 21px;
          letter-spacing: -0.3px;
          color: #4f4f4f;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          height: 20px;
        }

        .date {
          font-size: 11px;
          line-height: 16px;
          color: #81858b;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }
      }
    }
  }

  .imageContainer {
    padding-top: 60px;
    text-align: center;
  }

  .noNotificationsText {
    font-weight: 600;
    font-size: 17.5px;
    line-height: 20px;
    text-align: center;
    letter-spacing: 0.146667px;
    color: #81858b;
    margin-bottom: 60px;
    margin-top: 20px;
  }

  .goToAll {
    padding: 0 16px;

    .divider {
      border-top: 1px solid #e8ecf3;
      width: 100%;
    }

    .buttonContainer {
      height: 68px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      .button {
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        letter-spacing: -0.3px;
        color: $blue001;
        height: 48px;
        width: 100%;
      }
    }
  }
}