@import '../../variables.scss';

.container {
    padding-bottom: $extraBottomPadding;

    .titleContainer {
        display: flex;
        height: 80px;
        padding-top: 19px;
        justify-content: space-between;

        .title {
            font-family: Inter;
            font-style: normal;
            font-weight: 600;
            font-size: 21.88px;
            line-height: 32px;
            max-width: 280px;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            margin-top: 6px;
        }

        .actions {
            .buttonNextPrev {
                width: 40px;
                height: 40px;
                padding: 2px 0px !important;
                margin-right: 10px;

                .icon {
                    font-size: 26px;
                }
            }

            .button {
                height: 40px;
                width: 190px;
            }
        }
    }

    .card {
        margin-top: 16px;
        border-radius: 8px;

        .title {
            font-size: 22px;
            font-weight: bold;
            color: #4F4F4F;
        }

        .sectionTitle {
            margin-bottom: 16px;
        }

        .subTitle {
            font-size: 14px;
            color: #81858B;
        }

        .formDescription {
            color: #81858b;
            margin: 16px 0px;
            font-size: 12px;
        }

        :global(.tox-tinymce) {
            border: 0;
        }
    }

    .addTopicButton {
        width: 100%;
        margin-top: 30px;
        height: 40px;

        .square {
            background-color: #4376F9;
            width: 16px;
            height: 16px;
            border-radius: 4px;
            margin-right: 8px;
        }
    }

    .pdfFile {
        margin-top: 16px;
        background: #F7F8FA;
        border-radius: 4px;
        padding: 5px 10px;
        width: 100%;
        display: flex;
        align-items: center;

        .attach {
            display: inline-block;
            width: 26px;
        }

        .name {
            display: inline-block;
            width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        .size {
            display: inline-block;
            color: #81858B;
            width: 80px;
        }

        .removePdf {
            cursor: pointer;
        }

        .remove {
            display: inline-block;
        }
    }
}