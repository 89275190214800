@import '../../../../variables.scss';

.outerContainer {
  max-width: 800px;
  margin: 48px auto 0 auto;
  width: 100%;
}

.container {
  .topicTitle {
    font-size: 12px;
    line-height: 16px;
    color: $gray010;
  }

  .title {
    margin-top: 4px;
    font-size: 17.5px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    /* 114.286% */
    letter-spacing: 0.147px;
    color: $gray004;
  }

  .titleContainer {
    :global(.ant-card-body) {
      padding: 14px 16px;
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      .titleInnerContainer {
        width: 100%;
      }

      .progress {
        text-align: right;
        width: 100px;
      }
    }
  }
}

.viewLessonsMobile {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}