.noNotifications{
  .imageContainer {
    padding-top: 60px;
    text-align: center;
  }
  .noNotificationsText{
    font-weight: 600;
    font-size: 17.5px;
    line-height: 20px;
    text-align: center;
    letter-spacing: 0.146667px;
    color: #81858b;
    margin-bottom: 60px;
    margin-top: 20px;
  }
}
.container {
    display: flex;
    padding-top: 32px;
  .preview {
    width: calc(100% - 376px) !important;
  }
  .notificationsContainer {
    border: 1px solid #e8ecf3;
    border-radius: 8px;
    width: 376px !important;
    .mainTitle {
      font-weight: 600;
      font-size: 17.5px;
      line-height: 20px;
      letter-spacing: 0.146667px;
      color: #4f4f4f;
      height: 60px;
      padding: 26px 16px;
    }
    .notifications {
      width: 376px;
      max-height: calc(100vh - 300px);
      overflow-y: auto;
      overflow-x: hidden;
      padding: 0 4px;
      cursor: pointer;
      .notification {
        display: flex;
        height: 50px;
        margin-top: 10px;
        padding: 6px 0;
        &.selected{
            background: #F7F8FA;
            border-radius: 8px;
        }
        .iconContainer {
          width: 60px;
          height: 40px;
          display: flex;
          align-items: center;
          justify-content: center;
          .background {
            width: 32px;
            height: 32px;
            display: flex;
            background-color: #f7f8fa;
            align-items: center;
            justify-content: center;
            border-radius: 8px;
            .icon {
              height: 40px;
            }
          }
        }
        .unread{
          width: 40px;
          height: 40px;
          display: flex;
          align-items: center;
          justify-content: center;
          .indicator{
              width: 8px;
              height: 8px;
              border-radius: 4px;
              background-color: #4376F9;
          }
        }
        .content {
          width: calc(100% - 110px);
          overflow: hidden;
          @media (max-width: 375px) {
            width: 100%;
          }
          .title {
            font-weight: normal;
            font-size: 13px;
            line-height: 21px;
            letter-spacing: -0.3px;
            color: #4f4f4f;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            height: 20px;
          }
          .date {
            font-size: 11px;
            line-height: 16px;
            color: #81858b;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
          }
        }
      }
    }
  }
}
