.mainWrapper {
  display: flex;
  width: fit-content;
  &.typeTwo {
    flex-direction: column;
  }

  .container {
    display: flex;
    flex-direction: row;
  }
  
  .doughnutGraph {
    display: flex;
    align-items: center;
  }
  
  .contentsContainer {
    display: flex;
    flex-direction: column;
    padding-left: 20px;

    .stats {
      display: flex;
      flex-direction: column;
      flex: 2;
      justify-content: center;
      padding: 5%;
      color: #4F4F4F;
      .number {
        font-size: 2rem;
        font-weight: bold;
      }
      .description {
        font-size: 0.8rem;
      }
    }
  }

}

.labelsContent {
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 10px 5% 0;
  &.typeTwo {
    padding: 0;
    padding-top: 10px;
    justify-content: space-around;
  }

  .itemContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-right: 10px;
    .box {
      width: 30px;
      height: 10px;
    }
    .label {
      padding: 0 5px;
      white-space: nowrap;
    }

  }
}
