.container {
  :global(.ant-message-notice-content) {
    border-radius: 8px;
    :global(.anticon) {
      display: none;
    }
  }
  .toast {
    .content {
      margin-left: 10px;
      max-width: calc(100vw - 20px);
      width: 400px;
      text-align: left;
      .titleContainer {
        display: flex;
        align-items: center;
        .iconContainer {
          .icon {
            height: 20px;
            width: 20px;
            margin-right: 10px;
          }
        }
        .title {
          font-size: 14px;
          line-height: 21px;
          letter-spacing: -0.3px;
          color: #4f4f4f;
        }
      }
      .body {
        margin-top: 10px;
        font-size: 12px;
        line-height: 21px;
        letter-spacing: -0.3px;
        color: #4f4f4f;
      }
    }
  }
}
