.circle{
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 4px;
  margin-right: 4px;
  &.red{
    background-color: #EB5757;
  }
  &.green{
    background-color: #43D4A9;
  }
  &.orange{
    background-color: #FCBF22;
  }
}
.container{
  margin-top: 29px;
  .title{
    margin-bottom: 25px;
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 17.5px;
    line-height: 20px;
    letter-spacing: 0.146667px;
    color: #686F84;
  }
}