@import '../../../variables.scss';

.chip {
    width: fit-content;
    display: flex;
    height: 24px;
    padding: 4px 8px;
    align-items: center;
    justify-self: center;
    gap: 4px;
    border: 1px solid $gray002;
    background: $gray001;

    color: $gray003;
    text-align: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
}