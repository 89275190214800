.card {
  &.force {
    padding: 20px 10px;
    border-radius: 5px;
    background: #f7f8fA;
  }
  .container {
    display: flex;
    flex-direction: column;
    .top {
      display: flex;
      align-items: center;
      .logoContainer {
        width: 40px;
        height: 40px;
        .logo {
          width: 100%;
        }
      }
      .title {
        font-family: Inter;
        font-style: normal;
        font-weight: 600;
        font-size: 17px;
        line-height: 20px;
        color: #4f4f4f;
        padding-left: 10px;
      }
    }
    .bottom {
      display: flex;
      flex-direction: column;
      padding-left: 50px;
      .description {
        font-family: Inter;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 21px;
        color: #828282;
      }
      .link {
        border: none;
        color: #4376F9;
        align-self: flex-end;
        background: #f7f8fA;
      }
    }
  }
}
