@import '../../variables.scss';

$bottom-height: 49px;

header.header.noMobile {
  background: #fff;
  z-index: 2;
  width: 100%;
  padding: 0;
  height: initial;
  border-bottom: 1px solid #e8ecf3;
  display: flex;
  position: sticky;
  top: 0;

  &.ant-layout-header {
    height: inherit;
  }

  &.longHeader {
    display: initial;
    border-bottom: none;
  }

  .separator {
    border-bottom: 1px solid $gray002;
  }

  .bottomHeader {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 51px;
    border: 1px solid $gray002;

    .innerBottomHeader {
      max-width: $maxScreenWidth;
      width: 100%;

      ul.menu {
        border: 0;
        height: $bottom-height;
        line-height: $bottom-height;

        .subMenu {
          border-bottom: 0;
          line-height: $bottom-height;
        }
      }
    }
  }

  .topHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    max-width: 1440px;
    margin: auto;
    width: 100%;
    padding: 0 22px 0 0;

    @media(max-width:450px) {
      padding-right: 10px;
    }

    .logOutButton {
      padding: 4px 0 4px 15px;
    }

    .innerTopHeader {
      display: flex;
      flex-wrap: wrap;

      &.centered {
        justify-content: center;
        width: 100%;

        .logo {
          float: none;
        }
      }

      .logo {
        float: left;
        width: $sideMenuWidth;
        text-align: center;

        @media(max-width:450px) {
          width: 30vw;
        }

        img {
          width: 120px;
        }
      }

      .userMode {
        display: flex;
        align-items: center;
        max-width: 390px;
        padding: 0px 15px 0 0;

        @media (max-width: 450px) {
          display: none;
        }

        .text {
          width: 140px;
        }

        .button {
          padding: 4px 6px;
          margin-left: 6px;
        }
      }
    }

    .right {
      display: inline-flex;
      align-items: center;


      .divider {
        height: 30px;
        border-right: 1px solid #DEE2E9;
        margin-left: 10px;
        margin-right: 10px;
      }

      .kbMenu {
        border: 0;
        height: $bottom-height;
        line-height: $bottom-height;

        .subMenu {
          border-bottom: 0;
          line-height: $bottom-height;
          padding: 2px 15px;

          .icon {
            margin-right: 10px;
          }

          @media (max-width: 450px) {
            margin: 0 4px;

            .icon {
              margin-right: 0px;
            }
          }
        }

        .text {
          color: #81858b;
          font-weight: 500;
          font-size: 16px;
          line-height: 20px;

          @media (max-width: 768px) {
            display: none;
          }
        }
      }
    }
  }
}

.subMenuContainer {
  &:global(.ant-menu-submenu) {
    background-color: transparent;

    :global(.ant-menu) {
      margin-top: -13px;
      margin-left: -6px;
      border-radius: 10px;
      border: 1px solid #e8ecf3;
    }
  }
}

.iconMenu {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2px;
  cursor: pointer;
  margin-right: 32px;

  .icon {
    width: 26px;
    height: 26px;
  }

  .title {
    color: var(--grey, #81858B);
    text-align: center;
    font-feature-settings: 'clig' off, 'liga' off;

    /* caption */
    font-family: Inter;
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.3px;
  }
}

header.header.mobile {
  display: flex;
  padding: 9px 16px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  background: var(--white, #FFF);
  border-bottom: 1px solid var(--grey, #DEE2E9);

  .logo {
    display: flex;
    width: 32px;
    height: 32px;
    padding: 2.301px 5.294px 2.301px 5.355px;
    justify-content: center;
    align-items: center;

    img {
      width: 32px;
      height: 32px;
    }
  }
}

.bottom {
  position: fixed;
  z-index: 10;
  width: 100%;
  display: flex;
  padding: 17px 0px 32px 0px;
  border-top: 1px solid var(--grey, #DEE2E9);
  background: var(--white, #FFF);
  height: 40px + 17px + 32px;
  bottom: 0;
  justify-content: space-evenly;
  align-items: center;

  .iconMenu {
    margin-right: 0;
  }

  .logo {
    img {
      width: 32px;
      height: 32px;
    }
  }
}