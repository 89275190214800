.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 7px;

  .logo {
    display: flex;
    width: 68px;
    height: 14px;
    padding: 0px 0.028px 0.027px 0px;
    justify-content: center;
    align-items: flex-start;
    gap: 3.322px;
  }

  .actions {
    display: flex;
    align-items: flex-start;
    gap: 17px;

    .action {
      cursor: pointer;
      color: var(--Black, #2A2A2B);
      text-align: center;
      font-feature-settings: 'clig' off, 'liga' off;
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 21px;
      letter-spacing: -0.3px;
    }
  }
}