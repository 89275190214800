@import '../../../variables.scss';

.wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 22px;
    max-width: 100vw;

    .content {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        max-width: $maxScreenWidth;

        >* {
            width: 100%;
        }
    }
}