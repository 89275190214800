.course {
  max-width: 100%;
  display: flex;
  padding: 15px 23px;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  border-radius: 13px;
  background: var(--white, #FFF);

  &.mobile {
    padding: 15px;

    .titleContainer {
      .info {
        .titleAndDescription {
          .description {
            height: 32px;
            white-space: normal;
            line-clamp: 2;
            overflow: hidden;
            text-overflow: ellipsis;
            height: 32px;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
          }
        }
      }
    }

    .data {
      gap: 8px;
      margin-top: 6px;
    }
  }

  .titleContainer {
    max-width: 100%;
    display: flex;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;

    .info {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 10px;
      flex: 1 0 0;
      max-width: calc(100% - 32px);
      width: 100%;

      .categories {
        height: 24px;

        .category {
          display: flex;
          height: 24px;
          padding: 4px 8px;
          align-items: center;
          gap: 4px;
          border-radius: 4px;
          border-radius: 4px;
          border: 1px solid var(--grey, #DEE2E9);
          background: var(--gray-light, #F7F8FA);

          color: var(--grey, #81858B);
          text-align: center;
          font-family: Inter;
          font-size: 12px;
          font-style: normal;
          font-weight: 500;
          line-height: 16px;
        }
      }

      .titleAndDescription {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 3px;
        align-self: stretch;

        .title {
          height: 20px;
          align-self: stretch;
          overflow: hidden;
          color: var(--Black, #2A2A2B);
          font-feature-settings: 'clig' off, 'liga' off;
          text-overflow: ellipsis;
          white-space: nowrap;
          font-family: Inter;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 21px;
          letter-spacing: -0.3px;
        }

        .description {
          height: 16px;
          align-self: stretch;
          overflow: hidden;
          color: var(--grey, #81858B);
          font-feature-settings: 'clig' off, 'liga' off;
          font-family: Inter;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 16px;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }

    .goto {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-end;
      align-self: stretch;

      .button {
        cursor: pointer;
        display: flex;
        height: 32px;
        width: 32px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
    }
  }

  .data {
    display: flex;
    align-items: center;
    gap: 22px;
    margin-top: 4px;

    .priority {
      display: flex;
      align-items: center;
      gap: 3px;
      color: var(--grey, #81858B);
      font-feature-settings: 'clig' off, 'liga' off;
      font-family: Inter;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
    }

    .duration {
      display: flex;
      align-items: flex-start;
      gap: 3px;
      color: var(--grey, #81858B);
      font-feature-settings: 'clig' off, 'liga' off;
      font-family: Inter;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
    }

    .date {
      display: flex;
      align-items: flex-start;
      gap: 3px;
      color: var(--grey, #81858B);
      font-feature-settings: 'clig' off, 'liga' off;
      font-family: Inter;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
    }
  }
}

.container {
  display: flex;
  width: 537px;
  height: 1117px;
  padding: 41px 25px;
  align-items: flex-start;
  gap: 10px;
  flex-shrink: 0;
  border-radius: 13px;
  background: var(--gray-light, #F7F8FA);

  &.mobile {
    .innerContainer {
      .eventsContainer {
        width: calc(100vw - 25px);
      }
    }
  }

  .innerContainer {
    background-color: #f7f8fa;
    border-radius: 13px;
    display: flex;
    width: 100%;
    flex-flow: column;
    height: 100%;

    .header {
      display: flex;
      align-items: center;
      gap: 12px;

      .monthAndYear {
        font-size: 24.3019px;
        letter-spacing: 0.203673px;

        color: var(--SECONDARY, #213462);
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 30px;
        letter-spacing: -0.3px;
        width: 100px;

        .month {
          text-transform: capitalize;
          display: inline-block;
        }
      }

      .controls {
        display: flex;
        gap: 12px;
        align-items: center;

        .button {
          align-items: center;
          justify-content: center;
          display: flex;
          height: 30px;
          width: 30px;
          cursor: pointer;

          .icon {
            height: 24px;
            width: 24px;
          }
        }
      }
    }

    .eventsContainer {
      padding-top: 34px;
      padding-right: 25px;
      padding-bottom: 25px;
      display: flex;
      flex-direction: column;
      gap: 15px;
      width: 477px;
      overflow-y: auto;
      height: calc(100vh - 504px);
    }
  }
}