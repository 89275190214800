@import '../../../variables.scss';

#teurona .custom-search {
    border: none;
    background-color: $gray001 !important;
    border-radius: 4px;
    box-shadow: none;

    :focus {
        box-shadow: none;
    }

    .search-icon {
        color: $gray003;
        margin-right: 10px;
    }

    input.ant-input {
        background-color: $gray001;

        ::placeholder {
            color: $gray003;
        }
    }

    span.ant-input-suffix {
        .ant-input-search-icon::before {
            display: none;
        }
    }
}