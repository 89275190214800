.list {
    &:global(.ant-list) {
        padding: 10px 20px 10px 10px;
    }
    .item {
        font-family: Inter;
        font-style: normal;
        font-weight: normal;
        .field {
            font-size: 14px;
            line-height: 21px;
            letter-spacing: -0.3px;
            color: #4f4f4f;
        }
        .example {
            font-size: 12px;
            line-height: 16px;
            text-align: right;
            color: #81858b;
        }
    }
}
.infoText {
    margin-top: 20px;
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    color: #81858b;
}
.errorslist {
    .item {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
        .text {
            color: black;
            width: 100%;
        }
        .errorAlert{
            width: 100%;
            .error {
                display: inline-block;
                width: 100%;
            }
        }
    }
}

.adminUserSwitch{
    display: flex;
    justify-content: space-between;
}

.divider {
    border: 1px solid #E8ECF3;
    height: 1px;
    width: 100%;
    margin-top: 18px;
    margin-bottom: 14px;
}