@import '../../variables.scss';

.form {
    padding-bottom: $extraBottomPadding;
}

.evaluationButton {
    .square {
        margin-Left: 10px;
    }
}

.info {
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    letter-spacing: 0px;
    margin-bottom: 20px;
}

.delete {
    color: $red002;
}

.questionPopOver {
    display: flex;
    flex-direction: column;
    min-width: 200px;

    .deleteButton {
        color: #EB5757;
        text-align: start;
    }

    .duplicateButton {
        color: #686F84;
        text-align: start;
    }
}

.evaluationTypes {
    display: flex;
    gap: 10px;
    margin-bottom: 50px;
    flex-wrap: wrap;

    .title {
        flex-basis: 100%;
        font-size: 18px;
        font-weight: 600;
        line-height: 20px;
        letter-spacing: 0.1466670036315918px;
        text-align: left;
        color: $gray004;
    }

    .type {
        flex: 1;
        height: 57px;
        border-radius: 8px;
        border: 2px solid $gray002;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        font-weight: 600;
        line-height: 17px;
        letter-spacing: 0px;
        color: var(--secondary-text-button-color);
        cursor: pointer;

        &.selected {
            border-color: var(--secondary-text-button-color);
            background-color: var(--secondary-background-button-color);
        }
    }
}

.questionContainer {
    margin-bottom: 120px;


    .addQuestionButton {
        font-size: 16px;
        height: 48px;

        .square {
            background-color: $blue001;
            width: 16px;
            height: 16px;
            border-radius: 4px;
            margin-right: 8px;
        }
    }

    .addNewButton {
        width: 100%;
    }
}

.answerContainer {
    display: flex;

    :global(.ant-radio-inner) {
        height: 20px;
        width: 20px;

        &::after {
            // height: 10px;
            // left: 4px;
            // top: 4px;
            // width: 10px;
        }
    }

    :global(.ant-radio-checked .ant-radio-inner) {
        background-color: #ffffff;

        &::after {
            background-color: #4376F9;
        }
    }

    .fieldContainer {
        width: 100%;
    }

    .selectedAnswer {
        position: relative;
        top: 32px;
    }

    .rightAnswer {
        font-size: 10px;
        font-weight: 500;
        line-height: 12px;
        letter-spacing: -0.30000001192092896px;
        text-align: left;
        color: $green001;
        position: relative;
        top: -18px;
    }

    .actions {
        position: relative;
        top: 28px;
        display: flex;
        width: 84px;
        justify-content: space-evenly;

        .answerButton {
            width: 32px;
            height: 32px;
            margin-left: 10px;
            padding: 0px !important;
        }
    }
}

.saveButton {
    margin-right: 9px;
}