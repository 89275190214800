.container {
  :global(.ant-modal-header) {
    padding: 32px 16px;
    border-bottom: 0;

    :global(.ant-modal-title) {
      color: var(--Black, #2A2A2B);
      font-feature-settings: 'clig' off, 'liga' off;

      /* digit */
      font-family: Inter;
      font-size: 21px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      letter-spacing: -0.3px;
    }
  }

  :global(.ant-modal-body) {
    padding: 16px;
  }

  .tabs {
    :global(.ant-tabs-nav) {
      :global(.ant-tabs-nav-wrap) {
        :global(.ant-tabs-ink-bar) {
          border-radius: 11px;
          background: var(--main-color);
        }
      }
    }
  }

  .table {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 9px;
    align-self: stretch;
    max-height: calc(100vh - 300px);
    overflow-y: auto;

    .row {
      display: flex;
      padding: 12px 16px;
      justify-content: space-between;
      align-items: center;
      align-self: stretch;
      border-radius: 8px;
      &:nth-child(2n + 1) {
        background: var(--light, #F7F8FA);
      }

      .firstPart {
        display: flex;
        align-items: center;
        gap: 32px;

        .badge {
          width: 40px;
          height: 40px;
        }

        .user {
          display: flex;
          align-items: center;
          gap: 16px;

          .avatar {
            width: 46px;
            height: 46px;
            border-radius: 36px;
            border: 1px solid var(--white, #FFF);
            .picture{
              width: 46px;
              height: 46px;
              border-radius: 36px;
            }
          }

          .name {
            color: var(--Black, #2A2A2B);
            font-feature-settings: 'clig' off, 'liga' off;
            font-family: Inter;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 21px;
            /* 131.25% */
            letter-spacing: -0.3px;
          }
        }
      }

      .points {
        font-feature-settings: 'clig' off, 'liga' off;

        /* digit */
        -webkit-text-stroke-width: 54.57499694824219;
        -webkit-text-stroke-color: var(--SECONDARY, #213462);
        font-family: Inter;
        font-size: 21px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: -0.3px;
      }
    }
  }
}

.mobile.container {}