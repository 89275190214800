@import '../../variables.scss';

.pill {
  height: 24px;
  padding: 4px 10px 4px 10px;
  border-radius: 8px;
  gap: 8px;
  background-color: $gray001;
  //styleName: small;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: left;
  color: $gray004;
}