@import "../../../variables.scss";

.container {
  display: flex;
  width: 247px;
  padding: 28px 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 25px;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid var(--white, #FFF);
  background: var(--white, #FFF);

  /* Shadow */
  box-shadow: 0px 4px 16px 0px rgba(51, 51, 51, 0.08), 0px 4px 4px 0px rgba(51, 51, 51, 0.04);

  .title {
    color: var(--grey, #81858B);
    font-feature-settings: 'clig' off, 'liga' off;

    /* Subtitle */
    font-family: Inter;
    font-size: 17.5px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    /* 114.286% */
    letter-spacing: 0.147px;
  }

  .innerContainer {
    display: flex;
    width: 202px;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;

    .icon {
      width: 89px;
      height: 90px;
    }

    .timeAndDescription {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 3px;
      align-self: stretch;

      .time {
        color: var(--Black, #2A2A2B);
        font-feature-settings: 'clig' off, 'liga' off;

        /* bignumber */
        font-family: Inter;
        font-size: 40px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }

      .description {
        color: var(--grey, #81858B);
        font-feature-settings: 'clig' off, 'liga' off;

        /* paragraph */
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        /* 125% */
        letter-spacing: -0.3px;
      }
    }
  }
}

.container.mobile {
  width: 100%;
  
  .innerContainer {
    flex-direction: row;
    width: 100%;
  } 
}