.container {
  display: flex;
  padding-bottom: 16px;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
  border-bottom: 1px solid var(--grey, #DEE2E9);
  cursor: pointer;
  max-width: 100%;

  &.mobile {
    border: 1px solid var(--grey, #DEE2E9);
    border-radius: 16px;
    min-width: 241px;
    max-width: 241px;
    padding: 16px;

    .content {
      .title {
        font-size: 12px;
        height: 30px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
      .description {
        line-clamp: 2;
        -webkit-line-clamp: 2;
      }
    }

  }

  &:last-child {
    border-bottom: 0;
  }

  

  .icon {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 36px;
    flex: 1 0 0;
    width: 40px;

    .iconContainer {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 40px;
      height: 40px;
      border-radius: 20px;
      background-color: #F2F2F2;

    }
  }

  .content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;
    max-width: calc(100% - 60px);
    width: 100%;

    .title {
      color: var(--Black, #2A2A2B);
      font-feature-settings: 'clig' off, 'liga' off;
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      max-width: 100%;
    }

    .description {
      color: var(--Gray-2, #4F4F4F);
      font-feature-settings: 'liga' off, 'clig' off;
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-overflow: ellipsis;
      max-width: 100%;
      overflow: hidden;
      line-clamp: 3;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      display: -webkit-box;
    }

    .link {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      align-self: stretch;
      gap: 9px;

      .text {
        color: var(--tertiary-text-button-color);
        text-align: center;
        font-feature-settings: 'liga' off, 'clig' off;
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
        /* 125% */
        letter-spacing: -0.3px;
        text-overflow: ellipsis;
        max-width: calc(100% - 33px);
        overflow: hidden;
      }

      .iconContainer {
        width: 24px;
        height: 24px;

        .icon {
          transform: rotate(-132deg);
          width: 24px;
          height: 24px;
        }
      }
    }
  }
}