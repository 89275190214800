.title{
  color: #686F84;
  font-size: 17.5px;
  font-weight: bold;
}
.filterContainer{
  margin-top: 10px;
  margin-bottom: 20px;
}
.legend{
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  .item{
    display: flex;
    flex-direction: row;
    margin-right: 20px;
    align-items: center;
    justify-content: center;
    .square{
      width: 50px;
      height: 16px;
      margin-right: 8px;
      &.blue{
        background-color: #9ed1f4;
      }
      &.pink{
        background-color: #feb2c2;
      }
    }
  }
}