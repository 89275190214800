@import '../../variables.scss';

.container {
  padding: 1rem 0;
  border-top: 1px solid $gray008;
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: auto;
  position: relative;
  bottom: 0;

  .info {
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    letter-spacing: 0px;
    text-align: left;
    color: $gray003;
  }

  .link {
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: -0.30000001192092896px;
    text-align: center;
    color: $blue001;
  }
}