.title {
  color: #686f84;
  display: flex;
  font-size: 17.5px;
  font-weight: 600;
  margin-bottom: 30px;
}
.chartContainer {
  margin-bottom: 10px;
}

.tooltip {
  padding-left: 20px;
}