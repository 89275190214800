$evaluationHeight: 496px;

.container {
  position: relative;
  margin-top: 24px;

  .seekBlocker {
    height: 100px;
    background-color: transparent;
    position: relative;
    top: -100px;
    margin-left: 75px;
    margin-right: 102px;
    margin-bottom: -100px;

    &.mobile {
      margin-right: 65px;
    }

    &.youtube {
      display: none;
    }
  }

  .quizContainer {
    height: 100vh;
    width: 100vw;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
  }

  .vimeo {
    iframe {
      margin-bottom: 20px;
    }
  }

  .slider {
    position: relative;
    margin-top: -4px;

    &.fullscreen {
      position: fixed;
      z-index: 1;
      width: 100%;
      left: 0;
      bottom: 10px;
    }

    :global(.ant-slider-handle) {
      display: none;
    }

    :global(.ant-slider-step) {
      &:global(.ant-slider-dot-active) {
        //   &:last-of-type {
        height: 16px;
        width: 16px;
        top: -6px;
        margin-left: -7px;
        //   }
      }
    }
  }
}