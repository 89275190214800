.container {
  display: flex;
  width: 1000px;
  flex-direction: column;
  align-items: flex-end;
  gap: 24px;
  margin-bottom: 40px;

  .twoColumns {
    display: flex;
    width: 1001px;
    align-items: flex-start;
    gap: 24px;

    .column {
      flex: 1 0 0;
      align-self: stretch;

      &.small {
        display: flex;
        width: 247px;
        flex: 0;
      }

      &.wide {
        height: 306px;
        width: 100%;
      }
    }
  }

  .oneColumn {
    display: flex;
    width: 100%;
  }
}

.mobile.container {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-end;
  gap: 24px;
  margin-bottom: 40px;
  padding: 0 8px;

  .twoColumns {
    display: flex;
    width: 100%;
    align-items: flex-start;
    gap: 16px;
    .column {
      flex: unset;
      width: 50%;
    }
  }
}