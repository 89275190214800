@import '../../variables.scss';

.addMediaButton {
  font-size: 16px;
  width: 100%;
  min-height: 40px;

  .square {
    background-color: $blue001;
    width: 16px;
    height: 16px;
    border-radius: 4px;
    margin-right: 8px;
  }
}

.backButton {
  color: #686f84;
  font-size: 16px;
  margin: 0;
  padding: 0;
  background: transparent;
  border: none;
  font-weight: 500;
  margin-bottom: 9px;
  text-align: left;

  span {
    margin-left: 10px;
  }
}

.formSubtitle {
  margin: 0px;
  color: #4f4f4f;
  font-size: 17.5px;
  font-weight: 600;
  letter-spacing: 0.146px;
}

.formParagraph {
  margin: 16px 0px;
  font-size: 12px;
}

.formDescription {
  color: #81858b;
  margin: 16px 0px;
  font-size: 12px;
}

.uploadContainer {
  :global(.ant-upload) {
    width: 100%;
  }
}

.imageContainer {
  height: 185px;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  padding: 10px;
  border-radius: 8px;
  margin-bottom: 16px;

  .deleteButton {
    background-color: #686f84;
    border-radius: 8px;
    padding: 0;
    width: 32px;
    height: 32px;
    border: none;

    &:hover,
    &:focus,
    &:active {
      background-color: #686f84;
      border: 1px solid #fff;
    }
  }
}

.fileContainer {
  height: 83px;
  background-color: #f7f8fa;
  position: relative;
  padding: 14px;
  border-radius: 8px;
  margin-bottom: 16px;
  color: #686f84;
  text-align: left;

  &.video {
    height: fit-content;
  }

  p {
    font-size: 14px;
  }

  span {
    color: #81858b;
    font-size: 12px;
  }

  .deleteButton {
    position: absolute;
    background-color: #686f84;
    border-radius: 8px;
    padding: 0;
    width: 32px;
    height: 32px;
    border: none;
    right: 16px;
    bottom: 16px;

    &:hover,
    &:focus,
    &:active {
      background-color: #686f84;
      border: 1px solid #fff;
    }
  }

  .label {
    font-weight: 600;
    font-size: 14px;
  }

  .videoContainer {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-self: center;
  }
}

.switchContainer {
  margin: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.teacherContainer {
  :global(.ant-upload.ant-upload-select-picture-card) {
    width: auto;
    height: auto;
  }
}

.listMediaContainer {
  text-align: center;
}

.mediaContainer {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  gap: 16px;
  margin: 16px 0 44px;

  .mediaButton {
    background: white;
    width: 33.33%;
    height: 114px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    color: var(--secondary-text-button-color);
    border-radius: 8px;
    border: 2px solid $gray002;
    font-size: 14px;
    font-weight: 600;
    line-height: 17px;
    letter-spacing: 0px;
    text-align: center;


    &:hover,
    &:focus {
      color: var(--secondary-text-button-color);
      border: 2px solid var(--secondary-text-button-color);
      background-color: var(--secondary-background-button-color);

      svg {
        path {
          fill: var(--secondary-text-button-color);
        }
      }
    }

    &:disabled {
      color: var(--secondary-text-button-color);
      border: none;

      svg {
        path {
          fill: $blue001;
        }
      }
    }

    svg {
      path {
        fill: var(--secondary-text-button-color);
      }
    }
  }

  .selected {
    color: var(--secondary-text-button-color);
    border: 2px solid var(--secondary-text-button-color);
    background-color: var(--secondary-background-button-color);

    svg {
      path {
        fill: var(--secondary-text-button-color);
      }
    }
  }
}

.sectionRow {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;

  .sectionInfo {
    background-color: #f7f8fa;
    padding-left: 9px;

    span {
      font-size: 14px;
      padding: 9px 0 9px 11px;
    }
  }
}

.uploadZone {
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='6' ry='6' stroke='black' stroke-width='1' stroke-dasharray='6' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
  background-color: $gray001;
  width: 100%;
  height: 169px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .spin {
    position: absolute;
    background-color: $gray002AlfaD0;
    width: calc(100% - 102px);
    height: 167px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .icon {
    width: 67px;
  }

  .description {
    font-size: 13px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: -0.23796026408672333px;
    text-align: center;
    color: $blue003;

    .fakeLink {
      color: var(--tertiary-text-button-color);
    }
  }

  .specifications {
    //styleName: caption;
    font-size: 10px;
    font-weight: 500;
    line-height: 12px;
    letter-spacing: -0.30000001192092896px;
    text-align: center;
    color: $gray003;
  }
}