$imageSize: 98px;
$imageBorder: 4px;

.userData.noMobile {
  width: 292px;
  background-color: white;
  border-radius: 16px;
  border: 1px solid var(--grey, #DEE2E9);
  background: #FFF;
  overflow: hidden;
  padding: 0 0 29px 0;

  .top {
    height: 54px;
    background: var(--grey, #DEE2E9);
  }

  .imageAndData {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 11px;
    position: relative;
    top: -25px;
    margin-bottom: -25px;

    .imageContainer {
      padding: $imageBorder;
      border-radius: 111px;
      background-color: white;
      width: fit-content;

      .image {
        position: relative;

        max-width: $imageSize;
        max-height: $imageSize;
        width: $imageSize;
        height: $imageSize;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        border-radius: 90px;
      }
    }

    .data {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 8px;

      .userName {
        color: var(--Black, #2A2A2B);
        text-align: center;
        font-feature-settings: 'clig' off, 'liga' off;

        /* Button */
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        /* 125% */
        letter-spacing: -0.3px;
      }

      .group {
        color: var(--gray, #686F84);
        text-align: center;
        font-feature-settings: 'clig' off, 'liga' off;

        /* Body */
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 21px;
        /* 150% */
        letter-spacing: -0.3px;
      }
    }
  }
}

.mobile {
  display: flex;
  width: 375px;
  padding: 36px 16px;
  justify-content: space-between;
  align-items: flex-start;
  border-top: 1px solid var(--grey, #DEE2E9);
  border-bottom: 1px solid var(--grey, #DEE2E9);
  background: var(--gray-light, #F7F8FA);

  .userData {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 16px;
    background: var(--gray-light, #F7F8FA);

    .avatar {
      width: 67px;
      height: 67px;
      border-radius: 111px;
      border: 3px solid var(--white, #FFF);

      .image {
        width: 67px;
        height: 67px;
        flex-shrink: 0;
        border-radius: 111px;
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat no-repeat;
      }
    }

    .user {
      .userName {
        color: var(--Black, #2A2A2B);
        text-align: center;
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: Inter;
        font-size: 21px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: -0.3px;
      }

      .group {
        color: var(--gray, #686F84);
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 21px;
        letter-spacing: -0.3px;
      }
    }
  }

  .points {}
}