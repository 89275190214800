@import '../../variables.scss';

:global(#teurona) {
  :global(.ant-input-affix-wrapper).search {
    margin-top: 6px;
    margin-left: 16px;

    &.wrapper {
      background: #f7f8fa;
      border-radius: 5px;
      border-color: #f7f8fa;
      padding: 8px 4px 8px 12px;
      border-width: 0;
      width: calc(100% - 30px);

      :global(.ant-input) {
        max-width: calc(100% - 10px);
      }
    }
  }

  .targets {
    margin-top: 5px;
    overflow: auto;
    max-height: 240px;
    height: 100%;
    padding-right: 20px;
    padding-bottom: 20px;
    margin-left: 10px;

    .target {
      padding: 14px 10px;
      margin-top: 5px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      min-height: 50px;
      font-size: 16px;
      line-height: 20px;
      color: #4f4f4f;
      cursor: pointer;
      display: block;

      &.selected {
        background: #e8ecf3;
        border-radius: 4px;
        color: $blue001;
      }
    }
  }
}

.wrapper {
  display: flex;
}