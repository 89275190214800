.container{
    padding-bottom: 100px;
}
:global(.ant-back-top).backUpTop{
    margin-top: 46px;
    height: 30px;
    border-radius: 4px;
    display: flex;
    justify-content: flex-end;
    width: 140px;
    background-color: #aaaaaa50;
    padding: 4px 10px;
    .link{
        cursor: pointer;
        color: #4376F9;
        .icon{
            margin-left: 10px;
        }
    }
}
