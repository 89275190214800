.status {
    background: #F7F8FA;
    border-radius: 8px;
    display: flex;
    max-width: 130px;
    padding: 4px 10px;
    align-items: center;
    justify-items: center;
    margin-top: -10px;
    margin-bottom: -10px;

    .color {
        width: 8px;
        height: 8px;
        display: inline-block;
        border-radius: 4px;
        margin-right: 8px;

        &.green {
            background: #43D5A9;
        }

        &.red {
            background: #d54843;
        }
    }
}