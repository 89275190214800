.grayBackground {
  height: 317px;
  background-color: #f7f8fa;
  position: absolute;
  left: 0;
  width: 100vw;
}

$columnWidth: 292px;
$middleColumnWidth: 616px;
$columnsGap: 32px;

.container.nomobile {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #F7F8FA;

  .innerContainer {
    display: flex;
    gap: $columnsGap;
    width: calc(#{$columnWidth} + 2 * #{$columnsGap} + #{$columnWidth} + #{$middleColumnWidth});

    .firstColumn {
      min-width: $columnWidth;
      width: $columnWidth;
      padding-top: 43px;

      .fixedContainer {
        position: fixed;
        display: flex;
        min-width: $columnWidth;
        width: $columnWidth;
        flex-direction: column;
        align-items: flex-start;
        gap: 25px;
        z-index: 1;
      }
    }

    .middleColumn {
      padding-top: 43px;
      width: 100%;
      max-width: $middleColumnWidth;
      display: flex;
      gap: 40px;
      flex-direction: column;
    }

    .lastColumn {
      padding-top: 43px;
      width: $columnWidth;
      min-width: $columnWidth;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 16px;
    }
  }
}

.container.mobile {
  background-color: #F7F8FA;
}