.displayField {
  display: flex;
  justify-content: space-between;
}
.blueprintDropdown {
  :global(.ant-btn) {
    align-items: center;
    display: flex;
    justify-content: space-between;
  }
  .typeButton {
    width: 100%;
  }
}

.options {
  :global(.ant-form-item) {
    margin-top: 0px;
    &:first-child {
        margin-top: 0;
    }
  }
}
.templateWrapper {
  border-radius: 8px;
  height: 262px;
  margin-bottom: 40px;
  overflow: hidden;
  width: 374px;
}
.empty {
  background-color: #F7F8FA;
  border: 2px dashed #E8ECF3;
}