.container {
  display: flex;
  padding-bottom: 16px;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
  border-bottom: 1px solid var(--grey, #DEE2E9);
  cursor: pointer;

  &.mobile {
    border: 1px solid var(--grey, #DEE2E9);
    border-radius: 16px;
    min-width: 241px;
    max-width: 241px;
    padding: 16px;
    .content {
      .title {
        font-size: 12px;
        height: 30px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
    }

  }

  &:last-child {
    border-bottom: 0;
  }

  .icon {
    width: 40px;
    height: 40px;
  }

  .content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;

    .categories {
      .category {
        display: flex;
        padding: 4px 7px;
        align-items: center;
        gap: 10px;
        border-radius: 4px;
        background: var(--grey, #DEE2E9);
        color: var(--gray, #686F84);
        text-align: center;
        font-family: Inter;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 16px;
      }
    }

    .title {
      color: var(--Black, #2A2A2B);
      font-feature-settings: 'clig' off, 'liga' off;
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }

    .publishDate {
      display: flex;
      align-items: flex-start;
      gap: 3px;
      color: var(--grey, #81858B);
      font-feature-settings: 'clig' off, 'liga' off;
      font-family: Inter;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
    }
  }
}