.summaryCard {
  :hover {
    cursor: pointer;
  }
  &.border {
    border-radius: 8px;
  }
  .title {
    font-weight: 600;
    font-size: 17px;
    line-height: 20px;
    color: #4f4f4f;
  }
  .description {
    font-size: 14px;
    line-height: 21px;
    margin-top: 17px;
  }
  .meta {
    margin-top: 17px;
    color: #81858b;
  }
}
