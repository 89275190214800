@import '../../../variables.scss';

.bodyFullscreen {
  overflow: hidden;
}

.pdfViewerContainer {
  width: 100%;
}

.container {
  max-width: 100%;
  width: 100%;
  height: 500px;
  overflow: auto;
  position: relative;
  background-color: $gray007;
  display: flex;
  text-align: center;
  border-radius: 8px;
  margin-top: 20px;

  &.fullscreen {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 100;
    border-radius: 0;
    margin-top: 0;
  }

  .pdfViewer {
    max-width: fit-content;
    margin: auto;
    margin-top: auto;
  }
}

.tools {
  position: relative;
  top: -70px;
  width: fit-content;
  background-color: $gray005;
  color: white;
  padding: 7px 14px 7px 14px;
  border-radius: 12px;
  height: 46px;
  display: flex;
  margin: auto;
  margin-bottom: -40px;

  &.fullscreen {
    position: fixed;
    z-index: 101;
    bottom: 70px;
    left: 50%;
    transform: translateX(-50%);
  }

  * {
    -webkit-user-select: none;
    /* Safari */
    -ms-user-select: none;
    /* IE 10 and IE 11 */
    user-select: none;
    /* Standard syntax */
  }


  .button {
    cursor: pointer;
    height: 30px;
    width: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .navigator {
    display: flex;
    padding: 8px 10px 8px 10px;
    background: $whiteAlfa21;
    border-radius: 9px;
    height: 32px;
    font-size: 10px;
    font-weight: 500;
    line-height: 12px;
    letter-spacing: -0.30000001192092896px;
    text-align: center;
    margin-left: 20px;
    margin-right: 20px;

    .pageIndicator {
      padding: 4px;
      display: flex;
      align-items: center;
      justify-content: center;

    }

    .button {
      width: 16px;
      height: 16px;
      font-size: 16px;
    }
  }
}