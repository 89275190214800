.container {
  display: flex;
  height: 306px;
  padding: 28px 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 25px;
  flex: 1 0 0;
  border-radius: 8px;
  border: 1px solid var(--white, #FFF);
  background: var(--white, #FFF);

  /* Shadow */
  box-shadow: 0px 4px 16px 0px rgba(51, 51, 51, 0.08), 0px 4px 4px 0px rgba(51, 51, 51, 0.04);

  .titleContainer {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    align-self: stretch;

    .title {
      color: var(--grey, #81858B);
      font-feature-settings: 'clig' off, 'liga' off;
      font-family: Inter;
      font-size: 17.5px;
      font-style: normal;
      font-weight: 600;
      line-height: 20px;
      /* 114.286% */
      letter-spacing: 0.147px;

    }

    .viewRankings {
      display: flex;
      height: 48px;
      padding: 16px 20px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      border-radius: 8px;
      border: 2px solid var(--main-color);
      background: var(--white, #FFF);

      color: var(--main-color);
      text-align: center;
      font-feature-settings: 'clig' off, 'liga' off;

      /* button */
      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 20px;
      /* 125% */
      letter-spacing: -0.3px;
      cursor: pointer;
    }
  }

  .values {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 25px;
    align-self: stretch;

    .row {
      display: flex;
      align-items: flex-start;
      gap: 25px;
      align-self: stretch;

      .value {
        display: flex;
        padding: 16px;
        align-items: center;
        gap: 25px;
        flex: 1 0 0;
        border-radius: 16px;
        border: 1px solid var(--grey, #DEE2E9);

        .icon {
          width: 40px;
          height: 40px;
        }

        .description {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 12px;
          flex: 1 0 0;
          color: var(--grey, #81858B);
          font-feature-settings: 'clig' off, 'liga' off;
          font-family: Inter;
          font-size: 17.5px;
          font-style: normal;
          font-weight: 600;
          line-height: 20px;
          /* 114.286% */
          letter-spacing: 0.147px;
        }
      }
    }
  }
}

.mobile.container {
  height: fit-content;
  .titleContainer {
    align-items: center;
  }
  .values {
    .row {
      flex-direction: column;
      .value {
        width: 100%;
      }
    }
  }
}