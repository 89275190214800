@import '../../../variables.scss';

.container {
  display: flex;
  flex-direction: column;
  max-width: 1440px;

  .emptyMessage {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    height: 30px;
    color: #828282;
    font-size: 14px;
  }

  .titleContainer {
    display: flex;
    justify-content: space-between;
    margin-bottom: 41px;

    .title {
      color: #4f4f4f;
      font-weight: bold;
      font-size: 17px;
    }

    .linkContainer {
      .link {
        color: $blue001;
        font-size: 16px;
      }
    }
  }

  .innerContainer {
    display: flex;
    flex-direction: column;
    min-height: 135px;
  }

  .moreWrapper {
    margin-top: 45px;

    .width100 {
      width: 100%;
    }
  }
}