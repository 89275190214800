.container {
    border: 1px solid #E8ECF3;
    box-sizing: border-box;
    border-radius: 8px;
    display: flex;
    align-items: center;
    padding-left: 20px;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    /* 125% */
    letter-spacing: -0.3px;

    .selected {
        color: #4376F9;
    }

    .title {
        cursor: pointer;
    }
}

.cannotGoThere {
    cursor: default;

    .title {
        cursor: default;
    }
}