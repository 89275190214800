.container {
  width: 100%;
  text-align: center;
  .innerContainer {
    display: inline-block;
    width: fit-content;
  }
}
.flag {
  width: 24px;
  padding-right: 6px;
}