.report {
  align-items: center;
  justify-content: flex-start !important;
}

.optionsContainer {
  display: flex;

  .tooltip {
    align-items: center;
    display: flex;
    margin-left: 10px;
  }
}