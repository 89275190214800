.container {

  .innerContainer {
    background-color: white;
    display: flex;
    padding: 24px;
    flex-direction: column;
    align-items: flex-start;
    gap: 13px;
    align-self: stretch;
    border-radius: 16px;
    border: 1px solid var(--grey, #DEE2E9);
    background: var(--white, #FFF);

    .header {
      display: flex;
      width: 587px;
      align-items: flex-start;
      gap: 16px;

      &.mobile {
        width: 100%;
      }

      .icon {
        width: 48px;
        height: 48px;
        flex-shrink: 0;
      }

      .info {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        gap: 8px;
        flex: 1 0 0;
        align-self: stretch;

        .title {
          color: var(--Black, #2A2A2B);
          font-feature-settings: 'clig' off, 'liga' off;
          font-family: Inter;
          font-size: 17.5px;
          font-style: normal;
          font-weight: 600;
          line-height: 20px;
          letter-spacing: 0.147px;
        }

        .courseInfo {
          display: flex;
          align-items: flex-start;
          gap: 7px;

          color: var(--grey, #81858B);
          font-feature-settings: 'clig' off, 'liga' off;
          font-family: Inter;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 21px;
          /* 150% */
          letter-spacing: -0.3px;
        }
      }
    }

    .imageWrapper {
      width: 100%;
      position: relative;
      cursor: pointer;

      .play {
        position: absolute;
        width: 44px;
        height: 44px;
        flex-shrink: 0;
        top: calc(50% - 22px);
        left: calc(50% - 22px);
      }

      .image {
        width: 100%;
        height: 220px;
        flex-shrink: 0;
        border-radius: 15px;
      }
    }
  }

  &.mobile {
    .innerContainer {
      border-radius: 0;
      .imageWrapper {
        .image {
          height: 132px;
        }
      }
    }
  }
}