.drawer {
  :global(.ant-drawer-content-wrapper) {
    width: 422px !important;
  }
  .container {
    width: 422px;
    .none {
      padding: 20px 10px;
    }
    .notification {
      display: flex;
      height: 70px;
      margin-top: 15px;
      .iconContainer {
        width: 64px;
        height: 70px;
        display: flex;
        align-items: center;
        justify-content: center;
        .background {
          width: 32px;
          height: 32px;
          background-color: #f7f8fa;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 8px;
          .icon {
            height: 40px;
          }
        }
      }
      .content {
        max-width: 330px;
        overflow: hidden;
        .title {
          font-size: 14px;
          line-height: 21px;
          letter-spacing: -0.3px;
          color: #4f4f4f;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }
        .body {
          font-size: 14px;
          line-height: 21px;
          letter-spacing: -0.3px;
          color: #81858b;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }
        .date {
          font-size: 12px;
          line-height: 16px;
          color: #81858b;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }
      }
    }
  }
}
