.mainWrapper {  
  .header {
    display: flex;
    flex-direction: row;
    padding-top: 20px;
    padding-bottom: 20px;
    align-items: center;
  }
  .downloadButton {
    background-color: #e8ecf3;
    border-color: #e8ecf3;
    height: 40px;
    margin-left: 34px;
    text-align: left;
    width: 139px;
    span {
      color: #686f84;
      font-size: 16px;
      font-weight: 500;
      margin-right: 15px;
    }
  }
}
  