.mainWrapper {
  background-color: #f7f8fa;
  display: none;
  @media print {
    display: block;
  }

  .content {
    max-width: 1140px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 20px 20px;
    @media print {
      padding: 40px 20px;
    }
  }
  .logo {
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      max-width: 140px;
    }
  }
  .count {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 16px;
    color: #81858b;
  }
}
