@import '../../../variables.scss';

.surveyModal {
  :global(.ant-modal-content) {
    border-radius: 8px;
  }
}

.container {
  width: 100%;
  background-color: #606060a0;
  position: relative;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;

  &.survey {
    background-color: white;

    :global(.ant-card).question {
      max-width: 100%;
      border: 0;

      :global(.ant-card-body) {
        padding: 0;
      }

      :global(.ant-card-actions) {
        &>li {
          margin: 0;

          .buttonContainer {
            text-align: right;
            padding-right: 10px;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            padding-right: 19px;
            background: #f7f8fa;
            height: 68px;
          }
        }
      }
    }
  }

  &.test {
    margin-top: 14px;
    background-color: white;
    width: 100%;

    :global(.ant-card).question {
      max-width: 100%;

      :global(.ant-card-actions) {
        &>li {
          margin: 0;

          .buttonContainer {
            text-align: right;
            padding-right: 10px;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            padding-right: 19px;
            background: #f7f8fa;
            height: 68px;
          }
        }
      }
    }
  }

  .question {
    width: 100%;
    max-width: 370px;
    border-radius: 8px;

    .answerInfoIcon {}

    .answerInfoLabel {
      font-size: 17.5px;
      line-height: 20px;
      font-weight: 600;
      margin-top: 17px;
      color: #4f4f4f;
    }

    .feedback {
      font-size: 12px;
      line-height: 16px;
      margin-top: 12px;
      color: #4f4f4f;
    }

    .counter {
      font-size: 12px;
      line-height: 16px;
      color: #81858b;
    }

    .title {
      font-weight: 600;
      font-size: 17.5px;
      line-height: 20px;
      letter-spacing: 0.146667px;
      color: #4f4f4f;
      margin-top: 16px;
    }

    .subTitle {
      font-weight: normal;
      font-size: 12px;
      line-height: 16px;
      color: #81858b;
      margin-top: 8px;
    }

    .answers {
      width: 100%;
      margin-top: 24px;

      .answer {
        display: flex;
        padding: 11px 8px;
        margin-bottom: 8px;
        border-radius: 8px;
        min-height: 42px;
        width: 100%;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: -0.3px;
        color: #4f4f4f;

        &:global(.ant-radio-wrapper-checked) {
          background-color: $gray001;
          color: $blue001;
        }

        &:hover {
          background-color: #f7f8fa;
        }

        &.correct {
          background: rgba(103, 221, 45, 0.08);
        }

        &.wrong {
          background: rgba(255, 57, 33, 0.08);
        }

        >span:last-of-type {
          width: 100%;
          white-space: pre-wrap;
          margin-left: 2px;
        }
      }
    }

    .buttonContainer {
      text-align: right;
      padding-right: 10px;
    }
  }
}

:global(.ant-message-notice).feedbackToast {
  margin-top: calc(50vh - 50px);
  display: flex;
  justify-content: center;
  width: 100vw;
  position: absolute;

  @media (max-width: 375px) {
    margin-top: 20px;
  }

  :global(.ant-message-notice-content) {
    position: absolute;
    bottom: 26px;
    border-radius: 8px;
    box-shadow: 0px 2px 12px rgba(23, 29, 41, 0.12);
    text-align: left;
    width: 100%;
    max-width: 450px;

    @media (max-width: 375px) {
      width: 90%;
      left: 5%;
      margin-top: 0;
      top: 5%;
      height: fit-content;
    }

    :global(.ant-message-custom-content) {
      display: flex;
      align-items: center;

      .innerContainer {
        padding: 10px 15px;

        .title {
          color: #4f4f4f;
          font-weight: 600;
          font-size: 17.5px;
          line-height: 20px;
        }

        .feedback {
          font-size: 12px;
          line-height: 16px;
          color: #4f4f4f;
          margin-top: 10px;
        }
      }
    }
  }
}