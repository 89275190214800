.container {
  .success {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-weight: 600;
    font-size: 1.8em;
    line-height: 1em;
  }
  .img {
    width: 100%;
  }
}
