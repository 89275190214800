.container {
    width: 200px;

}

.drawer {
    &.mobile {
        :global(.ant-drawer-content-wrapper) {
            width: 100vw !important;
            border-radius: 0;
        }
    }

    :global(.ant-drawer-content-wrapper) {
        width: 500px !important;
        border-radius: 0;
    }
}

.calendar {
    max-height: 1000px;
    height: 400px;
}

.headerContainer {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #E8ECF3;
    margin-bottom: 10px;

    .header {
        font-size: 24px;
        color: #4F4F4F;
    }

    .buttons {
        .button {
            display: inline-block;
            height: 30px;
            width: 30px;
            cursor: pointer;
            margin-left: 10px;

            .icon {
                height: 24px;
                width: 24px;
            }
        }
    }
}

:global(.rbc-month-view) {
    border: 0;

    :global(.rbc-header) {
        border: 0;
    }

    :global(.rbc-day-bg) {
        border: 0;
    }

    :global(.rbc-month-row) {
        border: 0;
    }
}

.event {
    overflow: hidden;
    height: 2px;

    &.high {
        background-color: #EB5757;
    }

    &.medium {
        background-color: #F2C94C;
    }

    &.low {
        background-color: #20a471;
    }
}

:global(.ant-btn).calendarButton {
    background-color: #F7F8FA;
    height: 40px;
    width: 140px;
    border: 0;
    border-radius: 4px;
    color: #686F84;
    font-size: 16px;
    padding: 0px 11px;
    cursor: pointer;
}