@import '../../variables.scss';

.container {
  width: 372px;

  .title {
    font-weight: 600;
    font-size: 18px;
    line-height: 20px;
    color: #4F4F4F;
  }

  .subtitle {
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    color: #4F4F4F;
  }

  .label {
    margin-top: 20px;

    &:first-child {
      margin-top: 5px;
    }
  }

  .lastField {
    margin-bottom: 16px;
  }

  .successImage {
    width: 315px;
  }

  .successText {
    font-weight: 600;
    font-size: 28px;
    line-height: 28px;
    text-align: center;
    color: #4F4F4F;
  }
}

.requirement {
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  color: $gray007;
}