@import '../../variables.scss';

:global(#teurona) {
  .container {
    padding-bottom: $extraBottomPadding;

    .formWrapper {
      overflow: auto;
      padding: 0 0 120px;

      .rangePicker {
        color: #c1c1c1;
        width: 100%;

        :global(.ant-picker-input) {
          width: 95px;
        }

        :global(.ant-picker-range-separator) {
          color: #c1c1c1;
        }

        :global(.ant-picker-suffix) {
          margin-left: auto;
        }

        input {
          text-align: center;
        }
      }

      .card {
        border-radius: 8px;

        :global(.ant-card-body) {
          padding: 24px 16px;
        }

        h2 {
          font-size: 18px;
          font-weight: 600;
          letter-spacing: 0.15px;
        }

        p {
          font-size: 12px;
          font-weight: 400;
        }

        .actions {
          display: flex;
          justify-content: space-between;

          .role {
            color: #686f84;
            font-size: 12px;
            padding: 2px 16px;

            .square {
              width: 14px;
              height: 14px;
            }

            span {
              margin-left: 8px;
            }
          }
        }

        .addRoleButton {
          align-items: center;
          display: flex;
          justify-content: space-between;
          width: 100%;
        }
      }
    }
  }
}