@import '../../../variables.scss';

:global(.ant-card).container {
    border-radius: 8px;

    :global(.ant-card-body) {
        width: 100%;

        .category {
            display: flex;
            width: fit-content;
            height: 24px;
            padding: 4px 8px;
            align-items: center;
            gap: 4px;
            border-radius: 4px;
            border: 1px solid $gray002;
            background-color: $gray001;
            color: $gray003;
            margin-bottom: 10px;
        }

        .title {
            font-weight: 600;
            font-size: 17.5px;
            line-height: 20px;
            letter-spacing: 0.146667px;
            color: #4f4f4f;
        }

        .categoryContainer {
            margin-top: 20px;
        }

        .durationPriorityContainer {
            display: flex;
            margin-top: 20px;
            width: 100%;
            justify-content: space-between;

            .duration {
                color: #bdbdbd;
                font-size: 12px;
                display: flex;
                align-items: center;
            }

            .priority {
                color: #bdbdbd;
                font-size: 12px;
                display: flex;
                align-items: center;
            }

            .icon {
                height: 19px;
                width: 19px;
                margin-right: 6px;
            }
        }

        .timeLeft {
            margin-top: 20px;
            font-weight: normal;
            font-size: 12px;
            line-height: 16px;
            color: #828282;
        }

        .progress {
            margin-top: 12px;

            .percentage {
                font-size: 12px;
                line-height: 16px;
                color: #828282;
            }

            :global(.ant-progress-bg) {
                height: 10px !important;
            }
        }

        .gotToLessonContainer {
            margin-top: 20px;

            .gotToLesson {
                width: 100%;
                height: 40px;
            }
        }
    }
}