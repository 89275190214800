@import '../../../../../../variables.scss';

.container {
    display: flex;
    padding-bottom: 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
    border-bottom: 1px solid var(--grey, #DEE2E9);
    max-width: 100%;

    &:last-child {
        border: 0;
        padding-bottom: 0;
    }

    &.mobile {
        .image {
            width: 100%;
            height: 152px;
            flex-shrink: 0;
            border-radius: 7.278px;
        }

        .titleContainer {
            width: 100%;

            .info {
                min-width: calc(100vw - 92px);

                .titleAndDescription {

                    .description {
                        line-clamp: 2;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: normal;
                        height: 32px;
                        display: -webkit-box;
                        -webkit-line-clamp: 2;
                        -webkit-box-orient: vertical;
                    }
                }
            }
        }

        .progressbar {
            display: flex;
            height: 10px;
            align-items: center;
            gap: 32px;
            align-self: stretch;
        }

        .data {
            gap: 8px;
        }
    }

    .titleContainer {
        display: flex;
        align-items: flex-start;
        gap: 16px;
        align-self: stretch;

        .image {
            width: 189px;
            height: 93px;
            flex-shrink: 0;
            border-radius: 7.278px;
        }

        .info {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 10px;
            flex: 1 0 0;
            max-width: calc(100% - 189px - 32px - 32px);
            width: 100%;

            .categories {
                height: 24px;

                .category {
                    display: flex;
                    height: 24px;
                    padding: 4px 8px;
                    align-items: center;
                    gap: 4px;
                    border-radius: 4px;
                    border: 1px solid var(--grey, #DEE2E9);
                    background: var(--gray-light, #F7F8FA);
                    color: var(--grey, #81858B);
                    text-align: center;
                    font-family: Inter;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 16px;
                }
            }

            .titleAndDescription {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                gap: 3px;
                align-self: stretch;

                .title {
                    height: 20px;
                    overflow: hidden;
                    color: var(--Black, #2A2A2B);
                    font-feature-settings: 'clig' off, 'liga' off;
                    text-overflow: ellipsis;
                    white-space: nowrap;

                    font-family: Inter;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 20px;
                    letter-spacing: -0.3px;
                    max-width: 100%;
                }

                .description {
                    height: 16px;
                    overflow: hidden;
                    color: var(--grey, #81858B);
                    font-feature-settings: 'clig' off, 'liga' off;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    font-family: Inter;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 16px;
                    max-width: 100%;
                }
            }

            .progressbar {
                display: flex;
                height: 10px;
                align-items: center;
                gap: 32px;
                align-self: stretch;
            }
        }

        .goto {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-end;
            align-self: stretch;

            .button {
                cursor: pointer;
                display: flex;
                height: 32px;
                width: 32px;
                flex-direction: column;
                justify-content: center;
                align-items: center;
            }
        }
    }

    .data {
        display: flex;
        align-items: center;
        gap: 22px;

        .priority {
            display: flex;
            align-items: center;
            gap: 3px;
            color: var(--grey, #81858B);
            font-feature-settings: 'clig' off, 'liga' off;
            font-family: Inter;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 16px;
        }

        .duration {
            display: flex;
            align-items: flex-start;
            gap: 3px;
            color: var(--grey, #81858B);
            font-feature-settings: 'clig' off, 'liga' off;
            font-family: Inter;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 16px;
        }

        .date {
            display: flex;
            align-items: flex-start;
            gap: 3px;
            color: var(--grey, #81858B);
            font-feature-settings: 'clig' off, 'liga' off;
            font-family: Inter;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 16px;
        }
    }
}