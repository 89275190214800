@import '../../../variables.scss';

.menu {
  &:global(.ant-menu-inline) {
    border-right: 0;
  }

  .welcome {
    &:global(.ant-menu-item-selected) {
      background-color: white !important;
      border-left: 2px solid var(--tertiary-text-button-color);
      color: var(--tertiary-text-button-color);

      &::after {
        border-right: 0;
      }

      :global(.ant-menu-submenu-title) {
        border-radius: 0 16px 16px 0;
        background-color: $gray001;
        color: $blue002;
        font-weight: 600;
      }
    }
  }

  .subMenu {

    &:global(.ant-menu-submenu-selected) {
      :global(.ant-menu-submenu-title) {
        border-radius: 0 16px 16px 0;
        background-color: $gray001;
        color: $blue002;
        font-weight: 600;
      }
    }

    :global(.ant-menu-submenu-title) {
      height: 32px;
      padding-left: 16px !important;

      &>:global(.ant-menu-submenu-arrow) {
        top: 20px;
      }

      span {
        margin-left: 4px;
      }
    }

    :global(.ant-menu-sub) {
      background-color: white;

      .item {
        height: 31px;
        color: $blue002;
        font-weight: 600;
        line-height: 31px;
        padding: 0 0 0 32px !important;

        &:global(.ant-menu-item-selected) {
          background-color: white;
          border-left: 2px solid var(--tertiary-text-button-color);
          color: var(--tertiary-text-button-color);

          &::after {
            border-right: 0;
          }
        }
      }
    }

  }
}