.dotMainWrapper {
  display: flex;
  flex-direction: row;
  white-space: nowrap;
  justify-content: flex-start;
  align-items: center;
  .dot {
    width: 10px;
    height: 10px;
    border-radius: 5px;
  }
  .label {
    padding-left: 8px;
  }
}