@import './variables.scss';

$inputsBorderRadius: 6px;

:root {
    --main-color: #3B54EC;
    --main-bg-button-color: #3B54EC;
    --main-text-button-color: #FFFFFF;
    --secondary-background-button-color: #FFFFFF;
    --secondary-text-button-color: #3B54EC;
    --tertiary-text-button-color: #3B54EC;
}

#teurona {

    .ant-progress-bg,
    .ant-progress-success-bg {
        background-color: var(--main-color);
    }

    .ant-radio-checked .ant-radio-inner {
        border-color: var(--main-color);

        &::after {
            background-color: var(--main-color);
        }
    }

    .ant-upload.ant-upload-drag:not(.ant-upload-disabled):hover {
        border-color: var(--main-color);
    }

    .ant-progress-status-success .ant-progress-bg {
        background-color: #52c41a;
    }

    .ant-modal-content {
        border-radius: 8px;

        .ant-modal-header {
            border-radius: 8px 8px 0 0;
        }
    }

    section.ant-layout {
        background-color: #fff;
    }

    button.ant-btn {
        border-radius: 6px;

        &[disabled] {
            background-color: white;
            border-color: #BDBDBD;
            color: #BDBDBD;
        }
    }

    .ant-switch-checked {
        background-color: var(--main-color);
    }

    .ant-radio-group {
        &.ant-radio-group-solid {
            border: 1px solid $gray002;
            border-radius: 8px;

            .ant-radio-button-wrapper:first-child {
                border-radius: 8px 0 0 8px;
            }

            .ant-radio-button-wrapper:last-child {
                border-radius: 0 8px 8px 0;
            }

            .ant-radio-button-wrapper {
                height: 32px;
                border: 0;

                &:hover{
                    color: var(--main-text-button-color);                    
                }

                &.ant-radio-button-wrapper-checked {
                    border-radius: 8px;
                    background-color: var(--main-bg-button-color);
                    color: var(--main-text-button-color);
                    &+.ant-radio-button-wrapper:not(:first-child)::before {
                        background-color: transparent;
                    }
                }

                &+.ant-radio-button-wrapper.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):before {
                    background-color: transparent;
                }

                span {
                    font-size: 14px;
                    font-weight: 600;
                    line-height: 17px;
                    letter-spacing: 0px;
                    text-align: center;
                }
            }


        }
    }

    button.ant-btn.ant-btn-default {
        padding: 2px 26px;
        background: #f7f8fa;
        border: 0;
        border-radius: 4px;
        color: var(--tertiary-text-button-color);
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: -0.3px;

        &[disabled] {
            opacity: 0.3;
        }

        &.ant-btn-lg {
            padding: 16px 20px 16px 20px;
            border-radius: 8px;
            height: 48px;
            background-color: white;
            //styleName: button;
            font-size: 16px;
            font-weight: 600;
            line-height: 16px;
            letter-spacing: -0.30000001192092896px;
            text-align: center;
            color: var(--tertiary-text-button-color);
        }
    }

    button.ant-btn.ant-btn-primary {
        background: var(--main-bg-button-color);
        border-color: var(--main-bg-button-color);
        color: var(--main-text-button-color);

        &[disabled] {
            background-color: #BDBDBD;
            color: var(--main-text-button-color);
            border-color: #BDBDBD;
        }

        &.ant-btn-lg {
            padding: 16px 20px 16px 20px;
            border-radius: 8px;
            height: 48px;
            line-height: 16px;
        }
    }

    .ant-dropdown-button {
        border-radius: 8px;
        background-color: var(--main-bg-button-color);
        width: fit-content;

        button.ant-btn.ant-btn-primary {

            &.ant-btn-lg {
                &:first-child {
                    border-radius: 8px 0 0 8px;
                }

                &:last-child {
                    border-radius: 0 8px 8px 0;
                    background-color: $gray006Alfa37;
                    border-color: $gray006Alfa37;
                    padding: 0;

                    &::after {
                        width: 0;
                    }
                }
            }
        }
    }

    button.ant-btn-link {
        color: var(--tertiary-text-button-color);
        //styleName: button;
        font-weight: 600;

        &.withBorder {
            background-color: var(--secondary-background-button-color);
            color: var(--secondary-text-button-color);
            border: 2px solid var(--secondary-text-button-color);

            &:hover {
                border: 2px solid var(--secondary-text-button-color);
            }
        }

        &.ant-btn-lg {
            padding: 16px 20px 16px 20px;
            border-radius: 8px;
            height: 48px;
            line-height: 16px;

            &.clientSize {
                padding: 10px 20px 10px 20px;
                line-height: 8px;
                height: 40px;
            }
        }

        &:hover {
            border: 2px solid $gray001;
        }

        &.withoutHover {
            &:hover {
                border: 1px solid transparent;
            }
        }
    }

    div.ant-table-column-sorters {
        width: 100%;
        display: flex;
        justify-content: space-between;
    }

    thead.ant-table-thead {
        >tr>th {
            color: #686f84;
            border: 1px solid #f0f0f0;
            background: #f7f8fa;
        }
    }

    th.ant-table-column-sort {
        background: #f7f8fa !important;
    }

    td.ant-table-column-sort {
        background: none !important;
    }

    ul.ant-table-pagination.ant-pagination {
        padding-right: 15px;
    }

    li.ant-pagination-item {
        border: none;
    }

    li.ant-pagination-item-active {
        border: 2px var(--main-color) solid;
        border-radius: 8px;

        &:hover,
        &:focus {
            border-color: var(--main-color);

            a {
                color: var(--main-color);
            }
        }

        a {
            color: var(--main-color);
            font-family: 'Inter';

            &:hover,
            &:focus {
                color: var(--main-color);
            }
        }
    }

    .ant-pagination-item-container {
        .ant-pagination-item-ellipsis {
            color: $gray005;
            font-size: 9px;
            margin-top: 6px;
        }
    }

    button.ant-pagination-item-link {
        border: none;

    }

    span.anticon {
        vertical-align: text-top;
    }

    .ant-btn.studentButton {
        height: 48px;
        width: 100%;
        border-color: #4376F9;
        color: #4376F9;
        font-size: 16px;
    }

    div.ant-drawer-content {
        border-bottom-left-radius: 0px;
        border-top-left-radius: 0px;
    }

    div.ant-drawer-body {
        overflow: hidden;
        padding: 0;
    }

    div.ant-drawer-title {
        color: #4F4F4F;
        font-size: 22px;
        font-weight: 600;
    }

    .ant-input-affix-wrapper {
        border-radius: 4px;
        border: 1px solid #BDBDBD;
    }

    .ant-layout {
        position: relative;
        min-height: 100vh;
    }

    .ant-layout-content.students {

        @media (max-width: 768px) {
            padding-bottom: 185px;
            max-width: 100vw;
        }

        @media (max-width: 450px) {
            padding-bottom: 90px;
        }
    }

    .ant-layout-footer {
        bottom: 0;
        position: absolute;
        width: 100%;
    }

    .ant-checkbox-checked .ant-checkbox-inner {
        background-color: var(--main-color);
        border-color: var(--main-color);
    }

    .ant-tabs-tab {

        &.ant-tabs-tab-active {
            .ant-tabs-tab-btn {
                color: var(--main-color);
            }
        }

        .ant-tabs-tab-btn {
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
        }
    }

    .ant-form-item {
        margin-bottom: 16px;

        .ant-form-item-explain-error {
            font-size: 10px;
        }
    }

    .ant-form-item-control-input {
        .ant-form-item-control-input-content {

            input,
            textarea {
                border-radius: $inputsBorderRadius;
            }

            .ant-input-number.ant-input-number-in-form-item {
                border-radius: $inputsBorderRadius;
            }

            .ant-input-number-wrapper.ant-input-number-group {
                .ant-input-number.ant-input-number-in-form-item {
                    border-radius: $inputsBorderRadius 0 0 $inputsBorderRadius;

                    input:disabled {
                        color: #f5f5f5;
                    }
                }

                .ant-input-number-group-addon {
                    border-radius: 0 $inputsBorderRadius $inputsBorderRadius 0;
                    color: #81858B;
                    background-color: #DEE2E9;
                }
            }

            .ant-picker {
                border-radius: $inputsBorderRadius;
            }

            .ant-select {
                .ant-select-selector {
                    border-radius: $inputsBorderRadius;
                }
            }

            .flag-dropdown {
                border-radius: $inputsBorderRadius 0 0 $inputsBorderRadius;
            }
        }
    }

}