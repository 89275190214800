@import '../../variables.scss';

:global(#teurona) {
    .section {
        border: 1px solid $gray002;
        padding: 24px;
        border-radius: 16px;
        margin-bottom: 40px;
        background-color: white;

        .sectionTitle {
            //styleName: Subtitle;
            font-size: 18px;
            font-weight: 600;
            line-height: 20px;
            letter-spacing: 0.1466670036315918px;
            text-align: left;
            color: $gray003;

        }

        .separator {
            border-bottom: 1px solid $gray002;
            width: 100%;
            margin-top: 10px;
            margin-bottom: 10px;
        }

        .itemSwitch {
            :global(.ant-row) {
                margin-top: 30px;
                display: flex;
                justify-content: space-between;
                margin-bottom: 20px;
                width: 100%;
                flex-direction: row;
                align-items: center;

                :global(.ant-form-item-label) {
                    width: fit-content;
                    padding: 0;

                    label {
                        color: var(--Black, #2A2A2B);
                        font-feature-settings: 'clig' off, 'liga' off;

                        font-size: 16px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 20px;
                        letter-spacing: -0.3px;
                    }

                }

                :global(.ant-form-item-control) {
                    max-width: 30px;
                }
            }
        }

        .hidableContainer {
            margin-top: 30px;
            display: flex;
            justify-content: space-between;
            margin-bottom: 20px;

            .label {
                font-size: 16px;
                font-weight: 500;
                line-height: 20px;
                letter-spacing: -0.30000001192092896px;
                text-align: left;
            }
        }

        .header {
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            .pillNumber {
                display: flex;
                justify-content: center;
                align-items: center;
                height: 32px;
                width: fit-content;
                padding: 4px 8px 4px 8px;
                border-radius: 5px;
                background-color: $gray002;

                .number {
                    font-size: 18px;
                    font-weight: 600;
                    line-height: 20px;
                    letter-spacing: 0.1466670036315918px;
                    text-align: left;
                    margin-left: 6px;
                }
            }

            .actionContainer {
                display: flex;

                .action {
                    width: 32px;
                    height: 32px;
                    background-color: $gray001;
                    border-radius: 4px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    cursor: pointer;
                    border: 0;
                    padding: 0;
                    margin-left: 8px;
                }
            }

        }
    }

    .addNewButton {
        height: 40px;

        &:global(.ant-btn.ant-btn-default) {
            border: 2px solid var(--secondary-text-button-color);
            background-color: var(--secondary-background-button-color);
            font-weight: 600;
        }

        .square {
            width: 16px;
            height: 16px;
            border-radius: 4px;
            margin-left: 8px;
        }
    }

    .formFooter {
        border-top: 1px solid $gray002;
        padding-top: 14px;
        display: flex;
        justify-content: flex-end;
    }
}

.form {
    &:global(.ant-form) {
        padding-bottom: $extraBottomPadding;
    }

    :global(.ant-form-item) {
        margin-top: 10px;
        margin-bottom: 10px;

        &:first-child {
            margin-top: 0;
        }

        &:last-child {
            margin-bottom: 0;
        }
    }

    .sectionTitle {
        font-size: 20px;
        font-family: Inter;
        font-style: normal;
        font-weight: 600;
        font-size: 17.5px;
        line-height: 20px;
        letter-spacing: 0.146667px;
        color: #4f4f4f;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .sectionDescription{
        font-size: 14px;
        margin-top: 20px;
        color: black;
        margin-bottom: 36px;
    }
}

.evaluationCard {
    &.extendedStyle {
        margin-bottom: 16px;
        border-radius: 4px;
    }

    &.cardBody {
        padding: 30px;
    }

    .collapsedContainer {
        display: flex;
        justify-content: space-between;

        .btnContainer {
            display: flex;
            justify-content: flex-end;
            width: 100%;

            .removeButton {
                height: 32px;
                padding: 0 8px !important;

                .square {
                    width: 16px;
                    height: 16px;
                    border-radius: 4px;
                }
            }

            .space {
                margin-left: 8px;
            }
        }
    }

    .title {
        font-family: "Inter";
        line-height: 20px;
        font-weight: 600;
        text-align: center;
    }

    .pill {
        align-items: flex-start;
        background: #f7f8fa;
        border-radius: 4px;
        display: inline-flex;
        flex-direction: row;
        margin-left: 8px;
        min-width: 160px;

        .pillContent {
            align-items: center;
            display: flex;
            padding: 6px 8px;
        }

        .ansToPass {
            margin-left: 12px;
        }
    }

    .totalTop {
        margin-top: 20px;
        margin-left: 0px;
    }
}