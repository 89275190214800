@import '../../variables.scss';

.container {
  padding: 32px 0 20px 0;

  .title {
    font-size: 21px;
    font-weight: 600;
    line-height: 25px;
    letter-spacing: -0.30000001192092896px;

  }

  .subtitle {
    padding: 1rem 0;
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    letter-spacing: 0px;
    color: $gray003;
  }
}