.mainWrapper {
    font-size: 4px;
    background-color: #4376F9;
    margin: 0 auto;
    max-width: 842px;
    position: relative;
    width: 100%;
    @media (min-width: 375px) {
      font-size: 6px;
    }
    @media (min-width: 414px) {
      font-size: 7px;
    }
    @media (min-width: 520px) {
      font-size: 9px;
    }
    @media (min-width: 630px) {
      font-size: 10px;
    }
    @media (min-width: 768px) {
      font-size: 12px;
    }
    @media (min-width: 842px) {
      font-size: 14px;
    }
    .wrapper {
        padding-bottom: 70.66%;
    }
    .teuronaCrest {
        position: absolute;
        height: 29%;
        right: 7.3%;
        top: 0;
        width: 19.72%;
    }
    .crestWrapper {
        position: relative;
        height: 100%;
        overflow: hidden;
        text-align: center;
        p {
            color: #81858B;
            font-size: 0.86em;
            font-weight: 400;
            line-height: 1.5;
            margin: 22% 0 9.6%;
            position: relative;
        }
        img {
            position: relative;
            width: 83.74%;
        }
    }
    .crestBg  {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: #F7F8FA;
        transform: skewY(10deg);
        transform-origin: top right;
    }
    .container {
        background-color: #FFFFFF;
        bottom: 0;
        color: #81858B;
        display: flex;
        flex-direction: column;
        left:  0;
        margin-left: 8.79%;
        padding: 7.14% 6.77% 0 6.2%;
        position: absolute;
        right: 0;
        top: 0;
        header {
            display: flex;
            justify-content: flex-start;
            .logo {
                display: flex;
                justify-content: flex-start;
                margin-bottom: 1%;
                padding-top: 12.8%;
                position: relative;
                width: 21.3%;
                img {
                    position: absolute;
                    top: 0;
                    max-width: 100%;
                    max-height: 100%;
                }
            }
        }
        .content {
            text-align: left;
            .courseTitle {
                display: flex;
                justify-content: flex-start;
                padding-top: 4.6%;
                position: relative;
                h5 {
                    color: #81858B;
                    font-size: 1em;
                    font-weight: 400;
                    letter-spacing: -0.3px;
                    line-height: 1.5;
                    margin: 0;
                    position: absolute;
                    top: 0;
                }
            }
            .courseName {
                display: flex;
                justify-content: flex-start;
                padding-top: 12%;
                position: relative;
                h1 {
                    color: #4376F9;
                    font-size: 2em;
                    font-weight: 600;
                    margin: 0;
                    position: absolute;
                    top: 0;
                }
            }
            .courseHours {
                display: flex;
                justify-content: flex-start;
                padding-top: 3.6%;
                position: relative;
                h4 {
                    color: #686F84;
                    font-size: 1.14em;
                    font-weight: 500;
                    letter-spacing: -0.3px;
                    line-height: 1.3;
                    margin: 0;
                    position: absolute;
                    top: 0;
                }
            }
            .description {
                display: flex;
                justify-content: flex-start;
                padding-top: 16.5%;
                position: relative;
                p {
                    font-size: 1em;
                    letter-spacing: -0.3px;
                    line-height: 1.5;
                    width: 95%;
                    position: absolute;
                    top: 0;
                }
            }
            .traineeTitle {
                display: flex;
                justify-content: flex-start;
                padding-top: 3.4%;
                position: relative;
                span {
                    font-size: 1em;
                    font-weight: 400;
                    letter-spacing: -0.3px;
                    position: absolute;
                    top: 0;
                }
            }
            .traineeName {
                display: flex;
                justify-content: flex-start;
                padding-top: 5.1%;
                position: relative;
                h2 {
                    color: #686F84;
                    font-size: 1.57em;
                    font-weight: 600;
                    letter-spacing: 0.2px;
                    line-height: 1.4;
                    margin: 0;
                    position: absolute;
                    top: 0;
                }
            }
            .traineeId {
                display: flex;
                justify-content: flex-start;
                padding-top: 2.6%;
                position: relative;
                h3 {
                    color: #686F84;
                    font-size: 1.14em;
                    font-weight: 500;
                    letter-spacing: -0.3px;
                    line-height: 1.2;
                    margin: 0;
                    position: absolute;
                    top: 0;
                }
            }
        }
        footer {
            align-items: flex-end;
            display: flex;
            font-size: 0.86em;
            margin-bottom: 6.08%;
            margin-top: auto;
            .companyName {
                flex-basis: 35%;
            }
            .date {
                flex-basis: 20%;
            }
            .qrWrapper {
                align-items: flex-end;
                display: flex;
                flex-basis: 45%;
                flex-direction: column;
                canvas {
                    margin: 3.4%;
                    height: auto !important;
                    width: 21% !important;
                }
            }
        }
    }
}
