@import '../../variables.scss';

:global(#teurona) {
  .title {
    font-size: 16px;
    color: #4f4f4f;
    font-weight: 600;
    margin-bottom: 10px;
  }

  :global(.ant-input-affix-wrapper).search {
    &.wrapper {
      background: #f7f8fa;
      border-radius: 5px;
      border-color: #f7f8fa;
      padding: 8px 4px 8px 12px;
      border-width: 0;
    }
  }

  .companies {
    margin-top: 5px;
    overflow: auto;
    height: 300px;
    padding-right: 20px;

    .company {
      padding: 8px 0 8px 20px;
      margin-top: 5px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      height: 36px;
      font-size: 16px;
      line-height: 20px;
      color: #4f4f4f;
      cursor: pointer;
      display: block;

      &.selected {
        color: var(--tertiary-text-button-color);
        font-weight: 600;
        border-left: 2px solid var(--tertiary-text-button-color);
      }
    }
  }
}