.container {
  align-items: center;
  display: flex;
  height: 100%;
  text-align: center;

  .contentWrapper {
    width: 100%;
  }

  .imageWrapper {
    align-items: center;
    background-color: #f7f8fa;
    display: flex;
    height: 253px;
    justify-content: center;
    margin-bottom: 25px;
  }

  .subtitle {
    align-items: center;
    display: flex;
    justify-content: center;

    h3 {
      color: #686f84;
      font-size: 34px;
      margin: 0;
    }

    .tooltip {
      margin-left: 5px;
    }
  }

  p {
    color: #686f84;
    font-size: 18px;
    margin: 0;
  }
}
