@import '../../../variables.scss';

:global(#teurona) {
  .container {
    display: flex;
    justify-content: space-between;
    margin: 32px 24px 0 24px;
    height: 18px;
    align-items: center;

    .backContainer {
      .backButton {
        padding-left: 0;

        &:disabled {
          border-color: transparent;
        }
      }
    }

    .steps {
      display: flex;
      height: 18px;
      align-items: center;
      gap: 6px;

      .step {
        width: 31px;
        height: 4px;
        border-radius: 2px;
        background-color: $gray009;

        &.active {
          background-color: $blue001;
          width: 56px;
        }
      }
    }
  }
}