.selector {
  width: 150px;

  &.targeted {
    margin-bottom: 10px;
  }
}

:global(.ant-select:not(.ant-select-customize-input)).selector {
  > :global(.ant-select-selector) {
    border          : 0;
    background-color: #E8ECF3;
    color           : #686F84;
    font-size       : 12px;
    height          : 26px;
  }

  :global(.ant-select-clear) {
    background-color: #E8ECF3;
  }
}