.container{
    margin-top: 24px;
    box-sizing: border-box;
    border-radius: 8px;
    display: flex;
    align-items: center;
    .selected{ 
        color: #4376F9;
    }
    .title{
        cursor: pointer;
    }
}
.cannotGoThere{
    cursor: default;
    .title {
        cursor: default;
    }
}

.divider{
    border-bottom: 1px solid #E8ECF3;
    margin: 0 14px;
}