@import '../../../variables.scss';

.container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 51px;
    border: 1px solid #e8ecf3;

    .breadcrumbs {
        max-width: $maxScreenWidth;
        width: 100%;
        padding-left: 40px;

        :global(.ant-breadcrumb-link) {
            font-weight: 500;
            font-size: 16px;
            color: #BDBDBD;

            a {
                color: rgba(0, 0, 0, 0.45);
            }
        }
    }
}