@import '../../variables.scss';

.timeBarContainer {
    width: 100%;
    height: 4px;
    margin-top: -8px;

    .backgroundBar {
        position: absolute;
        background-color: #E8ECF3;
        height: 4px;
    }

    .foregroundBar {
        position: absolute;
        background-color: var(--main-color);
        height: 4px;
    }

    .timeStop {
        position: absolute;
        background-color: var(--main-color);
        height: 8px;
        width: 8px;
        margin-left: -4px;
        margin-top: -2px;
        border-radius: 4px;

        &.current {
            height: 16px;
            width: 16px;
            margin-left: -8px;
            margin-top: -6px;
            border-radius: 8px;
        }
    }
}