.container{
  display: flex;
  flex-flow: column;

  width: 538px;
  padding: 22px;
  gap: 37px;

  &.mobile {
    width: 100%;
    .calendar {
      .week{
        width: 100%;
      }
    }
  }

  .calendar {
    display: flex;
    flex-direction: column;
    height: fit-content;
    .week{
      padding: 0;
      width: 466px;
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
      align-items: stretch;
      margin-top: 10px;
    }
  }
}