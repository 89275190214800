.container.noMobile {
  display: flex;
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
  border-radius: 16px;
  border: 1px solid var(--grey, #DEE2E9);
  background: var(--white, #FFF);

  .header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    align-self: stretch;

    .title {
      color: var(--grey, #81858B);
      font-feature-settings: 'clig' off, 'liga' off;
      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 20px;
      /* 125% */
      letter-spacing: -0.3px;
    }

    .link {
      span {
        color: var(--tertiary-text-button-color);
        text-align: center;
        font-feature-settings: 'clig' off, 'liga' off;

        /* btn small */
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        cursor: pointer;
      }
    }

  }

  .records {
    display: flex;
    padding-top: 15px;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;

    .record {
      display: flex;
      padding: 0px 12px 16px 0px;
      align-items: center;
      gap: 8px;
      align-self: stretch;
      background: var(--white, #FFF);
      border-bottom: 1px solid var(--grey, #DEE2E9);

      &:last-child {
        border-bottom: 0;
      }

      .icon {
        width: 32px;
        height: 32px;

        img {
          width: 32px;
          height: 32px;
        }
      }

      .value {
        font-feature-settings: 'clig' off, 'liga' off;

        /* paragraph */
        -webkit-text-stroke-width: 54.57499694824219;
        -webkit-text-stroke-color: var(--Black, #2A2A2B);
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        /* 125% */
        letter-spacing: -0.3px;

      }
    }
  }
}

.container.mobile {
  display: flex;
  width: 100%;
  padding: 16px;
  flex-direction: column;
  gap: 16px;
  border-top: 1px solid var(--grey, #DEE2E9);
  position: relative;
  height: 126px;
  background: var(--gray-light, #F7F8FA);

  .header {
    display: flex;
    width: 343px;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 30px;

    .title {
      color: var(--grey, #81858B);
      font-feature-settings: 'clig' off, 'liga' off;
      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 20px;
      /* 125% */
      letter-spacing: -0.3px;
    }

    .link {
      color: var(--tertiary-text-button-color);
      text-align: center;
      font-feature-settings: 'clig' off, 'liga' off;
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
  }

  .carousel {
    display: flex;
    width: 100%;
    height: 60px;
    align-items: flex-start;
    gap: 16px;
    overflow-x: scroll;
    margin-right: -16px;
    margin-left: -16px;
    position: absolute;
    margin-top: 34px;
    padding-right: 16px;
    padding-left: 16px;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */

    &::-webkit-scrollbar {
      display: none;
    }

    .carouselItem {
      width: fit-content !important;

      .record {
        display: flex !important;
        padding: 16px;
        align-items: center;
        gap: 8px;
        align-self: stretch;
        border-radius: 37px;
        border: 1px solid var(--grey, #DEE2E9);
        background: var(--white, #FFF);

        .icon {
          width: 24px;
          height: 24px;

          img {
            width: 24px;
            height: 24px;
          }

        }

        .value {
          font-feature-settings: 'clig' off, 'liga' off;
          -webkit-text-stroke-width: 54.57499694824219;
          -webkit-text-stroke-color: var(--Black, #2A2A2B);
          font-family: Inter;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 20px;
          letter-spacing: -0.3px;
          width: fit-content;
          white-space: nowrap;
        }
      }
    }
  }
}