.mainWrapper {
    font-size: 4px;
    background-color: #F7F8FA;
    margin: 0 auto;
    max-width: 842px;
    position: relative;
    width: 100%;
    @media (min-width: 375px) {
      font-size: 5px;
    }
    @media (min-width: 414px) {
      font-size: 6px;
    }
    @media (min-width: 520px) {
      font-size: 7px;
    }
    @media (min-width: 630px) {
      font-size: 8px;
    }
    @media (min-width: 768px) {
      font-size: 10px;
    }
    @media (min-width: 842px) {
      font-size: 12px;
    }
    .wrapper {
        padding-bottom: 70.66%;
    }
    .frameHeader {
        background-color: #4376F9;
        position: absolute;
        padding-top: 10%;
        width: 100%;
    }
    .container {
        background-color: #FFFFFF;
        bottom: 7.2%;
        color: #81858B;
        display: flex;
        flex-direction: column;
        left:  5.3%;
        padding: 0 3.2%;
        position: absolute;
        right: 5.3%;
        top: 7.2%;
        header {
            align-items: flex-start;
            display: flex;
            font-size: 1em;
            justify-content: space-between;
            margin: 3.4% 0 0;
            .date {
                flex-basis: 33%;
                text-align: right;
            }
            .companyName {
                flex-basis: 33%;
            }
            .logo {
                display: flex;
                flex-basis: 34%;
                justify-content: center;
                margin-top: 0.8%;
                padding-top: 13%;
                position: relative;
                img {
                    position: absolute;
                    top: 0;
                    max-width: 80%;
                    max-height: 80%;
                }
            }
        }
        .content {
            text-align: center;
            .courseTitle {
                display: flex;
                justify-content: center;
                padding-top: 4.8%;
                position: relative;
                h5 {
                    color: #81858B;
                    font-size: 1.16em;
                    letter-spacing: -0.3px;
                    line-height: 1.5;
                    margin: 0;
                    position: absolute;
                    top: 0;
                }
            }
            .courseName {
                display: flex;
                justify-content: center;
                padding-top: 10%;
                position: relative;
                h1 {
                    color: #4376F9;
                    font-size: 2.34em;
                    font-weight: 600;
                    margin: 0;
                    position: absolute;
                    top: 0;
                }
            }
            .courseHours {
                display: flex;
                justify-content: center;
                padding-top: 3.7%;
                position: relative;
                h4 {
                    color: #686F84;
                    font-size: 1.33em;
                    font-weight: 500;
                    letter-spacing: -0.3px;
                    margin: 0;
                    position: absolute;
                    top: 0;
                }
            }
            .description {
                display: flex;
                justify-content: center;
                padding-top: 12.9%;
                position: relative;
                p {
                    font-size: 1.17em;
                    letter-spacing: -0.3px;
                    line-height: 1.3;
                    margin: 0 auto;
                    width: 95%;
                    position: absolute;
                    top: 0;
                }
            }
            .traineeTitle {
                display: flex;
                justify-content: center;
                padding-top: 3.5%;
                position: relative;
                span {
                    font-size: 1.17em;
                    font-weight: 400;
                    letter-spacing: -0.3px;
                    position: absolute;
                    top: 0;
                }
            }
            .traineeName {
                display: flex;
                justify-content: center;
                padding-top: 5%;
                position: relative;
                h2 {
                    color: #686F84;
                    font-size: 1.83em;
                    font-weight: 600;
                    letter-spacing: 0.2px;
                    line-height: 1.4;
                    margin: 0;
                    position: absolute;
                    top: 0;
                }
            }
            .traineeId {
                display: flex;
                justify-content: center;
                padding-top: 2.7%;
                position: relative;
                h3 {
                    color: #686F84;
                    font-size: 1.33em;
                    font-weight: 500;
                    letter-spacing: -0.3px;
                    line-height: 1.2;
                    margin: 0;
                    position: absolute;
                    top: 0;
                }
            }
        }
        footer {
            align-items: flex-end;
            display: flex;
            font-size: 1em;
            position: absolute;
            bottom: 3.2%;
            width: 93.6%;
            canvas {
                margin: 1.3% 1.3% 1.3% auto;
                height: auto !important;
                width: 10.32% !important;
            }
        }
    }
}
