@import '../../variables.scss';

.container {
  border-radius: 16px;
  border: 1px solid $gray002;
  display: flex;
  width: 100%;
  padding: 24px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
}