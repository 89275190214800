.mainWrapper {
  .title {
    font-size  : 17.5px;
    line-height: 20px;
    font-weight: 600;
    color      : #686F84;
    padding: 5px 0 10px;
  }

  .placeholder {
    display        : flex;
    min-height     : 259px;
    justify-content: center;
    align-items    : center;
  }
}