.container{
    margin-top: 16px;
    display: flex;
    flex-direction: column;
    .title{
        font-weight: 600;
        font-size: 21.88px;
        line-height: 32px;
        letter-spacing: 0.2px;
        color: #4F4F4F;
    }
    .content{
        font-size: 14px;
        line-height: 21px;
        letter-spacing: -0.3px;
        margin-top: 16px;
        color: #81858B;
    }
}