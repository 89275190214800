@import '../../variables.scss';

.modal {

  .title {
    font-size: 21px;
    font-weight: 600;
    line-height: 25px;
    letter-spacing: -0.30000001192092896px;

    .subTitle {
      font-size: 14px;
      font-weight: 400;
      line-height: 17px;
      letter-spacing: 0px;
      margin-top: 20px;
    }
  }

  :global(.ant-modal-body) {
    padding: 0;

    .innerContainer {
      padding: 6px;

      .divider {
        width: 100%;
        border-bottom: 1px solid #e8ecf3;
      }

      .questions {
        display: flex;
        flex-direction: column;
        padding: 10px 16px;

        .question {
          display: flex;
          align-items: center;
          justify-content: space-between;
          height: 48px;

          .title {
            max-width: 90%;
            font-size: 14px;
            font-weight: 400;
            line-height: 17px;
            letter-spacing: 0px;
            color: $blue002;

            &.cueSet {
              color: var(--tertiary-text-button-color);
            }


            .innerTitle {
              padding: 3px 8px;
              overflow: hidden;
              text-overflow: ellipsis;
              display: inline-block;
            }
          }

          .time {
            height: 40px;
            border: 1px solid var(--main-color);
            border-radius: 8px;
            padding: 10px 10px;
            display: flex;
            align-items: center;
            color: #686f84;
            text-align: right;
            background-color: #f7f8fa;

            .delete {
              margin-right: 0;
              cursor: pointer;
              padding: 7px 0px;
              width: 24px;
              height: 30px;
              color: $red001;
            }
          }

          .link {
            height: 40px;
            padding: 10px 20px;
            text-align: right;
            display: flex;
            align-items: center;
            color: white;
            border-radius: 8px;
            background-color: var(--tertiary-text-button-color);
            cursor: pointer;

            &.disabled {
              color: #686F84;
              cursor: not-allowed;
            }
          }

          .icon {
            margin-right: 13px;
            margin-left: 0;
          }
        }
      }
    }
  }
}