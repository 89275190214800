.mainWrapper {
  .licenseUsage {
    font-size: 1.2em;
    min-height: 56px;
  }
  .churnedDescription {
    .rate {
      display: flex;
      white-space: nowrap;
      font-size: 1em;
    }
    .description {
      font-size: 0.9em;
    }
  }
}