.navigator {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 19px;

  .header {
    display: flex;
    align-items: center;
    gap: 19px;

    .monthAndYear {
      color: var(--Gray-2, #4F4F4F);
      font-feature-settings: 'clig' off, 'liga' off;
      font-family: Inter;
      font-size: 21px;
      font-style: normal;
      font-weight: 600;
      line-height: 30px;
      letter-spacing: -0.3px;

      .month {
        text-transform: capitalize;
        display: inline-block;
        width: 54px;
        line-height: 30px;
      }
    }

    .controls {
      display: flex;
      align-items: center;
      justify-content: center;

      .button {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 30px;
        width: 30px;
        cursor: pointer;
        margin-left: 10px;

        .icon {
          height: 24px;
          width: 24px;
        }
      }
    }
  }

  .line {
    width: 100%;
    border-bottom: 1px solid #DEE2E9;
  }
}