.search {
    &.wrapper {
        background-color: white !important;
        border-color: #F7F8FA;
        padding: 8px 12px;
        margin-bottom: 17px;

        :global(.ant-input-wrapper) {
            background-color: #F7F8FA;
            border-width: 0;
            border-radius: 8px;
            padding: 4px;

            input,
            button {
                background-color: #F7F8FA;
                border-width: 0;
                padding-top: 4px !important;

                &:hover,
                &:focus {
                    border: 0;
                    box-shadow: none;
                }
            }
        }
    }
}