$panelHeight: 80px;

.height90 {
    height: calc(#{$panelHeight} * 1.2);
}

.image {
    width: calc(#{$panelHeight} * 1.8);
    height: calc(#{$panelHeight} * 1.8);
    border-radius: calc(#{$panelHeight} * 1.8 / 2);
    margin-top: -15px;
}

.content {
    max-width: 1140px;
    margin-left: auto;
    margin-right: auto;
    @media print {
        padding: 0 40px;
    }
}
