@import '../../variables.scss';

$panelHeight: 32vw;

.panel {
  height: $panelHeight;
  max-height: 350px;
}

.mastheadContainer {
  display: flex;
  height: calc(#{$panelHeight} - 2 * 42px);
  max-height: 350px;
}

.masthead {
  .left {
    .titleContainer {
      padding: 75px 60px 0 0;

      .title {
        font-family: Inter;
        font-style: normal;
        font-weight: 600;
        font-size: 34px;
        line-height: 37px;
        color: #4f4f4f;
      }

      .info {
        font-family: Inter;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 21px;
        color: rgba(0, 0, 0, 0.65);
        padding: 20px 0;

        .mail {
          color: $blue001;
        }
      }
    }
  }

  .right {
    .imageContainer {
      .image {
        width: 100%;
        border-radius: 8px;
      }
    }
  }
}