$gray001: #F7F8FA;
$gray002: #DEE2E9;
$gray002AlfaD0: #DEE2E9D0;
$gray003: #81858B;
$gray004: #4F4F4F;
$gray005: #2A2A2B;
$gray006: #373737;
$gray006Alfa37: #37373737;
$gray007: #BDBDBD;
$gray008: #E8ECF3;
$gray009: #E0E0E0;
$gray010: #828282;

$blue001: #3B54EC;
$blue001AlfaD0: #3B54ECD0;
$blue002: #3a4cb9;
$blue002: #213462;
$blue003: #686F84;

$green001: #27AE60;

$red001: #D65F5C;
$red002: #EB5757;


$whiteAlfa21: #FFFFFF21;

$headerHeight: 66px;
$maxScreenWidth: 1440px;
$maxUserProfileScreenWidth: 1000px;
$sideMenuWidth: 207px;
$formFooterHeight: 90px;
$extraBottomPadding: $formFooterHeight + 30px;