.container {
    margin-top: 20px;
    .pictureAndName {
        display: flex;
        align-items: center;
        .picture {
            width: 40xp;
            height: 40px;
            object-fit: cover;
            border-radius: 20px;
            margin-right: 20px;
        }
        .name {
            font-size: 14px;
            line-height: 21px;
            text-align: right;
            letter-spacing: -0.3px;
            color: #4f4f4f;
        }
    }
    .description {
        margin-top: 16px;
        font-weight: normal;
        font-size: 12px;
        line-height: 16px;
        color: #81858b;
    }
}
