.inputContainer {
  border: 1px solid #bdbdbd;
  border-radius: 4px;
  display: flex;
  align-items: center;
  width: 100%;
  .passwordInput {
    width: calc(100% - 25px);
    display: inline-block;
    border: none;
    :global(.ReactPasswordStrength-input) {
      height: 22px;
      border-radius: 4px;
      width: 100%;
      font-size: 14px;
      color: rgba(0, 0, 0, 0.85);
      font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI',
        'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
        'Helvetica Neue', sans-serif;
    }
    :global(.ReactPasswordStrength-strength-desc) {
      display: none;
    }
    :global(.ReactPasswordStrength-strength-bar) {
      display: none;
    }
  }
  .eyeButton {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 34px;
    height: 28px;
    cursor: pointer;
    color: #81858b;
    position: relative;
  }
}

$progressWidh: 47px;
$strenchWidth: ($progressWidh + 3) * 3;
.strength {
  display: flex;
  flex-direction: row;
  width: $strenchWidth;
  .progress {
    max-width: $progressWidh;
    margin-left: 3px;
  }
  &.hide {
    display: none;
  }
}
