@import '../../../variables.scss';

.addButtonContainer {
    margin-top: 228px;
    position: absolute;
    width: 100%;
    text-align: left;
}

.uploadFile {
    &.withAddButton {
        margin-bottom: 60px;
    }

    &:global(.ant-upload.ant-upload-drag) {
        height: 183px;
        border: 2px dashed #e8ecf3;
        border-radius: 8.3871px;

        &.selectedFile {
            border: 0;
        }

        :global(.ant-upload-btn) {
            height: 180px;
        }

        .ant-upload {
            padding: 0;
        }


        :global(.ant-upload-drag-container) {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            height: 180px;

            .uploadIcon {
                width: 58px;
                height: 48px;
                display: inline-block;
                border-bottom: 1.00538px solid #010237;
                overflow: hidden;
                position: relative;

                .background {
                    background: #e8ecf3;
                    opacity: 0.6;
                    width: 60px;
                    height: 60px;
                    border-radius: 30px;
                    position: absolute;
                    margin-left: 0px;
                }

                .bluePart {
                    display: block;
                    width: 20px;
                    height: 20px;
                    border-radius: 10px;
                    background-color: $blue001;
                    position: absolute;
                    z-index: 2;
                    margin-left: 36px;
                    margin-top: 12px;

                    .icon {
                        margin-top: -4px;
                    }
                }

                .folder {
                    width: 48px;
                    height: 38px;
                    position: absolute;
                    z-index: 1;
                    margin-left: -24px;
                    margin-top: 11px;
                }
            }

            .text {
                padding: 20px;
                width: 100%;

                .remove {
                    width: 32px;
                    height: 32px;
                    background-color: $gray001;
                    position: relative;
                    border-radius: 4px;
                    cursor: pointer;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    top: -52px;
                    right: calc(32px - 100%);
                    user-select: none;
                }

                .description {
                    font-size: 13px;
                    font-weight: 500;
                    line-height: 16px;
                    letter-spacing: -0.23796026408672333px;
                    text-align: center;
                    color: $blue003;

                    .fakeLink {
                        color: var(--tertiary-text-button-color);
                    }
                }

                .specifications {
                    //styleName: caption;
                    font-size: 10px;
                    font-weight: 500;
                    line-height: 12px;
                    letter-spacing: -0.30000001192092896px;
                    text-align: center;
                    color: $gray003;
                }
            }
        }
    }

    &.short {
        &:global(.ant-upload.ant-upload-drag) {
            height: 174px;

            :global(.ant-upload-btn) {
                height: 170px;
                padding: 0;
            }

            :global(.ant-upload-drag-container) {
                height: 170px;
            }
        }
    }
}

.imageUploaded {
    position: absolute;
    max-height: 180px;
    overflow: hidden;
    border-radius: 8.3871px;
    max-width: 500px;
    width: 100%;

    img {
        object-fit: cover;
        height: 100%;
        width: 100%;
    }
}

.divider {
    height: 1px;
    width: 100%;
    border-bottom: 1px solid $gray002;
    margin-top: 24px;
    margin-bottom: 24px;
}

.info {
    color: $gray003;
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 12px;
    margin-bottom: 100px;

    .navigator {
        display: flex;
        gap: 8px;


        .button {
            width: 32px;
            height: 32px;
            border-radius: 8px;
            background-color: $gray001;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
        }
    }
}

.carouselContainer {
    overflow: hidden;
    position: absolute;
    margin-top: -80px;
    max-width: calc(100% - 100px);
    width: 100%;

    .carousel {
        width: 100%;
        overflow-x: scroll;
        overflow-y: hidden;
        height: 100px;
        display: flex;
        gap: 8px;
        user-select: none;

        &::-webkit-scrollbar {
            width: 0;
        }

        .imageContainer {
            position: relative;
            cursor: pointer;

            .image {
                width: 163px;
                height: 79px;
                object-fit: cover;
                border-radius: 4px;
            }
        }
    }
}