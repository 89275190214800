@import '../../../../variables.scss';

.container {
    display: flex;
    padding: 24px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 32px;
    align-self: stretch;
    border-radius: 16px;
    border: 1px solid var(--grey, #DEE2E9);
    background: var(--white, #FFF);

    &.mobile {
        display: block;
        height: 400px;
        justify-content: flex-start;
        border-radius: 0;
        border: 1px solid var(--grey, #DEE2E9);
        background: var(--white, #FFF);
        margin-top: 32px;
        padding: 24px 16px;
    }

    .titleContainer {
        display: flex;
        justify-content: space-between;
        align-items: center;
        align-self: stretch;
        width: 100%;

        .title {
            color: var(--Black, #2A2A2B);
            font-feature-settings: 'clig' off, 'liga' off;
            font-family: Inter;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 20px;
            letter-spacing: -0.3px;
            width: 100%;
        }
    }

    .innerContainer {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 32px;
        max-width: 100%;
        min-width: 100%;
    }
}

.horizontalContainer {
    display: flex;
    width: 100%;
    height: 309px;
    gap: 32px;
    overflow-x: scroll;
    margin: 16px 16px 300px -16px;
    position: absolute;
    padding-right: 16px;
    padding-left: 16px;
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */

    &::-webkit-scrollbar {
        display: none;
      }
}