.card {
    &.overload {
        border-radius: .25rem;
    }
    .title {
        font-family: Inter;
        font-size: 20px;
        line-height: 16px;
        color: #4f4f4f;
        padding: 15px 0;
    }
}

.cardTile {
    // TODO: repeated code
    color: #4f4f4f;
}
:global(.ant-alert-error).alertError {
    border: 1px solid #eb5757;
    box-sizing: border-box;
    border-radius: 8px;
    :global(.ant-alert-message) {
        color: red;
        .message {
            display: flex;
            align-items: center;
            font-size: 14px;
            .icon {
                margin-right: 11px;
            }
        }
    }
}
:global(.ant-btn.ant-btn-primary) {
    &.downloadButton {
        display: flex;
        align-items: center;
    }
}
