.notAvailable {
  background: #f2f2f2;
  border-radius: 4px;
  height: 48px;
  width: 100%;
  color: #81858b;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  cursor: not-allowed;
}
.tooltip {
    :global(.ant-tooltip-arrow) {
        display: none;
    }
  :global(.ant-tooltip-inner) {
    background-color: #81858b;
    box-shadow: 0px 2px 12px rgba(23, 29, 41, 0.12);
    border-radius: 8px;
    width: 340px;
    text-align: center;
    margin-left: -45px;
    font-size: 12px;
  }
}
