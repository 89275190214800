.container {
  display: flex;
  flex-direction: column;
  padding: 10px 5px;
  margin-bottom: 5px;
  .label {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 16px;
    color: #81858b;
  }
  .text {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 16px;
    color: #010237;
    padding-top: 8px;
  }
}
