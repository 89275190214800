.linksContainer {
  display: flex;
  flex-direction: column;
  gap: 22px;
  padding-top: 22px;

  .externalDescription {
    color: var(--black, #262329);
    font-feature-settings: 'liga' off, 'clig' off;

    /* Subtitle */
    font-family: Inter;
    font-size: 17.5px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    /* 114.286% */
    letter-spacing: 0.147px;
  }

  .link {
    display: flex;
    width: 632px;
    padding: 24px;
    align-items: flex-start;
    gap: 24px;
    border-radius: 16px;
    border: 1px solid #DEE2E9;

    .delete {
      position: absolute;
      height: 22px;
      margin-bottom: -22px;
      left: calc(100% - 16px);
      cursor: pointer;
    }

    .fields {
      width: 50%;
    }
    
    .divider {
      border: 1px solid #DEE2E9;
      height: 332px;
    }

    .previewContainer {
      width: calc(50% - 48px);
      max-width: calc(50% - 48px);
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 36px;
      flex: 1 0 0;

      .title {
        color: var(--grey, #81858B);
        font-feature-settings: 'liga' off, 'clig' off;

        /* Subtitle */
        font-family: Inter;
        font-size: 17.5px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
        /* 114.286% */
        letter-spacing: 0.147px;
      }

      .preview {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        gap: 36px;
        flex: 1 0 0;
        width: 100%;
        padding-bottom: 16px;
        border-bottom: 1px solid #DEE2E9;

        .icon {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 36px;
          flex: 1 0 0;

          .iconContainer {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 40px;
            height: 40px;
            border-radius: 20px;
            background-color: #F2F2F2;

          }
        }

        .content {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 8px;
          align-self: stretch;
          width: 100%;
          max-width: calc(100% - 76px);

          .title {
            color: var(--Black, #2A2A2B);
            font-feature-settings: 'liga' off, 'clig' off;
            font-family: Inter;
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            text-overflow: ellipsis;
            max-width: 100%;
            overflow: hidden;
            white-space: nowrap;
          }

          .description {
            color: var(--Gray-2, #4F4F4F);
            font-feature-settings: 'liga' off, 'clig' off;
            font-family: Inter;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            text-overflow: ellipsis;
            max-width: 100%;
            overflow: hidden;
            line-clamp: 3;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            display: -webkit-box;
          }

          .linkPreview {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            align-self: stretch;
            gap: 9px;

            .text {
              color: var(--tertiary-text-button-color);
              text-align: center;
              font-feature-settings: 'liga' off, 'clig' off;
              font-family: Inter;
              font-size: 16px;
              font-style: normal;
              font-weight: 600;
              line-height: 20px;
              /* 125% */
              letter-spacing: -0.3px;
              text-overflow: ellipsis;
              max-width: calc(100% - 33px);
              overflow: hidden;
            }

            .iconContainer {
              width: 24px;
              height: 24px;

              .icon {
                transform: rotate(-132deg);
              }
            }
          }
        }
      }
    }
  }
}

.section {
  display: flex;
  gap: 40px;
  flex-direction: column;

  .innerTitle {
    color: var(--Black, #2A2A2B);
    font-feature-settings: 'clig' off, 'liga' off;

    /* body */
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .twoColumns {
    display: flex;
    flex-direction: row;
    gap: 24px;

    .colorBox {
      display: flex;
      width: 79px;
      height: 79px;
      padding: 29px 0px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      border-radius: 4px;
      border: 1px solid var(--grey, #81858B);
    }

    .colorWithDescription {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 8px;
      flex: 1 0 0;

      .description {
        color: var(--Black, #2A2A2B);
        font-feature-settings: 'clig' off, 'liga' off;

        /* caption */
        font-family: Inter;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }

    .buttonContainer {
      .button {
        display: flex;
        padding: 16px 20px;
        justify-content: center;
        align-items: center;
        border-radius: 8px;
        gap: 4px;

        text-align: center;
        font-feature-settings: 'clig' off, 'liga' off;

        /* button */
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
        /* 125% */
        letter-spacing: -0.3px;
      }
    }

    .colors {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 16px;

      .color {
        display: flex;
        align-items: center;
        gap: 16px;

        .label {
          color: var(--Black, #2A2A2B);
          font-feature-settings: 'clig' off, 'liga' off;

          /* body */
          font-family: Inter;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          width: 68px;
        }
      }
    }
  }

  .innerContent {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
  }

  .colorPickerWrapper {
    border-radius: 8px;
    border: 1px solid var(--grey, #81858B);
    width: 120px;
    height: 40px;

    .trigger {
      display: flex;
      padding: 8px;
      align-items: center;
      gap: 10px;
      align-self: stretch;
      cursor: pointer;

      .miniColorBox {
        width: 24px;
        height: 24px;
        border-radius: 4px;
        border: 1px solid var(--grey, #81858B);
      }
    }
  }
}