@import '../../variables.scss';

:global(#teurona) {
  .noNotificationMethods {
    padding: 40px 20px;
  }

  .container {
    .selectorContainer {
      display: flex;
      gap: 10px;

      .selector {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 33%;
        height: 57px;
        padding: 20px 10px 20px 10px;
        border-radius: 8px;
        border: 2px solid $gray002;
        font-weight: 600;
        color: $blue001;
        cursor: pointer;

        &.selected {
          border-color: $blue001;
        }

        &.disabled {
          cursor: not-allowed;
        }
      }
    }

    .viewSelector {
      width: 100%;
      border: 1px solid #e8ecf3;
      border-radius: 4px;

      :global(.ant-radio-button-wrapper) {
        width: 33.33%;
        text-align: center;
        border-width: 0;
        border-image-width: 0;

        &::before {
          background-color: transparent;
        }

        &:global(.ant-radio-button-wrapper-checked) {
          background-color: $blue001;
          border-radius: 4px;

          &:hover {
            background-color: $blue001AlfaD0;
          }
        }
      }
    }

    .actions {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin-top: 24px;

      .button {
        &:global(.ant-btn) {
          margin-left: 16px;
          border-radius: 4px;
          height: 40px;
          font-size: 16px;
          line-height: 20px;
        }
      }
    }
  }

  .scheduleModal {
    .title {
      font-weight: 600;
      font-size: 17.5px;
      line-height: 20px;
      color: #4F4F4F;
      margin-bottom: 40px;
    }

    .dateTimeContainer {
      display: flex;

      .date {
        margin-right: 20px;
      }
    }
  }
}