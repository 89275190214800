@import '../../../variables.scss';

.container {
  padding: 0 10px;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-items: center;

  .image {
    margin-top: 70px;
  }

  .header {
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 21.88px;
    line-height: 32px;
    text-align: center;
    letter-spacing: 0.2px;
    color: #010237;
    text-align: center;
    margin-top: 35px;

    &.second {
      margin-top: 10px;
    }
  }

  .courseName {
    margin-top: 8px;
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 17.5px;
    line-height: 20px;
    text-align: center;
    letter-spacing: 0.146667px;
    color: $blue001;
  }

  .extraInfo {
    margin-top: 25px;
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    text-align: center;
    letter-spacing: -0.3px;
    color: #81858B;
    width: 527px;
    max-width: 100%;
  }

  .buttonContainer {
    margin-top: 53px;
    width: 376px;
    max-width: 100%;

    &.second {
      margin-top: 24px;
    }

    .button {
      height: 40px;
      width: 100%;
      font-size: 16px;
      line-height: 20px;
    }
  }
}