@import '../../variables.scss';

.card:global(.ant-card) {
  margin-top: 70px;
  width: 380px;

  .title {
    font-size: 21px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.3px;
    margin-top: 21px;
  }

  .description {
    margin: 1.3rem 0;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    color: $gray007;
  }

  .backButton {
    .button {
      padding-left: 0;
      height: 38px;
      color: $blue003;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
  }
}