@import '../../variables.scss';

.row {
  display: flex;
  align-items: center;
  justify-content: center;
}

.carousel-indicators {
  margin-left: 5%;
  margin-right: 5%;
}

.fullHeight {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.contraintWidth {
  @media (min-width: 768px) {
    width: 50% !important;
  }
}

.border {
  overflow: hidden;

  @media (min-width: 576px) {
    border-radius: 10px;
    border: 1px solid lightgray;
  }
}

.roundedBorder {
  @extend .border;
  border: 1px solid lightgray;

  &.noMobile {
    margin-top: 80px;
  }
}

.title {
  color: #fff;
  display: flex;

  .logo {
    margin-right: 6px;
  }
}

.row1 {
  width: 100%;
  margin-bottom: 20px;

  .col1 {
    max-width: 776px;
    width: 100%;

    .row2 {
      display: flex;
      flex-direction: column;
      max-width: 776px;

      @media(max-width: 820px) {
        max-width: 375px;
      }

      &.noMobile {
        flex-direction: row;
      }

      .carouselContainer {
        width: 400px;
      }

      .formContainer {
        width: 100%;
      }
    }
  }
}

.carouselItem {

  .container {
    background-size: cover;
    background-position: center center;
    height: 650px;
    max-height: 516px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding-left: 20px;

    @media (max-width: 992px) {
      height: 50vw;
      max-height: 200px;
    }
  }

  .caption {
    color: #fff;
    padding-bottom: 30px;
  }
}

:global(#teurona) {
  .formInnerContainer {
    padding-left: 24px;
    padding-right: 24px;

    &.overload {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 516px;
    }

    &.noHeightLimit {
      height: fit-content;
    }

    :global(.ant-form-item-label) {
      padding-bottom: 2px;

      label {
        height: 24px;
      }
    }

    .topPart {
      padding: 0 20px 0 0;
    }

    .header {
      padding-top: 3em;
      padding-bottom: 3em;
      color: black;

      .title {
        font-weight: bold;
        font-size: 2em;
        color: black;
      }

      .content {
        padding-top: 1.5em;
      }
    }

    .error {
      position: relative;
      margin-top: -16px;
      // margin-bottom: -20px;
    }

    .buttons {
      display: flex;

      .loginBtn {
        width: 126px;
      }

      .forgotPassword {
        width: 198px;
        padding: 4px 16px 4px 0;
        color: $blue001;
      }

    }

    .signupBtn {
      width: 136px;
      margin-top: 10px;
      margin-left: calc(100% - 136px);
    }

    .footer {
      padding: 1px;
    }
  }

}