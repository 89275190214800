@import '../../variables.scss';

.container {
  width: 100%;
  border: 1px solid #e8ecf3;
  border-radius: 16px;
  margin-top: 16px;
  padding: 24px;

  .title {
    font-size: 18px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0.1466670036315918px;
    text-align: left;
    margin-bottom: 20px;
    color: $gray003;
  }

  .label {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    padding-left: 4px;
    color: #4f4f4f;
  }

  .counter {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    margin-top: 6px;
    color: #81858b;
    padding-left: 4px;
  }

  .textarea {
    margin-top: 8px;
    border: 1px solid #c8c9cc;
    box-sizing: border-box;
    border-radius: 4px;
  }

  .input {
    margin-top: 8px;
    margin-bottom: 10px;
    border: 1px solid #c8c9cc;
    box-sizing: border-box;
    border-radius: 4px;
  }
}