$header-height: 66px;
$header-footer: 76px;
$rest: $header-height + $header-footer;
.container {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 10px;
  &.noGutter {
    padding: 0;
  }
  .header {
    height: $header-height;
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 21.88px;
    line-height: 32px;
    /* identical to box height, or 146% */
    letter-spacing: 0.2px;
    /* Gray 2 */
    color: #4f4f4f;
    border-bottom: 1px solid #e8ecf3;
    padding-top: 10px;
    padding-left: 10px;
    display: flex;
    justify-content: space-between;
  }
  .content {
    flex-grow: 1;
    padding: 20px 10px;
    overflow-x: hidden;
    overflow-y: auto;
    max-height: calc(100vh - #{$rest});
    &.noFooter {
      max-height: 100%;
    }
    &.noGutter {
      padding: 0;
    }
  }
  .footer {
    border-top: 1px solid #e8ecf3;
    height: $header-footer;
    padding: 24px 15px 24px 10px;
    text-align: right;
  }
}
