.mainWrapper {
    font-size: 4px;
    background-color: #FFFFFF;
    margin: 0 auto;
    max-width: 842px;
    position: relative;
    width: 100%;
    @media (min-width: 375px) {
      font-size: 6px;
    }
    @media (min-width: 414px) {
      font-size: 7px;
    }
    @media (min-width: 520px) {
      font-size: 9px;
    }
    @media (min-width: 630px) {
      font-size: 10px;
    }
    @media (min-width: 768px) {
      font-size: 12px;
    }
    @media (min-width: 842px) {
      font-size: 14px;
    }
    .wrapper {
        padding-bottom: 70.66%;
    }
    .container {
        bottom: 6%;
        color: #81858B;
        display: flex;
        flex-direction: column;
        left:  4.75%;
        position: absolute;
        right: 4.75%;
        top: 6%;
        header {
            display: flex;
            justify-content: center;
            .logo {
                display: flex;
                justify-content: center;
                margin-bottom: 1%;
                padding-top: 10%;
                position: relative;
                width: 18.3%;
                img {
                    position: absolute;
                    top: 0;
                    max-width: 100%;
                    max-height: 100%;
                }
            }
        }
        .content {
            text-align: center;
            .courseTitle {
                display: flex;
                justify-content: center;
                padding-top: 4.3%;
                position: relative;
                h5 {
                    color: #81858B;
                    font-size: 1em;
                    letter-spacing: -0.3px;
                    line-height: 1.5;
                    margin: 0;
                    position: absolute;
                    top: 0;
                }
            }
            .courseName {
                display: flex;
                justify-content: center;
                padding-top: 9%;
                position: relative;
                h1 {
                    color: #4376F9;
                    font-size: 2em;
                    font-weight: 600;
                    margin: 0;
                    position: absolute;
                    top: 0;
                }
            }
            .courseHours {
                display: flex;
                justify-content: center;
                padding-top: 3.3%;
                position: relative;
                h4 {
                    color: #686F84;
                    font-size: 1.14em;
                    font-weight: 500;
                    letter-spacing: -0.3px;
                    margin: 0;
                    position: absolute;
                    top: 0;
                }
            }
            .description {
                display: flex;
                justify-content: center;
                padding-top: 12.9%;
                position: relative;
                p {
                    font-size: 1em;
                    letter-spacing: -0.3px;
                    line-height: 1.5;
                    margin: 0 auto;
                    width: 90%;
                    position: absolute;
                    top: 0;
                }
            }
            .traineeTitle {
                display: flex;
                justify-content: center;
                padding-top: 3.1%;
                position: relative;
                span {
                    font-size: 1em;
                    font-weight: 400;
                    letter-spacing: -0.3px;
                    position: absolute;
                    top: 0;
                }
            }
            .traineeName {
                display: flex;
                justify-content: center;
                padding-top: 4.6%;
                position: relative;
                h2 {
                    color: #686F84;
                    font-size: 1.57em;
                    font-weight: 600;
                    letter-spacing: 0.2px;
                    line-height: 1.4;
                    margin: 0;
                    position: absolute;
                    top: 0;
                }
            }
            .traineeId {
                display: flex;
                justify-content: center;
                padding-top: 6.2%;
                position: relative;
                h3 {
                    color: #686F84;
                    font-size: 1.14em;
                    font-weight: 500;
                    letter-spacing: -0.3px;
                    line-height: 1.2;
                    margin: 0;
                    position: absolute;
                    top: 0;
                }
            }
            .qrWrapper {
                display: flex;
                justify-content: center;
                margin: 10px;
                padding-top: 8.8%;
                position: relative;
                canvas {
                    height: auto !important;
                    position: absolute;
                    top: 0;
                    width: 8.8% !important;
                }
            }
            p {
                font-size: 0.86em;
                font-weight: 400;
                line-height: 1.4;
            }
        }
        footer {
            align-items: center;
            bottom: 0;
            display: flex;
            font-size: 0.86em;
            justify-content: space-between;
            margin: 0 auto;
            position: absolute;
            width: 100%;
        }
    }
}
