.description {
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
}

.wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
