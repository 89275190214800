.container {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  gap: 40px;

  .divider {
    display: flex;
    align-items: center;
    gap: 40px;
    align-self: stretch;
    &.mobile {
      padding-left: 16px;
    }

    .title {
      display: flex;
      align-items: center;
      gap: 4px;
      color: #000;
      font-feature-settings: 'clig' off, 'liga' off;
      white-space: nowrap;

      /* button */
      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 20px;
      /* 125% */
      letter-spacing: -0.3px;
    }

    .line {
      width: 100%;
      height: 0;
      border-bottom: 2px solid #DEE2E9;
    }
  }
}