@import '../../../../variables.scss';

:global(.ant-card).container {
  border-radius: 8px;
  margin-top: 20px;

  .icon {
    width: 60px;
    height: 60px;
  }

  .title {
    margin-top: 8px;
    font-size: 21px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.3px;
    color: $gray004;
  }

  .info {
    margin-top: 6px;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    /* 125% */
    letter-spacing: -0.3px;
    color: $gray005;
  }

  .results {
    margin-top: 32px;

    .result {
      font-weight: normal;
      font-size: 14px;
      line-height: 14px;
      letter-spacing: -0.3px;
      color: #81858b;
      margin-bottom: 4px;
    }
  }

  .buttonContainer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 19px;
    margin-top: 24px;
    gap: 24px;
  }

  .questions {
    margin-top: 32px;

    .title {
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: -0.3px;
      color: $gray003;
    }

    .question {
      margin-top: 8px;
      border-radius: 8px;
      border: 1px solid $gray002;
      padding: 11px 16px;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: -0.3px;

      &.failed {
        color: $red002;
      }

      .icon {
        margin-right: 8px;
        width: 16px;
        height: 16px;
      }
    }
  }
}