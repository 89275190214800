@import '../../../variables.scss';

:global(#teurona) {

    .formFooter {
        width: 100%;
        position: fixed;
        bottom: 0;
        max-width: $maxScreenWidth;
        background-color: white;
        left: 50%;
        transform: translateX(-50%);
        display: flex;
        flex-direction: column;
        padding: 0 20px 20px 20px;
        height: 90px;
        z-index: 10;

        .divider {
            border-top: 1px solid $gray002;
            width: 100%;
            margin-bottom: 14px;
        }

        .actions {
            display: flex;
            justify-content: flex-end;
            width: 100%;
            padding: 0 50px;
            gap: 10px
        }
    }

    .list {

        :global(.ant-dropdown-menu-title-content) {
            //styleName: button;
            font-size: 16px;
            font-weight: 600;
            line-height: 20px;
            letter-spacing: -0.30000001192092896px;
            padding: 5px 8px;
        }
    }
}