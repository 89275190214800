@import '../../../variables.scss';

$evaluationHeight: 480px;

.container {
  margin-top: 48px;

  .masthead {
    .titleContainer {
      margin-bottom: 24px;
      border-radius: 8px;

      :global(.ant-card-body) {
        padding: 14px 16px;
      }

      .topicTitle {
        font-size: 12px;
        line-height: 16px;
        color: $gray010;
      }

      .title {
        margin-top: 4px;
        font-size: 17.5px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
        /* 114.286% */
        letter-spacing: 0.147px;
        color: $gray004;
      }
    }
  }

  .quizContainer {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh; //$evaluationHeight;
    // margin-bottom: -$evaluationHeight;
  }

  .pdfsContainer {
    .pdf {
      background-color: #f7f8fa;
      border-radius: 10px;
      padding: 20x 19px;
      margin-top: 16px;

      .intruction1 {
        font-weight: 600;
        font-size: 17.5px;
        line-height: 20px;
        letter-spacing: 0.146667px;
        color: #4f4f4f;
      }

      .intruction2 {
        margin-top: 15px;
        font-size: 14px;
        line-height: 21px;
        letter-spacing: -0.3px;
        color: #4f4f4f;
      }

      .downloadButton {
        border: 0;
        margin-top: 30px;
        height: 44px;
        background-color: #e8ecf3;
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: center;

        &.mobile {
          width: 100%;
        }

        cursor: pointer;

        .text {
          max-width: 300px;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .attachIcon {
          margin-right: 8px;
          width: 18px;
          height: 18px;
        }

        .downloadIcon {
          margin-left: 14px;
          width: 18px;
          height: 18px;
        }
      }
    }
  }

  .carouselContainer {
    border-radius: 8px;

    .navigator {
      position: relative;
      width: 100%;
      height: $evaluationHeight;
      z-index: 1;
      display: flex;
      justify-content: space-between;
      margin-bottom: -$evaluationHeight;

      .button {
        display: inline-block;
        height: 480px;
        width: 128px;
        background-color: transparent;

        &:hover {
          background-color: #00000030;
        }

        display: flex;
        align-items: center;
        cursor: pointer;

        .icon {
          width: 20px;
          height: 20px;
        }
      }

      .left {
        padding-left: 30px;
      }

      .right {
        padding-right: 30px;
        justify-content: flex-end;
      }
    }

    .carousel {
      width: 100%;
      border-radius: 8px;

      .image {
        border-radius: 8px;
        height: 480px;
        width: 100%;
        object-fit: cover;
      }
    }
  }

  .info {
    margin-top: 16px;
    border-radius: 8px;

    .title {
      margin-top: 16px;
      font-weight: 600;
      font-size: 17.5px;
      line-height: 20px;
      letter-spacing: 0.146667px;
      color: #4f4f4f;
    }

    .categoryContainer {
      margin-top: 20px;
    }

    .description {
      color: $gray005;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      /* 125% */
      letter-spacing: -0.3px;
      margin-top: 8px;
    }

    :global(.ant-tabs-ink-bar) {
      background-color: $blue001;
    }

    .cta {
      position: relative;
      top: -26px;
      margin-bottom: -55px;
      z-index: 1;
    }
  }

  .nextLessonContainer {
    margin-top: 16px;
    display: flex;
    justify-content: flex-end;

    &.stickyCtaContainer {
      position: sticky;
      bottom: 0;
      width: calc(100% + 44px);
      z-index: 1;

      .stickyCta {
        width: 100%;
        height: 48px;
      }
    }

    .big {
      height: 48px;
    }
  }

  .viewLessonsMobile {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
}