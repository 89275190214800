.title {
  color: #686f84;
  display: flex;
  font-size: 17.5px;
  font-weight: 600;
}

.tableHeader {
  align-items: center;
  display: flex;
  margin: 0 0 25px 0;

  .downloadButton {
    background-color: #e8ecf3;
    border-color: #e8ecf3;
    height: 40px;
    margin-left: 34px;
    text-align: left;
    width: 139px;
    span {
      color: #686f84;
      font-size: 16px;
      font-weight: 500;
      margin-right: 15px;
    }
  }
}