.container {
  display: flex;
  padding: 24px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
  align-self: stretch;
  border-radius: 16px;
  border: 1px solid var(--grey, #DEE2E9);
  background: var(--white, #FFF);

  &.mobile {
    border-radius: 0;
  }
}