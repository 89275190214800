.event{
  width: 5px;
  height: 5px;
  border-radius: 5px;
  margin: 1px;
  &.high{
    background-color: #EB5757;
  }
  &.medium{
      background-color: #F2C94C;
  }
  &.low{
      background-color: #20a471;
  }
}

.day{
  height: 42px;
  width: 42px;
  max-width: 100%;
  .innerContainer{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    height: 42px;
    width: 42px;
    padding-bottom: 4px;
    font-size: 16px;
    color: #4F4F4F;
    &.outOfTheMonth{
      opacity: 0.3;
    }
    &.selected{
      background-color: var(--main-color);
      border-radius: 12px;
      color: white;
      .indicators{
        background: rgba(232, 236, 243, 0.4);
      }
    }
    .indicators{
      background-color: #E8ECF3;
      display: flex;
      width: fit-content;
      border-radius: 5px;
      padding: 2px;
    }
  }
}