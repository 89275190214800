.carousel {
  .carousel-indicators {
    justify-content: flex-start;
    margin-left: 5%;
    margin-right: 5%;
  }
}

.carousel-indicators {
  .active {
    width: 60px;
  }
}

.carousel-inner {
  .carousel-caption {
    text-align: left;
    right: 5%;
    left: 5%;
  }
  img {
    min-height: 654px;
    object-fit: cover;
    @media (max-width: 992px) {
      min-height: unset;
      max-height: 100vw;
    }
  }
}

.form-layout {
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  button {
    margin-top: auto;
  }
}

.error {
  height: 16pt;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16pt;
  color: #eb5757;
}
