.mainContainer {
  align-items: center;
  background-color: black;
  display: flex;
  height: 100vh;
  justify-content: center;
  margin-bottom: -161px;
  .certificateHeader {
    display: flex;
    justify-content: flex-end;
    padding: 20px;
    position: absolute;
    top: 0;
    width: 100%;
  }
  .certificateContainer {
    width: 100%;
  }
}
