.container {
  display: flex;
  padding: 24px 16px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  border-radius: 16px;
  border: 1px solid var(--grey, #DEE2E9);
  background: var(--white, #FFF);

  .title {
    color: #000;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Inter;
    font-size: 17.5px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0.147px;
  }

  .rightActions {
    display: flex;
    align-items: flex-start;
    gap: 11px;

    .schedule {}

    .filter {}
  }
}