@import '../../../variables.scss';

.masthead {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #e8ecf3;
  padding-top: 54px;
  padding-bottom: 48px;

  .header {
    display: flex;
    max-width: 1264px;
    justify-content: space-between;
    align-items: flex-end;
    width: 100%;
    &.mobile {
      padding: 0 10px;
      flex-direction: column;
      gap: 20px;
      .search { 
        padding: 8px 10px;
      }
    }
    &.tablet {
      padding: 0 10px;
    }

    .titleAndDescription {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 11px;

      .title {
        align-self: stretch;
        color: var(--Black, #2A2A2B);
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: Inter;
        font-size: 21px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: -0.3px;
      }

      .subTitle {
        color: var(--grey, #81858B);
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }

    .search {
      display: flex;
      width: 362px;
      height: 32px;
      padding: 8px 12px;
      align-items: center;
      gap: 16px;
      flex-shrink: 0;
    }
  }
}

@mixin wrapper-style {
  padding-left: 24px;
  padding-right: 24px;
}

.categoriesSection {
  width: 100%;
  max-width: 1264px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 30px;

  .categoryCard {
    :hover {
      cursor: pointer;
    }

    border-radius: 8px;

    .top {
      @include wrapper-style();
      width: 100%;
      height: 86px;
      background-color: #f7f8fa;

      .content {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: flex-end;
        padding-bottom: 24px;

        >* {
          font-weight: 600;
          font-size: 17px;
          line-height: 20px;
          color: #686f84;
        }
      }
    }

    .bottom {
      @include wrapper-style();
      padding-top: 12px;
      padding-bottom: 12px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      >* {
        display: inline-flex;
      }

      .articlesMeta {
        display: flex;
        align-items: center;
        color: var(--tertiary-text-button-color);
        font-size: 12px;
        line-height: 16px;
      }

      .date {
        color: #bdbdbd;
        font-size: 12px;
        line-height: 16px;
      }
    }

    .divider {
      @include wrapper-style();
    }
  }
}