.wrapper {
  composes: wrapper from "./common.module.scss";

  .evaluationItem {
    display: flex;
    flex-direction: column;
    background-color: #f7f8fa;
    padding: 10px 40px;
    .title {
      align-self: flex-start;
    }

    .bottomInfoWrapper {
      display: flex;
      justify-content: space-between;
      margin-top: 16px;
      color: #81858B;
    }
  }
}
