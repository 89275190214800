.Container:global(.ant-card) {
  margin-top: 70px;
  font-size: 0.8em;
  color: #81858B;
  width: 330px !important;

  .form {
    color: rgb(129, 133, 139);
  }
}

.requirementsSize {
  font-size: 12px;
}

.noSubTitle {
  display: none;
}

.Dark {
  color: #4F4F4F;

  &.size {
    font-size: 11px;
  }
}

.requirement {
  height: 16px;
  display: flex;
  align-items: center;
  margin-bottom: 2px;

  .icon {
    width: 9px;
    height: 12px;
    object-fit: contain;
  }

  .label {
    display: inline-block;
    margin-left: 6px;
    ;
  }
}

.welcomeMessage {
  font-size: 14px;
  color: #81858B;
}

.innerContainer {
  padding-left: 24px;
  padding-right: 24px;

  .header {
    padding-bottom: 0;
  }

  .resetBtn {
    margin-top: 20px;
    width: 180px;
    margin-left: calc(100% - 180px);
  }

  .errorMsg {
    position: relative;
    top: -22px;
    margin-bottom: -20px;
    width: 100%;
    background-color: white;
  }
}

.errorMsg {
  position: relative;
  top: -22px;
  margin-bottom: -20px;
  width: 100%;
  background-color: white;
}

.form {
  .setPasswordBtn {
    margin-top: 24px;
    width: 197px;
    margin-left: calc(100% - 197px);
  }
}