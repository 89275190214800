@import '../../../variables.scss';

.uploadImage {
    :global(.ant-upload.ant-upload-select-picture-card) {
        border: 0;
        background-color: transparent;
        width: 100%;


        :global(.ant-upload) {
            align-items: flex-start;
            justify-content: flex-start;
        }

        .uploadButton {
            display: flex;
            align-items: center;

            .image {
                width: 98px;
                height: 98px;
                min-width: 98px;
                min-height: 98px;
                max-width: 98px;
                max-height: 98px;
                background: $gray001;
                border-radius: 6px;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 40px;
                background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='6' ry='6' stroke='black' stroke-width='1' stroke-dasharray='6' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");

                &.loadedImage {
                    border-radius: 31px;
                    border: 0;
                    overflow: hidden;

                    img {
                        object-fit: cover;
                        width: 100%;
                        height: 100%;
                    }
                }
            }

            .secondColumn {
                padding-left: 16px;
                display: flex;
                flex-direction: column;
                font-size: 12px;
                align-items: flex-start;
                gap: 8px;

                .fileTypes {
                    color: var(--Black, #2A2A2B);
                    font-feature-settings: 'clig' off, 'liga' off;
                    font-family: Inter;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    text-align: left;
                }

                .buttonIcon {
                    margin-right: 6px;
                }
            }
        }
    }
}