.container {
  display: flex;
  padding: 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
  border-radius: 16px;
  border: 1px solid var(--grey, #DEE2E9);
  background: var(--white, #FFF);
  max-width: 100%;
  &.mobile {
    border-radius: 0;
  }

  .header {
    display: flex;
    align-items: flex-start;
    gap: 16px;
    max-width: 100%;
    width: 100%;

    .icon {
      width: 47px;
      height: 47px;
      flex-shrink: 0;
    }

    .titleContainer {
      max-width: calc(100% - 64px);
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      gap: 8px;
      flex: 1 0 0;
      align-self: stretch;

      .title {
        color: var(--Black, #2A2A2B);
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
        letter-spacing: -0.3px;
        white-space: nowrap;
        overflow: hidden;
        max-width: 100%;
        text-overflow: ellipsis;
      }

      .since {
        display: flex;
        gap: 5px;
        color: var(--grey, #81858B);
        font-feature-settings: 'clig' off, 'liga' off;

        /* Caption  */
        font-family: Inter;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
        /* 133.333% */
      }
    }
  }

  .description {
    color: var(--Gray-2, #4F4F4F);
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .image {
    height: 220px;
    align-self: stretch;
    width: 100%;
    border-radius: 15px;
  }

  .actions {
    display: flex;
    align-items: center;
    gap: 10px;
    align-self: stretch;
  }
}