@import '../../../variables.scss';

.container {
  margin-top: 0.5rem;

  .label {
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0px;
    text-align: left;
    color: $gray003;
  }

  .text {
    margin-top: 2px;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: -0.30000001192092896px;
    text-align: left;
    color: $gray005;
  }
}