.confirmModal {
    :global(.ant-modal-content) {
        border-radius: 8px;
    }
    :global(.ant-modal-body) {
        padding: 18px 24px 27px;
    }
    :global(.ant-modal-footer) {
        padding: 16px;
    }
    h2 {
        color: #4F4F4F;
        font-size: 18px;
        font-weight: 600;
        letter-spacing: 0.15px;
        line-height: 20px;
        margin-top: 27px; 
    }
    p {
        color: #4F4F4F;
        font-size: 12px;
        line-height: 16px;
        margin: 18px 0 0;
    }
}