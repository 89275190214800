@import "../../../variables.scss";

.container {
  display: flex;
  padding: 24px 24px 24px 48px;
  align-items: center;
  gap: 95px;
  align-self: stretch;
  border-radius: 16px;
  background: var(--main-color);
  width: 100%;

  /* Shadow */
  box-shadow: 0px 4px 16px 0px rgba(51, 51, 51, 0.08), 0px 4px 4px 0px rgba(51, 51, 51, 0.04);

  .firstContainer {
    display: flex;
    align-items: center;
    gap: 24px;

    .goldenBadge {
      width: 102px;
      height: 102px;
    }

    .points {
      font-feature-settings: 'clig' off, 'liga' off;
      -webkit-text-stroke-width: 54.57499694824219;
      color: #FFF;
      font-family: Inter;
      font-size: 48px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      display: flex;
      align-items: center;
      gap: 10px;
    }

    .description {
      color: #FFF;
      font-feature-settings: 'clig' off, 'liga' off;
      font-family: Inter;
      font-size: 17.5px;
      font-style: normal;
      font-weight: 600;
      line-height: 20px;
      /* 114.286% */
      letter-spacing: 0.147px;
    }
  }

  .secondContainer {
    display: flex;
    padding: 24px 18px;
    flex-direction: column;
    align-items: center;
    gap: 44px;
    flex: 1 0 0;
    border-radius: 13px;
    background: rgba(42, 42, 43, 0.20);

    .progressBarContainer {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 8px;
      align-self: stretch;

      .progressInfo {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        align-self: stretch;
        width: 100%;

        font-feature-settings: 'clig' off, 'liga' off;
        -webkit-text-stroke-width: 21.16596794128418;
        color: #FFF;
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }

      .progressBar {
        display: flex;
        height: 12px;
        align-items: flex-start;
        gap: 20px;
        flex: 1 0 0;
        border-radius: 25px;
        background: rgba(42, 42, 43, 0.44);

        .progress {
          border-radius: 25px;
          height: 12px;
          background: url('../../../assets/infographic/progress.png'), lightgray 0% 0% / 18.60465109348297px 18.60465109348297px repeat;
        }
      }
    }

    .badges {
      display: flex;
      width: 386px;
      justify-content: space-between;
      align-items: center;

      .badge {
        display: flex;
        width: 69px;
        height: 70px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        flex-shrink: 0;
      }
    }

    .detailsContainer {
      .details {
        display: flex;
        height: 41px;
        padding: 16px 20px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-radius: 8px;
        background: var(--main-color);
        color: var(--ECUADOR-Gray-blue, #F6F8FA);
        text-align: center;
        font-feature-settings: 'clig' off, 'liga' off;

        /* button */
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
        /* 125% */
        letter-spacing: -0.3px;
        cursor: pointer;
      }
    }
  }
}

.container.mobile {
  flex-direction: column;
  padding: 24px 16px;
  gap: 0;

  .firstContainer {
    width: 100%;
  }

  .secondContainer {
    width: 100%;
    margin-top: 26px;
    gap: 0;

    .badges {
      margin-top: 17px;
      width: 100%;
      flex-wrap: wrap;
      gap: 17px 0;

      .badge:last-child {
        flex-basis: 100%;
      }
    }

    .detailsContainer {
      margin-top: 17px;
      width: 100%;

      .details {
        width: 100%;
      }
    }
  }
}

.detailsModal {
  width: 721px !important;
  max-width: 100vw;
  display: flex;
  flex-direction: column;
  gap: 24px;
  
  :global(.ant-modal-header) {
    padding: 32px 16px;
    border-bottom: 0;

    :global(.ant-modal-title) {
      color: var(--Black, #2A2A2B);
      font-feature-settings: 'clig' off, 'liga' off;

      /* digit */
      font-family: Inter;
      font-size: 21px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      letter-spacing: -0.3px;
    }
  }

  .typeRow {
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding: 16px;

    &:nth-child(2n) {
      background-color: #F7F8FA;
      border-radius: 16px;
    }

    .titleContainer {
      display: flex;
      flex-direction: column;
      gap: 4px;

      .title {
        font-size: 21px;
        font-weight: 600;
        color: #2A2A2A;
      }

      .description {
        font-size: 14px;
        font-weight: 400;
        color: $gray003;
      }
    }

    .badges {
      display: flex;
      justify-content: space-between;

      .badge {
        width: 93px;

        .imageContainer {
          text-align: center;
          margin-bottom: 4px;

          .image {
            width: 76px;

          }
        }

        .text {
          text-align: center;
          width: 100%;
          font-size: 12px;
          color: $gray003;
        }
      }
    }
  }
}