.badge {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;

  &.small {
    width: 36px;
    height: 36px;
    img {
      width: 36px;
      height: 36px;
    }
  }

  .number {
    color: var(--grey, #81858B);
    text-align: center;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Inter;
    font-size: 21.179px;
    font-style: normal;
    font-weight: 700;
    line-height: 27.798px;
    /* 131.25% */
    letter-spacing: -0.397px;
  }
}