.hide {
  display: none;
  @media print {
    display: block;
  }
}

.wrapper {
  display: block;
  max-width: 1140px;
  margin-left: auto;
  margin-right: auto;
  @media print {
    padding: 0 40px;
  }
  &.hide {
    display: none;
    @media print {
      display: block;
    }
  }
}
