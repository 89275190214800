.resultsHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  padding-top: 10px;
  padding-bottom: 10px;
  &.column {
    flex-direction: column-reverse;
    .right {
      width: 100%;
    }
    .left {
      align-self: flex-start;
    }
  }
  .left,
  .right {
    height: 100%;
  }

  .left {
    display: inline-flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    padding-left: 12px;
    .title {
      font-weight: 600;
      font-size: 21px;
      line-height: 32px;
      color: #4f4f4f;
    }
    .counter {
      font-size: 12px;
      line-height: 16px;
      color: #81858b;
    }
  }

  .right {
    display: inline-flex;
    align-items: center;
  }
}

.resultsContent {
  margin-top: 20px;
}

.notFoundText {
  font-weight: 600;
  font-size: 17px;
  line-height: 20px;
  text-align: center;
  color: #4f4f4f;
}
