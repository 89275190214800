@import "../../variables.scss";

.container {
  padding: 100px 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  background-color: $gray001;
  width: 100%;
  height: 243px;


  .imageContainer {
    margin-left: 90px;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 200px;

    img {
      width: 100%;
    }
  }

  .info {
    margin-left: 40px;
    color: #4f4f4f;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 14px;

    .title {
      font-family: Inter;
      font-style: normal;
      font-weight: 600;
      font-size: 27px;
      line-height: 28px;
      text-align: left;
    }

    .description {
      font-family: Inter;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 21px;
      text-align: left;
    }
  }
}