@import '../../variables.scss';

.container {
  display: flex;
  gap: 24px;
  flex-direction: column;
  padding: 41px;

  .label {
    font-size: 16px;
    font-weight: 600;

  }

  .row {
    gap: 24px;
    display: flex;
    flex-direction: row;

    .menu {
      width: 50%;
      flex-grow: 1;
      background-color: $gray001;
      padding: 24px;
      display: flex;
      flex-direction: column;
      gap: 32px;
      border-radius: 16px;

      .title {
        color: var(--main-color);
        font-weight: 600;
        font-size: 17.5px;
        line-height: 20px;
        display: flex;
        flex-direction: row;
        height: 52px;
        align-items: center;
        height: 73px;
        padding: 0px 0px 21px;
        border-bottom: 1px solid $gray002;

        .icon {
          margin-right: 16px;
          width: 52px;
          height: 52px;
          background-color: white;
          border-radius: 65px;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          font-size: 22px;
        }
      }

      .links {
        display: flex;
        flex-direction: row;
        gap: 16px;

        .column {
          flex-grow: 1;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 16px;
          width: 50%;

          .link {
            width: 100%;

            a,
            a:active {
              color: $blue002;
              font-weight: 600;
              font-size: 16px;
              line-height: 20px;
              display: inline-block;
              width: 100%;
            }
          }
        }
      }
    }
  }
}