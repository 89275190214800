@import '../../../../variables.scss';

:global(.ant-card).container {
  border-radius: 8px;
  margin-top: 20px;

  .icon {
    width: 40px;
    height: 40px;
  }

  .info {
    margin-top: 15px;
    font-weight: 600;
    font-size: 21.88px;
    line-height: 32px;
    letter-spacing: 0.2px;
    color: $gray004;
  }

  .infoList {
    padding: 0;
    margin: 0;
    margin-top: 14px;
    margin-left: 18px;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    color: $gray003;
    list-style-type: disc;

    li {
      text-indent: -5px;
      white-space: normal;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      padding-left: 6px;

      &:before {
        content: '';
        text-indent: -10px;
        position: absolute;
      }
    }
  }

  .buttonContainer {
    margin-top: 24px;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    .button {
      height: 48px;
    }
  }
}