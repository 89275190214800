.header{
  width: 466px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: stretch;

  &.mobile {
    width: 100%;
  }
  
  .day{
    height: 42px;
    width: 42px;
    max-width: 100%;
    text-transform: capitalize;
    color: #81858B;
    text-align: center;
  }
}