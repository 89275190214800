@import '../../variables.scss';

$imageSize: 173px;
$imageBorder: 10px;

.container {
  background-color: $gray001;
  min-height: calc(100vh - #{$headerHeight});
  display: flex;
  justify-content: center;

  .tabs {
    :global(.ant-tabs-nav) {
      :global(.ant-tabs-nav-wrap) {
        justify-content: center;
        :global(.ant-tabs-ink-bar) {
          border-radius: 11px;
          background: var(--main-color);
        }
      }
    }
  }

  .innerContainer {
    max-width: $maxUserProfileScreenWidth;
    width: 100%;

    .header {
      margin-top: 30px;
      width: 100%;
      background-color: white;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-direction: column;
      min-height: 300px;
      border-radius: 16px;
      overflow: hidden;

      .tools {
        height: 74px;
        background-color: $gray002;
        margin-bottom: -48px;
        width: 100%;
        display: flex;
        justify-content: flex-end;
        align-items: center;

        .option {
          height: fit-content;
          padding: 16px 20px 16px 20px;
          font-size: 16px;
          font-weight: 600;
          line-height: 20px;
          letter-spacing: -0.30000001192092896px;
          text-align: center;
          cursor: pointer;
          margin-right: 20px;
        }
      }

      .imageContainer {
        padding: $imageBorder;
        border-radius: 100px;
        background-color: white;
        width: fit-content;

        .image {
          position: relative;

          max-width: $imageSize;
          max-height: $imageSize;
          width: $imageSize;
          height: $imageSize;
          background-repeat: no-repeat;
          background-size: cover;
          background-position: center;
          border-radius: 90px;
        }
      }

      .name {
        font-size: 28px;
        font-weight: 500;
        line-height: 21px;
        letter-spacing: -0.30000001192092896px;
        text-align: left;
        color: $gray005;
      }

      .email {
        font-size: 14px;
        font-weight: 400;
        line-height: 17px;
        letter-spacing: 0px;
        text-align: center;
        color: $gray003;
        margin-top: 8px;
      }
    }

    .profileTitle {
      height: 30px;
      margin-top: 30px;
      border-bottom: 1px solid $gray002;
      margin-bottom: 28px;
      display: flex;
      justify-content: center;

      .title {
        height: fit-content;
        padding: 0 0 5px 0;
        border-bottom: 2px solid $blue001;
        //styleName: btn small;
        font-size: 14px;
        font-weight: 600;
        line-height: 17px;
        letter-spacing: 0px;
        color: $blue001;
      }
    }
  }
}

.certifications {
  .image {
    margin-bottom: 6px;
  }

  .title {
    font-size: 21px;
    font-weight: 600;
    line-height: 25px;
    letter-spacing: -0.30000001192092896px;
    text-align: left;
    color: $gray005;
  }

  .certificate {
    margin-top: 6px;
    display: flex;
    height: 48px;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid $gray002;

    .courseName {
      font-size: 14px;
      font-weight: 400;
      line-height: 17px;
      letter-spacing: 0px;
      text-align: left;
      color: $gray004;
    }

    .gotoLink {
      .viewButton {
        color: $gray005;
        font-size: 16px;
        font-weight: 600;
        line-height: 20px;
        letter-spacing: -0.30000001192092896px;
        text-align: right;
        display: inline-block;
        width: 40px;
        padding: 4px 0;
        cursor: pointer;
        text-align: right;
      }
    }
  }
}


.progressCard {
  padding-top: 10px;

  .achievements {
    .title {
      font-weight: 600;
      font-size: 17px;
      line-height: 20px;
    }

    .content {
      padding-top: 20px;
      padding-bottom: 20px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
  }

  .coursesInfo {
    .headline {
      font-weight: 600;
      font-size: 22px;
      line-height: 36px;
      color: #4f4f4f;
    }

    .description {
      padding-top: 10px;
      font-size: 14px;
      line-height: 21px;
      color: #4f4f4f;
    }

    .ctaWrapper {
      padding-top: 20px;
    }
  }

  .stats {
    color: #4f4f4f;
    padding: 0 20px 0 0;
    width: 80px;

    .topWrapper {
      display: flex;
      flex-direction: row;
      font-size: 22px;

      .count {
        font-weight: 600;
        line-height: 32px;
      }

      .iconWrapper {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}

.certifications {
  &.element {
    border-radius: 8px;
    overflow: hidden;
  }
}

.securityCard {
  width: 100%;

  .mainWrapper {

    .title {
      font-weight: 600;
      font-size: 17px;
      line-height: 20px;
    }

    .description {
      margin-top: 13px;
      font-size: 14px;
      line-height: 21px;
      padding-bottom: 5px;
      color: #4f4f4f;

      .email {
        color: $blue001;
      }
    }

    .ctaWrapper {
      margin-top: 20px;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
    }
  }
}

.infoCard {
  background-color: transparent;
  border: 1px solid var(--tertiary-text-button-color);
  border-radius: 16px;
  padding: 16px;

  .mainWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;

    .ctaWrapper {
      padding: 0 10px 0 0;
    }

    .description {
      color: #4f4f4f;

      .email {
        color: var(--tertiary-text-button-color);
      }
    }
  }
}

:global(#teurona) {
  .certificateList {
    :global(.ant-collapse-header) {
      background-color: white;
    }

    :global(.ant-collapse-content-box) {
      background-color: transparent;
      padding-top: 0;
      background-color: #F7F8FA;

      .description {
        padding-top: 13px;
        font-size: 14px;
        line-height: 21px;
        color: #4f4f4f;
      }

      .certificate {
        display: flex;
        align-items: center;
        border-bottom: 1px solid #E8ECF3;
        padding-top: 16px;

        &:last-child {
          border-bottom-width: 0;
        }

        .courseName {
          width: 100%;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 21px;
          letter-spacing: -0.3px;
          color: #4F4F4F;
        }

        .gotoLink {
          width: 60px;

          .viewButton {
            color: #686F84;
            font-weight: 500;
            font-size: 16px;
          }
        }
      }
    }
  }
}