@import '../../variables.scss';

// TODO: keep it dry. this one is mostly a copy of Courses form.module.scss
.container {
  padding-bottom: 30px;

  .category {
    margin-top: 20px;
    height: 24px;
    padding: 4px 8px 4px 8px;
    border-radius: 4px;
    background-color: $gray001;
    width: fit-content;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0px;
    text-align: center;
    color: $blue003;
  }

  .evaluations {
    margin-top: 18px;

    .evaluation {
      background-color: $gray001;
      height: 32px;
      padding: 4px 8px 4px 8px;
      border-radius: 4px;
      font-size: 14px;
      font-weight: 400;
      line-height: 21px;
      letter-spacing: -0.30000001192092896px;
      text-align: left;
      color: $gray005;
      margin-right: 8px;

      .icon {
        margin-right: 8px;
      }


    }
  }

  .titleContainer {
    align-items: center;
    display: flex;
    height: 80px;
    padding-top: 19px;
    justify-content: space-between;

    .title {
      font-style: normal;
      font-weight: 600;
      font-size: 21.88px;
      line-height: 32px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }

    .actions {
      align-items: center;
      display: flex;

      .buttonNextPrev {
        width: 40px;
        height: 40px;
        padding: 2px 0px !important;
        margin-right: 10px;

        .icon {
          font-size: 26px;
        }
      }

      .button {
        height: 40px;
        width: 190px;

        .label {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          width: 100%;
        }
      }
    }
  }

  .card {

    .title {
      margin-top: 20px;
      color: $gray004;
      font-size: 18px;
      font-weight: 600;
      line-height: 20px;
      letter-spacing: 0.1466670036315918px;
      text-align: left;
    }

    .description {
      font-size: 12px;
      font-weight: 400;
      line-height: 15px;
      letter-spacing: 0px;
      text-align: left;
      margin-top: 20px;
      color: $gray003;
    }

    .meta {
      font-size: 10px;
      color: $gray003;
    }

    .subTitle {
      font-size: 14px;
      color: #81858b;
    }
  }

  // TODO: this must be unified in single common css. search for .addTopicButton in Courses form
  .addCoursesButton {
    width: 100%;
    margin-top: 30px;
  }

  .summary {
    $bgkColor: #f7f7fa;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-size: 12px;

    .btnContainer {
      flex-grow: 1;

      // TODO: this is a copy of Topic form css. Consider refactoring
      .actionButton {
        height: 32px;
        padding: 0 8px;
        margin-right: 8px;

        .square {
          width: 16px;
          height: 16px;
          border-radius: 4px;
        }
      }
    }

    .details {
      display: flex;
      flex-direction: row;
      flex-grow: 1;
      justify-content: flex-end;

      .range {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        background-color: $bgkColor;
        border-radius: 4px;
        margin-right: 8px;
        padding-left: 10px;
        padding-right: 10px;

        .icon {
          margin-right: 10px;
        }

        .separator {
          margin: 0 10px;
        }
      }

      .priority {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        background-color: $bgkColor;
        border-radius: 4px;
        padding-left: 10px;
        padding-right: 10px;

        .label {
          padding-left: 10px;
        }
      }
    }
  }

  .hidableContainer {
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;

    .label {
      font-size: 16px;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: -0.30000001192092896px;
      text-align: left;
    }
  }
}

.calendarHeader {
  margin-bottom: 10px;

  .selectNoBorders {
    :global(.ant-select-selector) {
      border: 0 !important;
    }

    &.yearSelect {
      margin-left: 8px;
    }
  }

}

.calendarModal {
  width: 650px !important;
  :global(.ant-modal-header) {
    border: 0;
    :global(.ant-modal-title) {
      display: flex;
      padding: 8px 0px;
      justify-content: space-between;
      align-items: center;
      align-self: stretch;
      color: var(--Black, #2A2A2B);
      font-feature-settings: 'clig' off, 'liga' off;
      font-family: Inter;
      font-size: 21px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      letter-spacing: -0.3px;
    }
  }

  .sameLine {
    display: flex;

    .switch {
      margin-right: 16px;
    }
  }

  .twoColumns {
    display: flex;
    gap: 40px;

    .calendar {
      max-width: 366px;

      &.marginTop {
        margin-top: 48px;
      }
      :global(.ant-picker-calendar-date-today){
        &::before{
          border-radius: 6px;
        }
      }
      :global(.ant-picker-cell-selected) {
        :global(.ant-picker-cell-inner) {
          background-color: $blue001;
          color: white;
          border-radius: 6px;
        }
      }

      :global(.ant-picker-cell-disabled) {
        &::before{
          background-color: white;
        }

        &:global(.ant-picker-cell-selected) {
          :global(.ant-picker-cell-inner) {
            background-color: white;
            color: rgba(0, 0, 0, .25);
          }
        }
      }
    }
  }
}
.durationBox {
  border-radius: 8px;
  border: 1px solid var(--grey, #DEE2E9);
  background: var(--white, #FFF);
  display: flex;
  padding: 8px 10px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
  cursor: pointer;
  margin-bottom: 16px;
}