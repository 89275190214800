@import '../../variables.scss';

.container {
  border: 1px solid #e8ecf3;
  box-sizing: border-box;
  border-radius: 8px;
  margin-top: 16px;
  padding: 24px;

  .cogTabs {
    :global(.ant-tabs-nav-list) {
      display: flex;
      margin-left: 33%;
      height: 36px;

      :global(.ant-tabs-tab) {
        flex: 1;
        justify-content: center;

        :global(.ant-tabs-tab-btn) {
          text-align: center;
          color: $blue001;
        }
      }
    }

    .tab {
      height: 290px;
    }
  }
}