@import '../variables.scss';

.notifications {
  left: calc(50vw - 149px);
  bottom: 40px;
  display: flex;
  position: fixed;
  gap: 6px;
  flex-direction: column;

  .container {
    width: 298px;
    height: 64px;
    background-color: $blue001;
    display: flex;
    gap: 10px;
    border-radius: 82px;
    padding:  12px 24px 12px 16px;
    line-height: 40px;
    
    .badge {
      width: 40px;
      height: 40px;
    }
    .message {
      color: #FFFFFF99;
      font-size: 17px;
  
    }
    .points {
      color: white;
      font-size: 17px;
    }
  }
}