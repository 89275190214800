@import '../../variables.scss';

.header {
  max-width: 850px;
}

.container {
  display: flex;
  flex-direction: row;

  .companyFilterContainer {
    max-width: 276px;
    max-height: calc(100vh - 272px);
    border: 1px solid #e8ecf3;
    box-sizing: border-box;
    border-radius: 8px;
    padding: 24px 16px;
    overflow: hidden;
    margin-bottom: 30px;
  }

  .innerContainer {
    width: 100%;
    padding-left: 28px;
    max-width: 576px;
  }
}

.scheduledButton {
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: -0.30000001192092896px;
  text-align: center;
  color: $blue002;

  span {
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: -0.30000001192092896px;
    text-align: center;
    color: $blue002 !important;
  }

  .icon {
    margin-right: 4px;
  }
}