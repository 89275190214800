.table {
  color: #686f84;
  border: 1px solid #f0f0f0;
  border-radius: 8px;
  .row {
    &:hover {
        .actions {
          display: flex;
          justify-content: flex-end;
          align-items: center;
        }
    }
    .actions {
      display: none;
      position: absolute;
      background: linear-gradient(270deg, #FFFFFF 54.83%, rgba(255, 255, 255, 0) 100%);
      padding: 12px 11px 12px 0;
      top: 0px;
      right: 0px;
      width: 100%;
      height: 100%;
      &.small {
        background: linear-gradient(270deg, #FFFFFF 1.83%, rgba(255, 255, 255, 0) 100%);
      }
      button {
        margin-left: 8px;
      }
    }
  }
}
