@import '../../../variables.scss';

:global(.ant-card).container {
    border-radius: 8px;

    :global(.ant-card-body) {
        width: 100%;

        .title {
            overflow: hidden;
            color: $gray004;
            font-feature-settings: 'clig' off, 'liga' off;
            text-overflow: ellipsis;
            white-space: nowrap;
            font-size: 17.5px;
            font-style: normal;
            font-weight: 600;
            line-height: 20px;
            /* 114.286% */
            letter-spacing: 0.147px;
        }

        .categoryContainer {
            margin-bottom: 10px;
        }

        .durationPriorityContainer {
            display: flex;
            margin-top: 10px;
            width: 100%;
            justify-content: flex-start;
            gap: 16px;

            .duration {
                display: flex;
                align-items: center;
                color: $gray003;
                font-feature-settings: 'clig' off, 'liga' off;
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: 16px;
            }

            .priority {
                display: flex;
                align-items: center;
                color: $gray003;
                font-feature-settings: 'clig' off, 'liga' off;
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: 16px;
            }

            .icon {
                height: 19px;
                width: 19px;
                margin-right: 6px;
            }

            .timeLeft {
                display: flex;
                align-items: center;
                color: $gray003;
                font-feature-settings: 'clig' off, 'liga' off;
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: 16px;
            }
        }

        .progress {
            margin-top: 8px;

            .percentage {
                font-size: 12px;
                line-height: 16px;
                color: #828282;
            }

            .bar {
                :global(.ant-progress-bg) {
                    height: 10px !important;
                }
            }
        }

        .gotToLessonContainer {
            margin-top: 20px;

            .gotToLesson {
                width: 100%;
                height: 40px;
            }

            .certificateButton {
                margin-top: 10px;
                width: 100%;
                height: 40px;
            }
        }
    }
}