.mainWrapper {
  margin-left: auto;
  margin-right: auto;
}

.pageBreak {
  display: block;
  page-break-before: always;
}

.column {
  .card {
    border-radius: 15px;
    background-color: #f7f8fa;
  }
  .title {
    font-family: Inter;
    font-size: 20px;
    line-height: 16px;
    color: #686f84;
    padding: 15px 5px;
  }
}

.floatingCtaWrapper {
  position: fixed;
  width: 100%;
  z-index: 10;
  background-color: cyan;
  .buttonWrapper {
    max-width: 1140px;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    .cta {
      margin-top: 50px;
      position: absolute;
      right: 0;
    }
  }
}

.contract {
  max-width: 1140px;
  margin-left: auto;
  margin-right: auto;
  @media print {
    padding: 0 40px;
  }
}
