.mainWrapper {
  display        : flex;
  justify-content: space-between;

  .divider {
    display        : flex;
    justify-content: center;
    flex           : 1;
    padding        : 16px 0;

    .hrVertical {
      height    : 100%;
      width     : 0;
      margin    : 0;
      max-height: 100vh;
      border    : 1px solid #E8ECF3;
    }
  }
}