.container.noMobile {
  display: flex;
  height: 32px;
  padding: 4px 8px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid var(--grey, #DEE2E9);
  background: #F7F8FA;
  margin-left: 7px;
  margin-right: 7px;

  .points {
    color: var(--Black, #2A2A2B);
    text-align: center;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    min-width: 18px;
  }

  .icon {
    width: 18px;
    height: 18px;
  }
}

.container.mobile {
  display: flex;
  padding: 6px 8px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid var(--grey, #DEE2E9);
  background: #F7F8FA;

  .points {
    font-feature-settings: 'clig' off, 'liga' off;
    -webkit-text-stroke-width: 54.57499694824219;
    -webkit-text-stroke-color: var(--Black, #2A2A2B);
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: -0.3px;
  }

  .icon {
    width: 26px;
    height: 26px;
  }
}