@import '../../../variables.scss';

.wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 0 22px;
    min-height: calc(100vh - 115px);

    .main {
        width: 100%;
        max-width: $maxScreenWidth;
        display: flex;

        .sider {
            background-color: white;
            border-right: 1px solid $gray002;
            padding: 16px 16px 16px 0;
            display: flex;
            gap: 16px;
            width: $sideMenuWidth;
            min-width: $sideMenuWidth;
        }

        .contentContainer {
            width: 100%;
            padding: 0px 20px 6px 36px;

            &.withoutPadding {
                padding: 0;
            }

            .breadcrumbs {
                height: 52px;
                display: flex;
                align-items: center;
                border-bottom: 1px solid $gray002;
                position: relative;
                margin-left: -36px;
                margin-right: -20px;
                padding-left: 30px;

                span {
                    cursor: default;
                    display: inline-block;

                    &::after {
                        content: "/";
                        display: inline-block;
                        width: 18px;
                        text-align: center;
                        font-size: 15px;
                        font-weight: 400;
                        line-height: 20px;
                        letter-spacing: 0px;
                        color: $gray003;
                    }
                }

                .teurona {
                    width: 44px;
                    cursor: pointer;
                }

                .item {
                    font-size: 16px;
                    font-weight: 600;
                    line-height: 20px;
                    letter-spacing: -0.30000001192092896px;
                    text-align: left;
                    color: $gray005;

                    &:last-child {
                        color: $gray003;

                        &::after {
                            display: none;
                        }
                    }

                    &.pointer {
                        cursor: pointer;
                    }
                }
            }

            .goBackContainer {
                margin-top: 30px;
                //styleName: button;
                font-size: 16px;
                font-weight: 600;
                line-height: 20px;
                letter-spacing: -0.30000001192092896px;
                width: fit-content;
                cursor: pointer;
                color: var(--tertiary-text-button-color);
            }

            .titleContainer {
                display: flex;
                margin-top: 20px;
                margin-bottom: 14px;
                justify-content: space-between;

                &.hide {
                    display: none;
                }

                &.withGoBack {
                    margin-top: 0;
                }

                .title {
                    font-style: normal;
                    height: 56px;
                    display: flex;
                    align-items: center;

                    //styleName: title;
                    font-size: 22px;
                    font-weight: 600;
                    line-height: 32px;
                    letter-spacing: 0.20000000298023224px;
                    text-align: left;

                }
            }

            .content {
                display: flex;
                flex-direction: column;
                align-items: center;

                >* {
                    width: 100%;
                }
            }
        }
    }
}