@import '../../variables.scss';

$imageSize: 173px;
$imageBorder: 10px;

.innerContainer {
  max-width: $maxUserProfileScreenWidth;
  width: 100%;

  .header {
    margin-top: 30px;
    width: 100%;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    min-height: 300px;
    border-radius: 16px;
    overflow: hidden;
    margin-bottom: 30px;

    .tools {
      height: 74px;
      background-color: $gray002;
      margin-bottom: -48px;
      width: 100%;
      display: flex;
      justify-content: flex-end;
      align-items: center;

      .option {
        height: fit-content;
        padding: 16px 20px 16px 20px;
        font-size: 16px;
        font-weight: 600;
        line-height: 20px;
        letter-spacing: -0.30000001192092896px;
        text-align: center;
        cursor: pointer;
        margin-right: 20px;
      }
    }

    .imageContainer {
      padding: $imageBorder;
      border-radius: 100px;
      background-color: white;
      width: fit-content;

      .image {
        position: relative;

        max-width: $imageSize;
        max-height: $imageSize;
        width: $imageSize;
        height: $imageSize;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        border-radius: 90px;
      }
    }

    .name {
      font-size: 28px;
      font-weight: 500;
      line-height: 21px;
      letter-spacing: -0.30000001192092896px;
      text-align: left;
      color: $gray005;
    }

    .email {
      font-size: 14px;
      font-weight: 400;
      line-height: 17px;
      letter-spacing: 0px;
      text-align: center;
      color: $gray003;
      margin-top: 8px;
    }
  }

  .profileTitle {
    height: 30px;
    margin-top: 30px;
    border-bottom: 1px solid $gray002;
    margin-bottom: 28px;
    display: flex;
    justify-content: center;

    .title {
      height: fit-content;
      padding: 0 0 5px 0;
      border-bottom: 2px solid $blue001;
      //styleName: btn small;
      font-size: 14px;
      font-weight: 600;
      line-height: 17px;
      letter-spacing: 0px;
      color: $blue001;
    }
  }
}