.container {
  display: flex;
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
  width: 100%;
  border-radius: 16px;
  border: 1px solid var(--grey, #DEE2E9);
  background: #FFF;

  &.mobile {
    margin-top: 40px;
    border-radius: 0;
    border: 0;
    border-top: 1px solid var(--grey, #DEE2E9);
    border-bottom: 1px solid var(--grey, #DEE2E9);

    .carousel {
      display: flex;
      flex-direction: row;
      gap: 16px;
      margin-left: -16px;
      margin-right: -16px;
      max-width: 100vw;
      overflow-y: auto;
      padding-left: 16px;
      padding-right: 16px;
      -ms-overflow-style: none;
      /* IE and Edge */
      scrollbar-width: none;
      /* Firefox */

      &::-webkit-scrollbar {
        display: none;
      }
    }

    .title {
      font-size: 16px;
    }
  }

  .title {
    color: var(--Black, #2A2A2B);
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.208px;
  }

  .contents {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    align-self: stretch;
  }
}