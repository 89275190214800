@import '../../variables.scss';

.mainWrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 10px;
  cursor: pointer;

  .text {
    color: #81858b;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    padding-left: 10px;
    padding-right: 10px;

    @media (max-width: 768px) {
      display: none;
    }
  }

  .imageWrapper {
    height: 26px;
    width: 26px;
    line-height: 26px;

    @media(max-width:450px) {
      width: 26px;
      height: 26px;
    }

    .image {
      border-radius: 19px;
      height: 26px;
      width: 26px;

      @media(max-width:450px) {
        width: 22px;
        height: 22px;
      }
    }
  }

  .profileTitle {
    font-size: 10px;
    font-weight: 500;
    line-height: 12px;
    letter-spacing: -0.30000001192092896px;
    color: $gray003;
  }
}

.menuWrapper {
  width: 200px;
  border: 1px solid #e8ecf3;
  color: #686f84;
}