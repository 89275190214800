.tabs {
  :global(.ant-tabs-nav) {
    :global(.ant-tabs-nav-wrap) {
      :global(.ant-tabs-ink-bar) {
        border-radius: 11px;
        background: var(--main-color);
      }
    }
    &:global(.ant-tabs-nav) {
      margin: 0;
    }
    .tab {
      display: flex;
      align-items: center;
      gap: 6px;
      color: #333;
    }
    :global(.ant-tabs-tab-active) {
      .tab {
        color: var(--main-color);
      }
    }
  }
}

.tabs.mobile {
  :global(.ant-tabs-nav) {
    margin-top: 25px;
    padding-left: 20px;
    margin-bottom: 24px;
    &::before{
      border: 0;
    }
    :global(.ant-tabs-nav-wrap) {
      :global(.ant-tabs-ink-bar) {
        border-radius: 11px;
        background: var(--main-color);
      }
    }
    .tab {
      display: flex;
      align-items: center;
      gap: 6px;
      color: #333;
    }
    .line {
      height: 10px;
      width: calc(100vw - 260px);
      border-bottom: 2px solid #DEE2E9;
    }
    :global(.ant-tabs-tab-active) {
      .tab {
        color: var(--main-color);
      }
    }
  }
}