.title {
  color: #686f84;
  display: flex;
  font-size: 17.5px;
  font-weight: 600;
  margin-bottom: 25px;
}

.surveyColumn {
  align-items: center;
  display: flex;
  justify-content: flex-start;
  .columntitle {
    white-space: normal;
    width: 60%;
  }
}