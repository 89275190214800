:global(.ant-back-top).backUpTop{
  margin-top: 46px;
  height: 30px;
  border-radius: 4px;
  display: flex;
  justify-content: flex-end;
  width: 140px;
  background-color: #aaaaaa50;
  padding: 4px 10px;
  bottom: 10px;
  .link{
      cursor: pointer;
      color: #4376F9;
      .icon{
          margin-left: 10px;
      }
  }
}
.container{
  display: flex;
  justify-content: space-between;
  .filterContainer{
      display: flex;
      .search {
          &.wrapper {
              background: #F7F8FA;
              border-radius: 8px;
              border-color: #F7F8FA;
              padding: 8px 12px;
              width: 220px;
          }
      }
  }
}
