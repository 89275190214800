.wrapper {
  padding: 2em 1em 0;
  .header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: column;
    padding-top: 10px;
    padding-bottom: 10px;

    .title {
      font-weight: 600;
      font-size: 22px;
      line-height: 32px;
      color: #4f4f4f;
    }
    .subTitle {
      font-size: 12px;
      line-height: 16px;
      color: #81858b;
    }
  }

  .content {
    margin-top: 20px;
    color: rgba(0, 0, 0, 0.65);
    img {
      width: 100%;
    }
  }

  .pdfs {
    display: flex;
    align-items: baseline;
    .pdf {
      display: inline-flex;
      margin-right: 12px;
      background-color: #f7f8fa;
      border-radius: 8px;
      .download {
        padding: 8px 12px;
        .iconLeft {
          margin-right: 8px;
        }
        .iconRight {
          margin-left: 10px;
        }
        .filename {
          font-weight: 500;
          font-size: 16px;
          line-height: 20px;
          color: #686f84
        }
      }
    }
  }

  .related {
    .title {
      font-weight: 600;
      font-size: 22px;
      line-height: 32px;
      color: #4f4f4f;
    }

    .suggestionsWrapper {
      margin-top: 24px;
    }
  }
}
