.emptyContainer {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 307px;
  h2 {
    color: #686F84;
    font-size: 22px;
    font-weight: 600;
    letter-spacing: 0.19px;
    margin: 26px 0 10px;
  }
  p {
    color: #81858B;
    font-size: 14px;
    letter-spacing: -0.3px;
  }
}