.mainWrapper {
  display       : flex;
  flex-direction: column;
  padding       : 0 16px;
  flex          : 3;

  &.row {
    flex-direction: row;
    align-items   : center;

    .contents {
      padding-left: 10px;
    }

    .mainIcon {
      $size         : 57px;
      border-radius : calc(#{$size}/2);
      min-width     : $size;
      min-height    : $size;
      width         : $size;
      height        : $size;
    }
  }



  .mainIcon {
    $size           : 48px;
    display         : flex;
    justify-content : center;
    align-items     : center;
    background-color: #f7f8fa;
    border-radius   : calc(#{$size}/2);
    min-width       : $size;
    min-height      : $size;
    width           : $size;
    height          : $size;
  }

  .numberWrapper {
    display       : flex;
    flex-direction: row;
    padding-top   : 16px;
    align-items   : center;

    .number {
      font-size  : 2.4em;
      color      : #4f4f4f;
      line-height: 1em;
      font-weight: 600;
    }

    .tooltipTarget {
      display     : flex;
      align-items : center;
      padding-left: 8px;
    }
  }

  .description {
    font-weight: 400;
    font-size  : 1em;
    color      : #4f4f4f;
    padding-top: 8px;
  }
}