:global(.ant-back-top).reportButton {
    bottom: 75px;
    right: 4.5%;
    width: 187px;
    .content {
        align-items: center;
        background-color: #F7F8FA;
        border-radius: 8px;
        box-shadow: 0px 2px 12px rgba(23, 29, 41, 0.12);
        color: #4376F9;
        display: flex;
        padding: 16px 14px;
        span {
            font-size: 16px;
            font-weight: 500;
            letter-spacing: -0.3px;
            padding: 0 0 0 11px;
        }
        .square {
            align-items: center;
            background-color: #E8ECF3;
            border-radius: 10px;
            display: flex;
            height: 40px;
            justify-content: center;
            margin: 0;
            width: 40px;
        }
    }
  }