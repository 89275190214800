:global(.rbc-overlay) {
  z-index: 1001;
}
:global(#teurona) {
  .container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    margin-top: 40px;
    line-height: 16px;
    align-items: stretch;
    justify-content: stretch;
    justify-items: stretch;
    &:first-child {
      margin-top: 0;
    }
    &.clickable {
      cursor: pointer;
    }
    .imageWrapper {
      position: relative;
      max-width: 276px;
      height: 135px;
      width: 100%;
      &.width25 {
        width: 25%;
      }
      &.width40 {
        width: 40%;
      }
      &.width50 {
        width: 50%;
      }
      .category {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        padding: 4px 8px;
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        color: #686f84;
        left: 12px;
        top: 12px;
        border-radius: 4px;
        background-color: #f7f8fa;
        max-width: 85%;
      }
    }
    .image {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 8px;
    }
    .innerContainer {
      padding: 0 8px;
      display: flex;
      flex-direction: row;
      &.width75 {
        width: 75%;
      }
      &.width60 {
        width: 60%;
      }
      &.width50 {
        width: 50%;
      }
    }
    .firstColumn {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      padding: 0 18px;
      flex-grow: 1;
      width: 100%;
    }
    .secondColumn {
      justify-content: space-between;
      display: flex;
      flex-direction: column;
      max-width: 268px;
      width: 100%;
    }
    .durationPriorityContainer {
      display: flex;
      &.justify {
        justify-content: space-between;
      }
      .duration {
        color: #bdbdbd;
        font-size: 12px;
        display: flex;
        align-items: center;
        max-width: 100px;
        width: 100%;
      }
      .priority {
        color: #bdbdbd;
        font-size: 12px;
        display: flex;
        align-items: center;
      }
      .icon {
        height: 19px;
        width: 19px;
        margin-right: 6px;
      }
    }
    .title {
      margin-top: 8px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      color: #4f4f4f;
      font-size: 14px;
    }
    .descriptionContainer {
      display: flex;
      .description {
        margin-top: 4px;
        color: #4f4f4f;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        color: #828282;
        font-size: 12px;
        height: 32px;
        width: calc(100% - 180px);
      }
      .certificateButtonContainer {
        top: -43px;
        position: relative;
        :global(.ant-btn.ant-btn-default).certificateButton {
          color: #686f84;
          width: 180px;
          border-radius: 4px;
        }
      }
    }
    .progress {
      width: 100%;
      margin-top: 20px;
      .percentage {
        color: #828282;
        font-size: 12px;
      }
    }
    .scheduleContainer {
      margin-top: 10px;
      display: flex;
      justify-content: space-between;
      &.justify {
        justify-content: flex-start;
      }
      .start {
        color: #828282;
        font-size: 12px;
      }
      .finish {
        color: #828282;
        font-size: 12px;
      }
    }
    .buttonContainer {
      width: 100%;
    }
  }
}
