.alertIcon {
    background-color: #ffffff;
    padding-left: 3px;
    position: absolute;
    right: 5px;
    top: 20px;
}
.name{
    align-items: center;
    display: flex;
    .logo{
        display: block;
        width: 30px;
        height: 30px;
        margin-right: 10px;
        border-radius: 15px;
    }
}