@import '../../../variables.scss';

.indicator {
    width: 18px;
    margin-right: 10px;
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: center;

    .unfinishedIcon {
        width: 16px;
        height: 16px;
        display: inline-block;
        border: 1px solid $gray003;
        border-radius: 8px;
        margin-top: 2px;
    }

    .icon {
        width: 18px;
        height: 18px;
    }
}